import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TicketPropertyDetailView from './TicketPropertyDetailView';
import TicketPropertyListView from './TicketPropertyListView';
import Frame from '../../components/layout/Frame';

@observer
class TicketPropertyFrame extends Component {
  render() {
    const { location } = this.props;
    return (
      <Frame>
        <div
          className={`${location.pathname === '/main/setting/manage/property/list' ? 'd-block' : 'd-none'} h-100`}
        >
          <TicketPropertyListView pathname={location.pathname} />
        </div>
        <Switch>
          <Route
            path="/main/setting/manage/property/new"
            component={TicketPropertyDetailView}
          />
          <Route
            path="/main/setting/manage/property/detail/:fieldKey"
            component={TicketPropertyDetailView}
          />
          <Redirect
            exact
            from="/main/setting/manage/property"
            to="/main/setting/manage/property/list"
          />
        </Switch>
      </Frame>
    );
  }
}

export default TicketPropertyFrame;
