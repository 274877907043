import React from 'react';
import { observer } from 'mobx-react';
import { Tab } from 'react-bootstrap';

@observer
class ScrollTabContent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { children } = this.props;
    return (
      <div
        className={`d-flex flex-column`}
        style={{ flex: '1 1 0', minHeight: 0 }}
      >
        <Tab.Content className={`h-100`} style={{ flex: '1 1 0' }}>
          {children}
        </Tab.Content>
      </div>
    );
  }
}

ScrollTabContent.propType = {};

export { ScrollTabContent };
