import React, { useEffect, useState } from 'react';
import { useCountUp } from 'react-countup';
import Styles from './Chart.module.scss';
const SubCountChart = ({ data, title, size = 36 }) => {
  useEffect(() => {
    start();
  }, [data]);

  const countUpRef = React.useRef(null);
  const [startCount, setStartCount] = useState(0);
  const { start } = useCountUp({
    ref: countUpRef,
    start: startCount,
    end: data,
    duration: 2,
    onEnd: () => {
      setStartCount(data);
    },
  });
  return (
    <div className={Styles.SubCount}>
      <div className={Styles.Name}>{title}</div>
      <div ref={countUpRef} style={{ fontSize: size }} />
    </div>
  );
};

export { SubCountChart as default };
