import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Styles from './UploadImage.module.scss';
import { uploadStore } from '../dialog/UploadDialog';
import ResetImage from './ResetImage';
import { Image } from 'react-bootstrap';

@observer
class UploadImage extends Component {
  constructor(props) {
    super(props);
  }

  get existImage() {
    // true일 때는 이미지 존재, false일 때는 이미지 존재 X
    return this.props?.url !== '';
  }

  render() {
    let { selectImage, resetImage, url, noImage, useUrl, useFile } = this.props;
    return (
      <div className={Styles.ImageUploadWrap}>
        {this.existImage && (
          <div className={Styles.ResetAvatar}>
            <ResetImage
              onOk={() => {
                resetImage();
              }}
            />
          </div>
        )}

        <div
          className={Styles.AvatarWrap}
          onClick={() => {
            uploadStore.modalOpen(
              (image) => {
                selectImage(image);
              },
              useUrl,
              useFile,
            );
          }}
        >
          {url ? <Image className={Styles.AvatarWrap} src={url} /> : noImage}
          <div className={`bg-opacity-75 bg-dark ${Styles.HoverImage}`}>
            {this.existImage ? `수정` : `등록`}
          </div>
        </div>
      </div>
    );
  }
}

export { UploadImage as default };
