import { createHashHistory } from 'history';

const HISTORY = createHashHistory(); // TODO : 무조건 가장 먼저 실행되어야 함. IoC 라이브러리 도입 검토 .;
window.h = HISTORY;

const navigate = url => {
    HISTORY.push(url);
};

const navigateReplace = url => {
    HISTORY.replace(url);
};

const goBack = () => {
    HISTORY.goBack()
}

export {HISTORY, navigate, navigateReplace, goBack};
