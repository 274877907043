import React from 'react';
import {observer} from "mobx-react";
import Styles from './ContentEditor.module.scss'
import {SortableHandle} from "react-sortable-hoc";
import {BiSort} from "react-icons/bi";
import {CONTENT_TYPE} from "@qbot-chat/qbot-uikit/chat";
import TextEditor from "./editor/TextEditor";
import FileEditor from "./editor/FileEditor";
import ButtonEditor from "./editor/ButtonEditor";
import MediaEditor from "./editor/MediaEditor";
import LineEditor from "./editor/LineEditor";
import {Trash} from "iconsax-react";

const DragHandle = SortableHandle(() =>
    <div className={Styles.Handle}><BiSort color={'ADB5BD'}/></div>
);

const title = {
    TEXT : '텍스트',
    IMAGE : '이미지',
    AUDIO : '오디오',
    VIDEO : '비디오',
    FILE : '파일',
    LINE : '구분선',
    BUTTON : '버튼',
};

@observer
class ContentEditor extends React.Component{

    renderEditor(type, value, onChangeItem, idx) {

        switch (type) {
            case CONTENT_TYPE.TEXT:
                return (
                    <TextEditor type={type} value={value} onChangeItem={onChangeItem} idx={idx}/>
                );
            case CONTENT_TYPE.BUTTON:
                return (
                    <ButtonEditor type={type} value={value} onChangeItem={onChangeItem} idx={idx}/>
                );
            case CONTENT_TYPE.IMAGE:
                return (
                    <MediaEditor type={type} value={value} onChangeItem={onChangeItem} idx={idx} accept={'image/*'}/>
                );
            case CONTENT_TYPE.AUDIO:
                return (
                    <MediaEditor type={type} value={value} onChangeItem={onChangeItem} idx={idx} accept={'audio/*'}/>
                );
            case CONTENT_TYPE.VIDEO:
                return (
                    <MediaEditor type={type} value={value} onChangeItem={onChangeItem} idx={idx} accept={'video/*'}/>
                );
            case CONTENT_TYPE.FILE:
                return (
                    <FileEditor type={type} value={value} onChangeItem={onChangeItem} idx={idx}/>
                )
            case CONTENT_TYPE.LINE:
                return (
                    <LineEditor type={type} value={value} onChangeItem={onChangeItem} idx={idx}/>
                )
            default :
                return <div>{`${value}`}</div>

        }
    }

    render() {
        let {content, idx, onChangeItem, onDeleteItem, textContentCount} = this.props;
        let {type, value} = content;
        let isDeletable = true;
        if(type == CONTENT_TYPE.TEXT && textContentCount <= 1){
            isDeletable = false;
        }
        return(
           <div className={`${Styles.Card}`} >
               <DragHandle />
               <div className={Styles.Content}>
                   <div className={Styles.Header}>
                       <div className={`fw-bold`}>
                           {`${title[type]} - ${idx+1}`}
                       </div>
                       <div>
                           <a className={`${Styles.Delete} ${isDeletable ? '' : Styles.Disabled}`}
                              onClick={()=>{
                                  if(isDeletable)
                                    onDeleteItem && onDeleteItem(idx)
                              }}
                           >
                               <Trash size={20}/>
                           </a>
                       </div>
                   </div>
                   <div className={`mt-2`}>
                       {
                           this.renderEditor(type, value, onChangeItem, idx)
                       }
                   </div>
               </div>
           </div>
        );
    }
}

export default ContentEditor;