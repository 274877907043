import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Validation from './Validation';
import Styles from './Input.module.scss';
import { Form, InputGroup } from 'react-bootstrap';

/**
 * mobx 폼 스토어와 동기화 되는 인풋 필드.
 *
 * @reactProps {FormStore} store - mobx 스토어
 * @reactProps {string} name  - 필드 이름
 * @reactProps {*} value  - 필드 값
 * @reactProps {Array<Validation>} validations  - 유효성 체크목록
 */

function autoHyphen(value) {
  if (!value) {
    return '';
  }
  value = value.replace(/[^0-9]/g, '');
  let result = [];
  let restNumber = '';
  if (value.startsWith('02')) {
    result.push(value.substr(0, 2));
    restNumber = value.substring(2);
  } else if (value.startsWith('1')) {
    restNumber = value;
  } else {
    result.push(value.substr(0, 3));
    restNumber = value.substring(3);
  }
  if (restNumber.length === 7) {
    result.push(restNumber.substring(0, 3));
    result.push(restNumber.substring(3));
  } else {
    result.push(restNumber.substring(0, 4));
    result.push(restNumber.substring(4));
  }
  return result.filter((val) => val).join('-');
}
@observer
class Input extends React.Component {
  constructor(props, context) {
    super(props, context);
    let { store, name, value, validations } = this.props;
    store.validationMap.set(name, validations);
    if (!store.valueMap.get(name)) store.valueMap.set(name, value || '');
  }

  render() {
    let {
      store,
      name,
      validOnBlur,
      onChange,
      onBlur,
      type = `text`,
      // label,
      // require = false,
      isAutoHyphen = false,
      readOnly = false,
      maxLength,
      style = {},
      button,
      ...props
    } = this.props;
    return (
      <>
        <div className={`d-flex flex-column flex-grow-1`}>
          <InputGroup hasValidation className={`position-relative`}>
            <Form.Control
              maxLength={isAutoHyphen ? 13 : maxLength}
              type={type}
              size={'md'}
              error={
                store.hasTried.has(name)
                  ? store.invalidationsMap?.get(name)
                  : undefined
              }
              helpertext={
                store.hasTried.has(name)
                  ? store.getErrorMessages(name)
                  : undefined
              }
              ref={(ref) => store.refs.set(name, ref)}
              value={store.valueMap.get(name) || ''}
              onChange={(e) => {
                !isAutoHyphen
                  ? store.valueMap.set(name, e.target.value)
                  : store.valueMap.set(name, autoHyphen(e.target.value));
                onChange && onChange(e);
              }}
              readOnly={readOnly}
              onBlur={(e) => {
                if (validOnBlur) store.hasTried.set(name, true);
                onBlur && onBlur(e);
              }}
              style={style}
              className={`${Styles.Input}`}
              {...props}
            />
            {button && button}
            {store.hasTried.get(name) &&
              store.getErrorMessages(name).length > 0 && (
                <Form.Control.Feedback
                  type="invalid"
                  className={`d-flex`}
                  style={{ whiteSpace: 'pre' }}
                >
                  {store.getErrorMessages(name)[0]}
                </Form.Control.Feedback>
              )}
          </InputGroup>
        </div>
      </>
    );
  }
}

Input.propTypes = {
  store: PropTypes.object.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  validOnBlur: PropTypes.bool,
  value: PropTypes.any,
  validations: PropTypes.arrayOf(PropTypes.instanceOf(Validation)),
};

export default Input;
