import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DataGridStore, Sort } from '@qbot-chat/qbot-uikit/grid';
import { Col, FormGroup } from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import IconRegistrationView from './IconRegistrationView';
import IconGrid from '../../components/grid/IconGrid';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import TitlePanel from '../../components/layout/TitlePanel';
import { ConfirmDialog } from '@qbot-chat/qbot-uikit';
import FrameWrap from '../../components/layout/FrameWrap';

const dataGridStore = new DataGridStore({
  fetchUrl: `/setting/icon/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`name`, Sort.SORT_DIRECTION.ASC),
  rowsPerPage: 100,
  paginationSize: 5,
});

@observer
class IconSettingView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addOpen: true,
    };
  }

  componentDidMount() {
    dataGridStore.refresh();
  }
  iconNameInputRef;

  render() {
    return (
      <FrameWrap className={'w-100 h-100'}>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'일반 설정 - 아이콘'} />
            </Col>
          </FormGroup>
        </TitlePanel>
        <IconRegistrationView
          className={'w-100'}
          setIconNameInputRef={(inputFocus) => {
            this.iconNameInputRef = inputFocus;
          }}
          dataGridStoreRefresh={() => {
            dataGridStore.refresh();
          }}
        />
        <Panel className={'flex-default'}>
          <IconGrid
            className={'d-flex w-100 h-100'}
            store={dataGridStore}
            dataGridStoreRefresh={() => {
              dataGridStore.refresh();
            }}
          />
        </Panel>
        <ConfirmDialog />
      </FrameWrap>
    );
  }
}

export default IconSettingView;
