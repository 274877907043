import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Styles from '../SettingManageView.module.scss';
import {
  Button,
  Col,
  Row,
  FormControl,
  FormGroup,
  Collapse,
  Form,
  InputGroup,
} from 'react-bootstrap';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { MdAdd, MdRemove } from 'react-icons/md';
import { computed, makeObservable, observable, values } from 'mobx';
import { v4 as uuid } from 'uuid';
import { FormStore } from '../../../components/validation';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import settingStore from '../../../store/SettingStore';
import _ from 'lodash';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const format = 'HH시mm분';

@observer
class WeekTimeEditor extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      isLoad: false,
    };
  }

  componentDidMount() {
    let { weekTime } = this.props;
    this.init(weekTime);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.weekTime, prevProps.weekTime)) {
      this.init(this.props.weekTime);
    }
  }

  form = new FormStore();

  init(weekTime) {
    this.form.clear(weekTime);
    if (weekTime?.exceptTimes) {
      this.exceptTimeMap.replace(
        weekTime?.exceptTimes.map((exceptTime) => {
          return [exceptTime.id, { ...exceptTime, key: exceptTime.id }];
        }),
      );
    }
    this.setState({ isLoad: true });
  }

  @observable
  exceptTimeMap = new Map();

  @computed
  get exceptTimes() {
    return values(this.exceptTimeMap);
  }

  get isSame() {
    let { weekTime } = this.props;
    let { exceptTimes = [] } = weekTime;
    let defaultStartTime = dayjs(weekTime.startTime, format);
    let defaultEndTime = dayjs(weekTime.endTime, format);
    let startTime = this.form.valueMap.get('startTime')
      ? dayjs(this.form.valueMap.get('startTime'), format)
      : null;
    let endTime = this.form.valueMap.get('endTime')
      ? dayjs(this.form.valueMap.get('endTime'), format)
      : null;
    let notSameExceptTimes = exceptTimes.filter(
      (exceptTime) =>
        !_.isEqual(
          exceptTime,
          _.omit(this.exceptTimeMap.get(exceptTime.id), ['isValid', 'key']),
        ),
    );
    if (
      weekTime.isActive != this.form.valueMap.get('isActive') ||
      !defaultStartTime.isSame(startTime) ||
      !defaultEndTime.isSame(endTime) ||
      exceptTimes.length != this.exceptTimes.length ||
      notSameExceptTimes.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    let { weekTime, team } = this.props;
    let isActive = this.form.valueMap.get('isActive') || false;
    let exceptTimes = this.exceptTimes;
    let defaultStartTime = dayjs(weekTime.startTime, format);
    let defaultEndTime = dayjs(weekTime.endTime, format);
    let startTime = this.form.valueMap.get('startTime')
      ? dayjs(this.form.valueMap.get('startTime'), format)
      : null;
    let endTime = this.form.valueMap.get('endTime')
      ? dayjs(this.form.valueMap.get('endTime'), format)
      : null;
    let unValidExceptTime = this.exceptTimes.filter(
      (time) => time.isValid === false,
    );
    return (
      <>
        <div className={Styles.TicketEventEditor}>
          <FormGroup as={Row}>
            <Col lg={3} md={12} sm={12} className={'mt-2 mb-2'}>
              <div className={Styles.Label}>설정 사용</div>
            </Col>
            <Col
              lg={9}
              md={12}
              sm={12}
              className={`d-flex justify-content-end`}
            >
              <Form.Check
                type="switch"
                checked={isActive}
                onChange={(e) => {
                  this.form.setValue('isActive', e.target.checked);
                }}
              />
            </Col>
          </FormGroup>
          <hr className={Styles.HrColor} />
          <Collapse in={weekTime && isActive}>
            <div>
              <FormGroup as={Row}>
                <Col lg={3} md={12} sm={12} className={'mt-2 mb-2'}>
                  <div className={Styles.Label}>근무시간</div>
                </Col>
                <Col
                  lg={9}
                  md={12}
                  sm={12}
                  className={`d-flex justify-content-end`}
                >
                  <TimePicker.RangePicker
                    defaultValue={[defaultStartTime, defaultEndTime]}
                    value={[startTime, endTime]}
                    format={format}
                    onChange={(dates) => {
                      let startTime = dates
                        ? dates[0].format('HH:mm:ss')
                        : null;
                      let endTime = dates ? dates[1].format('HH:mm:ss') : null;
                      this.form.setValue('startTime', startTime);
                      this.form.setValue('endTime', endTime);
                    }}
                  />
                </Col>
              </FormGroup>
              <hr className={Styles.HrColor} />
              <FormGroup as={Row}>
                <Col lg={2} md={12} sm={12} className={'mt-2 mb-2'}>
                  <div className={Styles.Label}>예외시간</div>
                </Col>
                <Col
                  lg={10}
                  md={12}
                  sm={12}
                  className={`d-flex flex-column justify-content-end`}
                >
                  {exceptTimes.map((exceptTime, idx) => {
                    let key = exceptTime?.key;
                    let isActive = exceptTime?.isActive;
                    let start = exceptTime.startTime
                      ? dayjs(exceptTime.startTime, format)
                      : null;
                    let end = exceptTime.endTime
                      ? dayjs(exceptTime.endTime, format)
                      : null;
                    let isValid = !!start && !!end;
                    return (
                      <div
                        className={`pb-2 d-flex justify-content-between`}
                        key={idx}
                      >
                        <div className={`me-3`}>
                          <Button
                            variant={'outline-danger'}
                            size={'sm'}
                            onClick={() => {
                              this.exceptTimeMap.delete(key);
                            }}
                          >
                            <MdRemove />
                          </Button>
                        </div>
                        <FormGroup as={Row}>
                          <Col
                            lg={2}
                            md={1}
                            sm={1}
                            className={
                              'mb-2 d-flex align-items-start justify-content-end'
                            }
                          >
                            <Form.Check
                              type="switch"
                              checked={isActive}
                              onChange={(e) => {
                                this.exceptTimeMap.set(key, {
                                  ...exceptTime,
                                  isActive: e.target.checked,
                                  isValid: isValid,
                                });
                              }}
                            />
                          </Col>
                          <Col lg={4} md={10} sm={10}>
                            <InputGroup hasValidation>
                              <TimePicker.RangePicker
                                defaultValue={[start, end]}
                                value={[start, end]}
                                format={format}
                                allowClear={false}
                                onChange={(dates) => {
                                  let startTime = dates
                                    ? dates[0].format('HH:mm:ss')
                                    : null;
                                  let endTime = dates
                                    ? dates[1].format('HH:mm:ss')
                                    : null;
                                  this.exceptTimeMap.set(key, {
                                    ...exceptTime,
                                    startTime,
                                    endTime,
                                    isValid: !!startTime && !!endTime,
                                  });
                                }}
                                status={!isValid && 'error'}
                              />
                              {!isValid && (
                                <Form.Control.Feedback
                                  type="invalid"
                                  className={`text-right d-block`}
                                >
                                  시간을 입력해주세요
                                </Form.Control.Feedback>
                              )}
                            </InputGroup>
                          </Col>
                          <Col lg={6} md={12} sm={12}>
                            <FormControl
                              as={'textarea'}
                              placeholder={'메시지를 입력하세요.'}
                              defaultValue={exceptTime.message}
                              value={exceptTime.message}
                              onChange={(e) => {
                                this.exceptTimeMap.set(key, {
                                  ...exceptTime,
                                  message: e.target.value,
                                  isValid: isValid,
                                });
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </div>
                    );
                  })}
                  <div className={`d-flex justify-content-end`}>
                    <Button
                      variant={'outline-primary'}
                      size={'sm'}
                      onClick={() => {
                        let key = uuid();
                        this.exceptTimeMap.set(key, { key, isValid: false });
                      }}
                    >
                      <MdAdd />
                    </Button>
                  </div>
                </Col>
              </FormGroup>
            </div>
          </Collapse>
        </div>
        <div className={`d-flex justify-content-end pt-3`}>
          <Collapse in={this.state.isLoad && !this.isSame}>
            <div className={`${Styles.ButtonContainer}`}>
              <Button
                variant={'outline-secondary'}
                className={Styles.Button}
                onClick={() => this.init(weekTime)}
              >
                취소
              </Button>
              <Button
                variant={'outline-primary'}
                className={`mt-3`}
                disabled={unValidExceptTime.length > 0}
                onClick={() => {
                  let data = this.form.values;
                  axios
                    .post(`/setting/week/time/update`, {
                      ...data,
                      team,
                      exceptTimes: this.exceptTimes,
                    })
                    .then(() => {
                      settingStore.fetchWorkSheet(team);
                      NotificationManager.success(`업데이트 완료`);
                    });
                }}
              >
                저장
              </Button>
            </div>
          </Collapse>
        </div>
      </>
    );
  }
}

export default WeekTimeEditor;
