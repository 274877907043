import React, { useEffect, useRef } from 'react';
import S from './Recommend.module.scss';
import { Form } from 'react-bootstrap';
import { PiArrowElbowDownRightBold } from 'react-icons/pi';
import moment from 'moment';
import { CONTENT_TYPE } from '../../const/ChatConst';
import { observer } from 'mobx-react';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const Recommend = observer(({ store }) => {
  const recommendMessages = store?.recommendMap.get(store.channelId);
  const recommendBoxRef = useRef(null);

  const scrollToBottom = () => {
    if (recommendBoxRef.current) {
      recommendBoxRef.current.scrollTop = recommendBoxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [recommendMessages]);

  const onDeleteRecommendMessge = (id) => {
    store?.recommendMap.set(
      store.channelId,
      recommendMessages?.filter((v) => v.id !== id),
    );
  };

  const onSendRecommendMessge = async (e, id) => {
    e.preventDefault();
    const contentType = CONTENT_TYPE.TEXT;
    const message = {
      content: {
        type: contentType,
        value: e.target[0].value,
      },
      contentType,
      createdAt: moment(),
    };
    onDeleteRecommendMessge(id);
    store.onSendMessage(message);
  };

  if (!recommendMessages || recommendMessages?.length === 0) return;
  return (
    <div className={S.Recommend} ref={recommendBoxRef}>
      <ul className={S.Content}>
        {recommendMessages?.map((v) => {
          return (
            <li key={v.id}>
              <div className={S.Title}>
                <div className={S.ReplyWrap}>
                  <span className={S.Reply}>{v.replyMessage?.textData}</span>
                </div>
                <Button
                  icon={<CloseOutlined />}
                  size="small"
                  type="text"
                  onClick={() => onDeleteRecommendMessge(v.id)}
                />
              </div>
              <Form onSubmit={(e) => onSendRecommendMessge(e, v.id)}>
                <PiArrowElbowDownRightBold className={S.Icon} />
                <Form.Control
                  className={S.Textarea}
                  as="textarea"
                  defaultValue={v?.textData || ''}
                />
                <Button htmlType="submit">전송</Button>
              </Form>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export default Recommend;
