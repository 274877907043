import React, { Component } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import PropTypes from 'prop-types';
import MentionInputStyle from './MentionInputStyle.js';
import MentionStyle from './MentionStyle.js';
import Styles from './MenstionInput.module.scss';
import { observer } from 'mobx-react';
import { Dropdown } from 'react-bootstrap';
import MentionIcon from './MentionIcon.js';
import MentionErrorBoundary from './MentionInputErrorBoundary.js';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div className={Styles.Toggle}>
    <button
      className={Styles.Button}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  </div>
));

@observer
class MentionInput extends Component {
  render() {
    let {
      options = [],
      onChange,
      value,
      renderHighlight,
      placeholder = `내용을 입력해주세요.`,
      isUseMention = true,
    } = this.props;
    return (
      <MentionErrorBoundary>
        <div className={Styles.MentionWrap}>
          {isUseMention && options.length > 0 && (
            <Dropdown>
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                <div>
                  <MentionIcon className={'Toggle'} />
                </div>
              </Dropdown.Toggle>
              {options.length > 0 && (
                <Dropdown.Menu style={{ padding: 0, minWidth: '5rem' }}>
                  {options?.map((option) => (
                    <Dropdown.Item key={option.id}>
                      <div
                        onClick={() => {
                          onChange && onChange(value + `{{${option.id}}}`);
                        }}
                      >
                        {option?.label}
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              )}
            </Dropdown>
          )}
          <MentionsInput
            placeholder={placeholder}
            value={value || ''}
            onChange={(e) => {
              onChange && onChange(e.target.value);
              this.CurrentRealTimeValue = e.target.value;
            }}
            style={MentionInputStyle}
            singleLine={false}
            spellCheck={false}
          >
            <Mention
              displayTransform={(id) => {
                if (renderHighlight) {
                  return renderHighlight(id, options);
                } else {
                  const option = options.find((option) => option.id === id);
                  return option ? `${option.id}` : '';
                }
                /*return `${options.find(option => option.id === id)?.label}`*/
              }}
              trigger="{{"
              data={isUseMention ? options : []}
              markup={`{{__id__}}`}
              renderSuggestion={(
                entry,
                // search,
                // highlightedDisplay,
                // index,
                // focused
              ) => {
                return <div>{entry?.label}</div>;
              }}
              style={MentionStyle}
              className={Styles.Mention}
              appendSpaceOnAdd
            />
          </MentionsInput>
        </div>
      </MentionErrorBoundary>
    );
  }
}

MentionInput.propTypes = {
  options: PropTypes.array,
};
export default MentionInput;
