export const PATH = {
  COMPANY: {
    main: {
      label: '회사 관리',
      name: '/main/company',
    },
  },
  ADMIN: {
    main: {
      label: '관리자',
      name: '/main/admin',
    },
  },
  CHAT: {
    main: {
      label: '나의티켓',
      name: '/main/chat',
    },
  },
  TICKET: {
    main: {
      label: '모든티켓 조회',
      name: '/main/ticket',
    },
  },
  DASH: {
    main: {
      label: '대시보드',
      name: '/main/dashboard',
    },
  },
  MONITOR: {
    main: {
      label: '모니터링',
      name: '/main/monitor',
    },
  },
  REPORT: {
    main: {
      label: '리포트',
      name: '/main/report',
    },
  },
  TEAM: {
    main: {
      label: '팀',
      name: '/main/team',
    },
  },
  TEAM_AGENT: {
    main: {
      label: '팀원 관리',
      name: '/main/member',
    },
  },
  AGENT: {
    main: {
      label: '상담사',
      name: '/main/agent',
    },
  },
  BOT_MESSAGE: {
    main: {
      label: '챗봇 메시지',
      name: '/main/bot',
      // name: '/main/bot/message/template',
    },
  },
  NOTICE: {
    main: {
      label: '공지사항',
      name: '/main/notice',
    },
    read: {
      label: '공지사항 조회',
      name: '/main/notice/read',
    },
    new: {
      label: '공지사항 등록',
      name: '/main/notice/new',
    },
    update: {
      label: '공지사항 수정',
      name: '/main/notice/update',
    },
  },

  QUICK: {
    main: {
      label: '빠른답변',
      name: '/main/quickmsg',
    },
  },
  BRANCH: {
    main: {
      label: '지점공감',
      name: '/main/branch-memo',
    },
  },
  SETTING: {
    main: {
      label: '설정',
      name: '/main/setting',
    },
  },
  SCENARIO: {
    main: {
      label: '시나리오',
      name: '/main/scenario',
    },
  },
  OUTBOUND_CALL: {
    main: {
      label: '아웃바운드 전화번호',
      name: '/main/call',
    },
  },
  OUTBOUND_SETTING: {
    main: {
      label: '아웃바운드 설정',
      name: '/main/ob-setting',
    },
  },
  VOICE_RECORD: {
    main: {
      label: '녹취',
      name: '/main/voice-record',
    },
  },
  STANDARD_SCENARIO: {
    main: {
      label: '시나리오 표준셋',
      name: '/main/standard-scenario',
    },
  },
  STANDARD_CHAT: {
    main: {
      label: '챗봇 표준셋',
      name: '/main/standard-chat',
    },
  },
  ANNOTATION: {
    main: {
      label: '어노테이션',
      name: '/main/annotation',
    },
  },
  PROFILE: {
    main: {
      label: '개인정보 변경',
      name: '/main/profile',
    },
  },
  PASSWORD: {
    main: {
      label: '비밀번호 변경',
      name: '/main/password',
    },
  },
};
