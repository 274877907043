import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Frame from '../../components/layout/Frame';
import AnnotationList from './AnnotationList';
import AnnotationDetailView from './AnnotationDetailView';

const AnnotationFrame = () => {
  return (
    <Frame>
      <Switch>
        <Route path="/main/annotation/list" component={AnnotationList} />
        <Route
          exact
          from="/main/annotation/detail"
          component={AnnotationDetailView}
        />
        <Redirect exact from="/main/annotation" to="/main/annotation/list" />
      </Switch>
    </Frame>
  );
};

export default AnnotationFrame;
