import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { MdClear } from 'react-icons/md';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit/modal';

@observer
class ResetImage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let { onOk } = this.props;
    return (
      <>
        <MdClear
          onClick={() => {
            confirmDialogStore.openReset(
              '초기화',
              '이미지를 초기화 하시겠습니까?',
              () => {
                onOk();
              },
            );
          }}
        />
      </>
    );
  }
}

export default ResetImage;
