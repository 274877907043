import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TicketManageDetailView from './TicketManageDetailView';
import TicketMangeListView from './TicketManageListView';
import Frame from '../../components/layout/Frame';
import appStore from '../../store/AppStore';

@observer
class TicketManageFrame extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { location } = this.props;
    return (
      <Frame>
        {appStore.chatReady && (
          <>
            <div
              className={`${location.pathname === '/main/ticket/list' ? 'd-block' : 'd-none'} h-100`}
            >
              <TicketMangeListView pathname={location.pathname} />
            </div>
            <Switch>
              <Route
                path="/main/ticket/new"
                component={TicketManageDetailView}
              />
              <Route
                path="/main/ticket/detail/:id"
                component={TicketManageDetailView}
              />
              <Redirect exact from="/main/ticket" to="/main/ticket/list" />
            </Switch>
          </>
        )}
      </Frame>
    );
  }
}

export default TicketManageFrame;
