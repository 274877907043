import React from 'react';

const QtLogo = ({ fill = '#000', width = 647, height = 95, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 647 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M646.58 0.699951H630.01V93.5199H646.58V0.699951Z"
        fill="#0078FE"
      />
      <path
        d="M601.35 93.5199H617.92L584.14 0.699951H565.02L531.24 93.5199H547.81L555.65 71.97H593.51L601.35 93.5199ZM573.75 22.25H575.41L587.48 55.4H561.69L573.76 22.25H573.75Z"
        fill="#0078FE"
      />
      <path
        d="M487.38 28.88C476.17 28.88 470.79 33.66 468.35 37.59C465.6 32.84 460.8 28.88 452.46 28.88C444.12 28.88 439.63 32.86 436.84 36.5V30.53H421.74V88.54H436.84V49.99C438.12 46.94 441.02 42.96 447.36 42.96C452.82 42.96 455.53 45.77 456.86 48.51C457.75 50.34 458.12 52.36 458.12 54.37V88.54H471.92V49.27C473.36 46.31 476.31 42.96 482.23 42.96C487.76 42.96 490.5 45.77 491.84 48.51C492.74 50.34 493.11 52.36 493.11 54.37V88.54H507.07V48.28C507.07 48.28 505.12 28.87 487.39 28.87L487.38 28.88Z"
        fill={fill}
      />
      <path
        d="M406.62 88.51H391.51V82.28C387.81 87.18 386.36 90.21 376.22 90.21C364.3 90.21 352.31 80.61 352.31 64.7V30.54H367.42V63.3C367.35 72.4 372.16 75.29 378.51 75.29C383.98 75.29 391.51 72.4 391.51 62.1V30.54H406.62V88.51Z"
        fill={fill}
      />
      <path
        d="M326.7 44.5401H340.07V30.5401H326.7V12.3101L311.9 17.1201V30.5401H302.21V44.5401H311.9C312.02 54.1601 311.9 64.3701 311.9 71.1301C311.9 87.3501 325.49 88.5501 325.49 88.5501H340.21V75.3701C340.21 75.3701 336.07 75.2901 331.62 75.2501C328.89 75.2201 326.7 73.0001 326.7 70.2801V44.5501V44.5401Z"
        fill={fill}
      />
      <path
        d="M274.99 29.02C265.21 29.02 259.9 32.43 257.04 36.5V30.53H241.94V88.54H257.04V52.72C257.44 50.91 259.81 42.95 269.43 42.95C280.25 42.95 281.15 52.78 281.15 52.78V88.53H296.17V48.27C296.17 48.27 294.07 29 274.99 29V29.02Z"
        fill={fill}
      />
      <path
        d="M211.72 30.54V36.51C206.69 31.87 200.15 28.92 192.96 28.92C177.16 28.92 163.95 42.49 163.95 59.23C163.95 75.97 177.15 90.22 192.96 90.22C200.15 90.22 206.7 86.72 211.72 82.09V88.56H226.83V30.55H211.72V30.54ZM195.21 75.29C186.13 75.29 178.76 68.42 178.76 59.33C178.76 50.24 186.12 42.97 195.21 42.97C204.3 42.97 211.72 50.25 211.72 59.33C211.72 68.41 204.29 75.29 195.21 75.29Z"
        fill={fill}
      />
      <path
        d="M153.07 88.51H137.96V82.28C134.26 87.18 132.81 90.21 122.67 90.21C110.75 90.21 98.7598 80.61 98.7598 64.7V30.54H113.87V63.3C113.8 72.4 118.61 75.29 124.96 75.29C130.43 75.29 137.96 72.4 137.96 62.1V30.54H153.07V88.51Z"
        fill={fill}
      />
      <path
        d="M90.03 83.03L78.93 71.93C83.81 64.92 86.67 56.37 86.67 47.11C86.67 23.23 67.52 3.84998 43.53 3.84998C19.54 3.84998 0.5 23.23 0.5 47.11C0.5 70.99 19.53 90.37 43.53 90.37C52.31 90.37 60.44 87.78 67.22 83.33L78.48 94.59L90.04 83.03H90.03ZM43.53 74.91C28.19 74.91 16.08 62.45 16.08 47.11C16.08 31.77 28.19 19.31 43.53 19.31C58.87 19.31 71.1 31.77 71.1 47.11C71.1 52.02 69.84 56.62 67.63 60.63L55.14 48.14L43.58 59.7L55.85 71.97C52.15 73.83 47.97 74.91 43.52 74.91H43.53Z"
        fill={fill}
      />
    </svg>
  );
};

export default QtLogo;
