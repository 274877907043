import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Styles from "./SearchCalendar.module.scss";
import { Calendar } from "react-date-range";
import moment from "moment";
import ko from "date-fns/locale/ko/index.js";
import { useOutsideClose } from "../../../hooks/UseOutsideClose";

const SearchCalendar = (prop, ref) => {
  const [item, setItem] = useState(prop.item);
  const clickRef = useRef(null);
  const boxRef = useRef(null);
  const [selectShow, setSelectShow] = useOutsideClose(boxRef);

  const onClickCalBtn = () => {
    setSelectShow(!selectShow);
  };

  useEffect(() => {
    setItem({ ...item, value: prop.item.value });
  }, [prop.item.value]);

  useEffect(() => {
    setSelectShow(false);
  }, [item]);

  useImperativeHandle(ref, () => ({
    getData() {
      return item.value;
    },
  }));

  return (
    <div ref={boxRef} className={`d-flex ${Styles.search_calendar}`}>
      <div
        onClick={() => {
          !prop.disabled && onClickCalBtn();
        }}
        className={Styles.range_picker_input_box}
      >
        <button></button>
        <label>{item.name}</label>
        <span>{item.value}</span>
      </div>
      {selectShow === true ? (
        <div ref={clickRef} className={Styles.range_picker}>
          <Calendar
            date={moment(item.value).toDate()}
            onChange={(date) => {
              let nItem = item;
              nItem.value = moment(new Date(date)).format("YYYY-MM-DD");
              setItem({ ...item, value: nItem.value });
            }}
            locale={ko}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default forwardRef(SearchCalendar);
