import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import Frame from '../../components/layout/Frame';
import ObSettingView from './ObSettingView';

@observer
class ObSettingFrame extends Component {
  render() {
    // const { location } = this.props;
    return (
      <Frame>
        <Switch>
          <Route path="/main/ob-setting" component={ObSettingView} />
        </Switch>
      </Frame>
    );
  }
}

export default ObSettingFrame;
