import React, { Suspense, useEffect, useState } from 'react';

const COMPANY_ENUM = {
  2: 'FydFrame',
};

const ErpFrame = ({ companyId, ...props }) => {
  const [ErpComponent, setErpComponent] = useState(null);

  useEffect(() => {
    if (!companyId || !Object.keys(COMPANY_ENUM).includes(String(companyId)))
      return;
    import('./Erp.jsx').then((module) => {
      setErpComponent(() => module[COMPANY_ENUM[companyId]]);
    });
  }, [companyId]);

  if (!ErpComponent) return <></>;
  return (
    <Suspense fallback="Loading...">
      <ErpComponent {...props} />
    </Suspense>
  );
};

export default ErpFrame;
