import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Redirect, Route, Switch} from "react-router-dom";
import Frame from "../../components/layout/Frame";
import MemberManageListView from "./MemberManageListView";
import AgentManageDetailView from "../agent/AgentManageDetailView";

@observer
class MemberManageFrame extends Component {

    render () {
        const {location} = this.props;
        return (
            <Frame>
                <div className={`${location.pathname === '/main/member/list' ? 'd-block' : 'd-none'} h-100`}>
                    <MemberManageListView pathname={location.pathname}/>
                </div>
                <Switch>
                    <Route path="/main/member/detail/:id" component={AgentManageDetailView}/>
                    <Redirect exact from="/main/member" to="/main/member/list"/>
                </Switch>
            </Frame>
        )
    }
}

export default MemberManageFrame;