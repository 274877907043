import React from 'react';
import Styles from './DateLine.module.scss';
import moment from "moment";

class DateLine extends React.Component {
    render() {
        let {createdAt} = this.props;
        return (
            <div className={Styles.DateLine}>
                <span className={Styles.Message}>{moment(createdAt).format('ll dddd')}</span>
            </div>
            // <div className={Styles.DateLine}>
            //     <div className={Styles.Line}>
            //         <hr/>
            //     </div>
            //     <div className={Styles.Date}>
            //         {moment(createdAt).format('ll dddd')}
            //     </div>
            //     <div className={Styles.Line}>
            //         <hr/>
            //     </div>
            // </div>
        )
    }
}

export {DateLine};
