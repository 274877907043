import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Frame from '../../components/layout/Frame';
import NoticeListView from './NoticeListView';
import NoticeDetailView from './NoticeDetailView';

@observer
class NoticeFrame extends Component {
  render() {
    const { location } = this.props;
    return (
      <Frame>
        <div
          className={`${
            location.pathname === '/main/notice/list' ? 'd-block' : 'd-none'
          } h-100`}
        >
          <NoticeListView pathname={location.pathname} />
        </div>
        <Switch>
          <Route path="/main/notice/new" component={NoticeDetailView} />
          <Route path="/main/notice/detail/:id" component={NoticeDetailView} />
          <Route path="/main/notice/read/:id" component={NoticeDetailView} />
          <Redirect exact from="/main/notice" to="/main/notice/list" />
        </Switch>
      </Frame>
    );
  }
}

export default NoticeFrame;
