import { React } from 'react';
import { PATH } from '../../../const/path';
// import { NotificationFilled, MessageFilled } from '@ant-design/icons';
import { navigate } from '../../../helper/HistoryHelper';
import { MENU } from '../../../const/AppConst';
import {
  Book1,
  Call,
  PresentionChart,
  Setting2,
  Ticket,
  UserOctagon,
} from 'iconsax-react';

// export const AGENT_ITEMS = [
//   {
//     key: PATH.CHAT.main.name,
//     label: PATH.CHAT.main.label,
//     icon: <MessageFilled />,
//   },
//   {
//     key: PATH.NOTICE.main.name,
//     label: PATH.NOTICE.main.label,
//     icon: <NotificationFilled />,
//   },
// ];

export const MAIN_ITEMS = [
  // {
  //   key: 0,
  //   label: '슈퍼관리자',
  //   icon: <UserOctagon size="16" />,
  //   auth: [MENU.COMPANY, MENU.ADMIN],
  //   children: [
  //     {
  //       auth: MENU.COMPANY,
  //       key: PATH.COMPANY.main.name,
  //       label: PATH.COMPANY.main.label,
  //     },
  //     {
  //       auth: MENU.ADMIN,
  //       key: PATH.ADMIN.main.name,
  //       label: PATH.ADMIN.main.label,
  //     },
  //   ],
  // },
  {
    key: 1,
    label: '티켓',
    icon: <Ticket size="16" />,
    children: [
      {
        auth: MENU.CHAT,
        key: PATH.CHAT.main.name,
        label: PATH.CHAT.main.label,
      },
      {
        auth: MENU.TICKET,
        key: PATH.TICKET.main.name,
        label: PATH.TICKET.main.label,
      },
    ],
  },
  {
    key: 2,
    label: '대시보드',
    icon: <PresentionChart size="16" />,
    children: [
      {
        auth: MENU.DASH,
        key: PATH.DASH.main.name,
        label: PATH.DASH.main.label,
      },
      {
        auth: MENU.MONITOR,
        key: PATH.MONITOR.main.name,
        label: PATH.MONITOR.main.label,
      },
      {
        auth: MENU.REPORT,
        key: PATH.REPORT.main.name,
        label: PATH.REPORT.main.label,
      },
    ],
  },
  {
    key: 3,
    label: '관리',
    icon: <Setting2 size="16" />,

    children: [
      {
        auth: MENU.TEAM,
        key: PATH.TEAM.main.name,
        label: PATH.TEAM.main.label,
      },
      {
        auth: MENU.TEAM_AGENT,
        key: PATH.TEAM_AGENT.main.name,
        label: PATH.TEAM_AGENT.main.label,
      },
      {
        auth: MENU.AGENT,
        key: PATH.AGENT.main.name,
        label: PATH.AGENT.main.label,
      },

      {
        auth: MENU.NOTICE,
        key: PATH.NOTICE.main.name,
        label: PATH.NOTICE.main.label,
      },
      {
        auth: MENU.QUICK,
        key: PATH.QUICK.main.name,
        label: PATH.QUICK.main.label,
      },
      {
        auth: MENU.BRANCH,
        key: PATH.BRANCH.main.name,
        label: PATH.BRANCH.main.label,
      },
      {
        auth: MENU.BOT_MESSAGE,
        key: PATH.BOT_MESSAGE.main.name,
        label: PATH.BOT_MESSAGE.main.label,
      },
      {
        auth: MENU.SETTING,
        key: PATH.SETTING.main.name,
        label: PATH.SETTING.main.label,
      },
    ],
  },
  {
    key: 4,
    label: '음성설정',
    icon: <Call size="16" />,

    children: [
      {
        auth: MENU.SCENARIO,
        key: PATH.SCENARIO.main.name,
        label: PATH.SCENARIO.main.label,
      },
      {
        auth: MENU.OUTBOUND_CALL,
        key: PATH.OUTBOUND_CALL.main.name,
        label: PATH.OUTBOUND_CALL.main.label,
      },
      {
        auth: MENU.OUTBOUND_SETTING,
        key: PATH.OUTBOUND_SETTING.main.name,
        label: PATH.OUTBOUND_SETTING.main.label,
      },
      {
        auth: MENU.VOICE_RECORD,
        key: PATH.VOICE_RECORD.main.name,
        label: PATH.VOICE_RECORD.main.label,
      },
    ],
  },
  {
    key: 5,
    label: '챗 로그 자동화',
    icon: <Book1 size="16" />,
    children: [
      {
        auth: MENU.STANDARD_SCENARIO,
        key: PATH.STANDARD_SCENARIO.main.name,
        label: PATH.STANDARD_SCENARIO.main.label,
      },
      {
        auth: MENU.STANDARD_CHAT,
        key: PATH.STANDARD_CHAT.main.name,
        label: PATH.STANDARD_CHAT.main.label,
      },
      {
        auth: MENU.ANNOTATION,
        key: PATH.ANNOTATION.main.name,
        label: PATH.ANNOTATION.main.label,
      },
    ],
  },
];

export const settingItems = [
  {
    key: PATH.PROFILE.main.name,
    label: PATH.PROFILE.main.label,
    onClick: () => {
      navigate(PATH.PROFILE.main.name);
    },
  },
  {
    key: PATH.PASSWORD.main.name,
    label: PATH.PASSWORD.main.label,
    onClick: () => {
      navigate(PATH.PASSWORD.main.name);
    },
  },
];

// export const ticketItems = computed(() => {
//   return [
//     {
//       label: `${ticketStore.tickets.length} / 20`,
//       icon: (
//         <Badge
//           dot
//           color={
//             ticketStore.tickets.find((ticket) => !ticket.isConfirm)
//               ? colorStore.colors.chActive
//               : colorStore.colors.coolGray3
//           }
//           count={ticketStore.tickets.length}
//           className={S.Badge}
//         >
//           <Tag data-status="ing">진행</Tag>
//         </Badge>
//       ),
//       path: PATH.chat.main.name + '/ing',
//     },
//     {
//       label: '모든 티켓',
//       icon: <Tag data-status="complete">완료</Tag>,
//       path: PATH.chat.main.name + '/complete',
//     },
//   ];
// });
