import React from 'react';
import Styles from './Message.module.scss'
import {observer} from "mobx-react";
import MessageErrorBoundary from "./MessageErrorBoundary.js";

@observer
class Message extends React.Component {

    replyMessage(message){
        let {store} = this.props;
        store.replyMessage = message
    }

    render() {
        return (
            <div className={Styles.Message}>
                <MessageErrorBoundary>
                    {
                        this.props.children
                    }
                </MessageErrorBoundary>
            </div>
        )
    }
}

export default Message;
