import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from "./SettingManageView.module.scss";

@observer
class SettingMenu extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        let {id, name, onKeyChange, activeKey, icon} = this.props;
        return (
            <div className={`${Styles.Menu} ${id == activeKey ? Styles.Active : ''}`} onClick={()=>onKeyChange(id)}>
                {icon}{name}
            </div>
        );
    }
}

export default SettingMenu;
