import React, {Component} from 'react';
import {Action, Fab} from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

class FloatingButton extends Component {
    render() {

        const {actions, icon} = this.props;
         return (
            <Fab
               icon={icon}
            >
                {
                    actions && actions.map((action, index) => {
                        return <Action
                            key={index}
                            text={action.text}
                            onClick={action.onClick && action.onClick}
                        >
                            {action.icon}
                        </Action>

                    })
                }
            </Fab>

        );
    }
}

FloatingButton.propTypes = {};

export default FloatingButton;