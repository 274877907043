import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Redirect, Route, Switch} from "react-router-dom";
import ChatbotManageDetailView from "./ChatbotManageDetailView";
import RegstrationImageModalView from "../../components/dialog/UploadDialog";
import ChatbotManageListView from "./ChatbotManageListView";
import Frame from "../../components/layout/Frame";

@observer
class AdminMangeFrame extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const {location} = this.props;
        return (
            <Frame>
                <div className={`${location.pathname === '/main/setting/manage/chat_bot/list' ? 'd-block' : 'd-none'} h-100`}>
                    <ChatbotManageListView pathname={location.pathname}/>
                </div>
                <Switch>
                    <Route path="/main/setting/manage/chat_bot/new" component={ChatbotManageDetailView}/>
                    <Route path="/main/setting/manage/chat_bot/detail/:id" component={ChatbotManageDetailView}/>
                    <Route path="/main/setting/manage/chat_bot/modal" component={RegstrationImageModalView}/>
                    <Redirect exact from="/main/setting/manage/chat_bot" to="/main/setting/manage/chat_bot/list"/>
                </Switch>
            </Frame>
        );
    }
}

export default AdminMangeFrame;
