import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { FormStore, Input, Validation } from '../components/validation';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { goBack } from '../helper/HistoryHelper';
import Panel from '../components/layout/Panel';
import { ConfirmDialog } from '@qbot-chat/qbot-uikit';
import TitlePanelLabel from '../components/label/TitlePanelLabel';
import TitlePanel from '../components/layout/TitlePanel';
import FrameWrap from '../components/layout/FrameWrap';

const form = new FormStore();

@observer
class AgentPasswordChangeView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  save() {
    if (!form.doCheckValid()) {
      NotificationManager.warning(form.firstErrorMessage);
      return;
    }
    axios.post(`/user/update/password`, form.values).then(() => {
      //   let id = res.data;
      NotificationManager.success('변경사항이 저장되었습니다.');
      form.clear();
    });
  }

  render() {
    return (
      <FrameWrap className={'w-100'}>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'비밀번호 변경'} />
              <div>
                <Button
                  variant={'outline-secondary'}
                  onClick={() => {
                    goBack();
                  }}
                >
                  취소
                </Button>
                <Button
                  variant={'outline-primary'}
                  className={`ms-1`}
                  onClick={() => {
                    this.save();
                  }}
                >
                  저장
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>기존 비밀번호</FormLabel>
                <Input
                  store={form}
                  label={`비밀번호`}
                  name={`password`}
                  placeholder={`비밀번호를 입력해주세요`}
                  type={`password`}
                  validations={[
                    new Validation(/^.+$/, `비밀번호를 입력하세요.`),
                  ]}
                  validOnBlur
                />
              </Col>
              <Col className={`mb-3`}></Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>새 비밀번호</FormLabel>
                <Input
                  store={form}
                  label={`새 비밀번호`}
                  name={`newPassword`}
                  placeholder={`비밀번호를 입력해주세요`}
                  onChange={(e) => {
                    form.newPassword = e.target.value;
                  }}
                  type={`password`}
                  validations={[
                    new Validation(/^.+$/, `비밀번호를 입력하세요.`),
                  ]}
                  validOnBlur
                />
              </Col>
              <Col className={`mb-3`}>
                <FormLabel>새 비밀번호 확인</FormLabel>
                <Input
                  store={form}
                  label={`비밀번호 확인`}
                  name={`newCheckPassword`}
                  placeholder={`비밀번호를 입력해주세요`}
                  type={`password`}
                  //   onChange={(e) => {}}
                  validations={[
                    new Validation(/^.+$/, `비밀번호를 입력하세요.`),
                    new Validation(
                      (value) => form.valueMap.get('newPassword') === value,
                      `새 비밀번호와 비밀번호 확인이 일치하지 않습니다.`,
                    ),
                  ]}
                  validOnBlur
                />
              </Col>
            </FormGroup>
          </Form>
        </Panel>
        <ConfirmDialog />
      </FrameWrap>
    );
  }
}

export default AgentPasswordChangeView;
