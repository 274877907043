import React, {Component} from 'react';
import {observer} from "mobx-react";
import {CONTENT_TYPE} from "../../../const/ChatConst.js";
import {MdAudioFile, MdFilePresent, MdImage, MdVideoFile} from "react-icons/md";

@observer
class ContractionMessage extends Component {

    render() {
        let {message} = this.props;
        let {content} = message;
        let {type, value} = content;
        switch (type) {
            case CONTENT_TYPE.TEXT :
                return `${value}`;
            case CONTENT_TYPE.IMAGE :
                return <span className={`d-flex justify-content-start align-items-center`}><MdImage className={`me-1`}/>{`이미지`}</span>
            case CONTENT_TYPE.FILE :
                return <span className={`d-flex justify-content-start align-items-center`}><MdFilePresent className={`me-1`}/>{`파일`}</span>
            case CONTENT_TYPE.VIDEO :
                return <span className={`d-flex justify-content-start align-items-center`}><MdVideoFile className={`me-1`}/>{`영상`}</span>
            case CONTENT_TYPE.AUDIO :
                return <span className={`d-flex justify-content-start align-items-center`}><MdAudioFile className={`me-1`}/>{`오디오`}</span>
            case CONTENT_TYPE.CUSTOM :
                return value.find(content=>content.type === CONTENT_TYPE.TEXT)?.value
            case CONTENT_TYPE.DIALOG_FLOW_RESPONSE :
                return value.find(content=>content.type === CONTENT_TYPE.TEXT)?.value
            default:
                return `${value}`;
        }
    }
}

export default ContractionMessage;