/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import Styles from './SearchForm.module.scss';
import { Button } from 'react-bootstrap';
import { useObserver } from 'mobx-react';

import SearchDropDown from './SearchDropDown';
import SearchCalendar from './SearchCalendar';
import SearchInputDown from './SearchInputDown';
import SearchInputGroup from './SearchInputGroup';
import SearchRangeCalendar from './SearchRangeCalendar';
import SearchMonth from './SearchMonth';
import SearchYear from './SearchYear';
import SearchRadio from './SearchRadio';
import './ymcalendar.css';
/**
 * Example (items 배열 인덱스 순으로 생성됩니다.)
    const searchConfig = {
    searchBtnCallback: (params) => {
      setConfig({ ...config, params: params });
    },
    showCalendar:true,
    items: [
      {
        type:'range'
      },
      {
        name:'기준년월',
        type:'month',
        use: true,
        fieldName:'basmm',
        value:moment().format("YYYY-MM")
      },
      {
        name:'생성일',
        type:'calendar',
        use: true,
        fieldName:'created_at',
        value:moment().format("YYYY-MM-DD")
      },
      {
        name: '배정구분',
        type: 'dropdown',
        use: true,
        lc_code: 'asgn_div',
        data: [],
        fieldName: 'status',
        getDatasApi:getCodes
      },
      {
        name: '채널',
        type: 'dropdown',
        use: true,
        lc_code: 'ticket_chan',
        data: [],
        fieldName: 'ticket_chan',
        getDatasApi:getCodes
      },
      {
        type: 'inputdown',
        use: true,
        fieldName: 'name', // init
        data: [
          {code:'name',name:'고객명'},
          {code:'agent_name',name:'상담원명'}
        ],
        value: '늑대',
        maxLength: 20
      },
      {
        name: '고객명',
        type: 'input',
        use: true,
        placeholder: '고객명',
        fieldName: 'name',
        value: '홍길동',
        maxLength: 5
      }
    ]
  }

    <SearchForm config={searchConfig} />
 * 
 * @param {*} props 
 * @returns 
 */
const SearchForm = (props) => {
  const [config, setConfig] = useState(props.config);
  const [searchItems, setSearchItems] = useState(props.config.items);

  const refs = useRef([]);

  // useEffect(() => {
  //     console.group("useEffect() config");
  //     console.log(config);
  //     console.groupEnd();
  // }, [config])

  // useEffect(() => {
  //     console.group("useEffect() searchItems");
  //     console.log(searchItems);
  //     console.groupEnd();
  // }, [searchItems])

  const onClickSearchBtn = () => {
    var result = {};

    for (let i = 0; i < searchItems.length; i++) {
      let item = searchItems[i];
      let refInst = refs.current[i].getData();

      if (item.type === 'dropdown' && refInst.selectedCode === '-1') {
        continue;
      }

      switch (item.type) {
        case 'input':
        case 'inputdown':
        case 'calendar':
        case 'month':
        case 'year':
          if (refInst.value == '') {
            continue;
          }
          break;
      }

      switch (item.type) {
        case 'dropdown':
          result[refInst.fieldName] = refInst.selectedCode;
          break;
        case 'inputdown':
        case 'radio':
          result[refInst.fieldName] = refInst.value;
          break;
        case 'input':
        case 'calendar':
        case 'month':
        case 'year':
          result[item.fieldName] = refInst;
          break;
        case 'range':
          result['start_dt'] = refInst.start_dt;
          result['end_dt'] = refInst.end_dt;
          break;
      }
    }

    config.searchBtnCallback(result);
  };

  return useObserver(() => {
    return (
      <div className={`d-flex ${Styles.search_form_box}`}>
        {searchItems.length === 0 ? (
          <></>
        ) : (
          searchItems.map((item, idx) => {
            switch (item.type) {
              case 'year':
                return (
                  <SearchYear
                    ref={(el) => (refs.current[idx] = el)}
                    key={idx}
                    item={item}
                  ></SearchYear>
                );
              case 'month':
                return (
                  <SearchMonth
                    ref={(el) => (refs.current[idx] = el)}
                    key={idx}
                    item={item}
                  ></SearchMonth>
                );
              case 'range':
                return (
                  <SearchRangeCalendar
                    ref={(el) => (refs.current[idx] = el)}
                    key={idx}
                    item={item}
                  ></SearchRangeCalendar>
                );
              case 'calendar':
                return (
                  <SearchCalendar
                    ref={(el) => (refs.current[idx] = el)}
                    key={idx}
                    item={item}
                  ></SearchCalendar>
                );
              case 'dropdown':
                return (
                  <SearchDropDown
                    ref={(el) => (refs.current[idx] = el)}
                    key={idx}
                    item={item}
                  ></SearchDropDown>
                );
              case 'inputdown':
                return (
                  <SearchInputDown
                    ref={(el) => (refs.current[idx] = el)}
                    key={idx}
                    item={item}
                  ></SearchInputDown>
                );
              case 'input':
                return (
                  <SearchInputGroup
                    ref={(el) => (refs.current[idx] = el)}
                    key={idx}
                    item={item}
                  ></SearchInputGroup>
                );
              case 'radio':
                return (
                  <SearchRadio
                    ref={(el) => (refs.current[idx] = el)}
                    key={idx}
                    item={item}
                  ></SearchRadio>
                );
            }
          })
        )}
        <Button
          className={`${Styles.search_btn}`}
          onClick={() => {
            onClickSearchBtn();
            props.setLoaded?.(false);
          }}
        >
          조회
        </Button>
      </div>
    );
  });
};

export default SearchForm;
