import React, { Component } from 'react';
import { Button, Collapse, Image } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { FormStore, Input, Validation } from '../components/validation';
import appStore from '../store/AppStore';
import { Redirect } from 'react-router-dom';
import { makeObservable } from 'mobx';
import Styles from './LoginView.module.scss';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { navigate } from '../helper/HistoryHelper';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

const loginForm = new FormStore();
const store = loginForm;

@observer
class LoginView extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      type: 'password',
    };
  }

  componentDidMount() {}

  changeType() {
    if (this.state.type === 'password') {
      this.setState({
        type: 'text',
      });
    } else
      this.setState({
        type: 'password',
      });
  }

  updatePw() {
    const dataConfig = {
      password: loginForm.valueMap.get('password'),
      newPassword: loginForm.valueMap.get('newPassword'),
      newCheckPassword: loginForm.valueMap.get('newCheckPassword'),
    };
    if (dataConfig.newPassword !== dataConfig.newCheckPassword) {
      return NotificationManager.error('새로운 비밀번호가 일치하지 않습니다.');
    }
    axios
      .post('/user/update/password', dataConfig)
      .then(() => {
        navigate('/main');
        NotificationManager.success('비밀번호가 변경되었습니다.');
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const paddingTop = 13;
    const paddingBottom = 13;
    return (
      <div className={`d-flex w-100 h-100 a`} style={{ padding: 0 }}>
        {appStore.isLogin && !appStore.isFirstLogin && <Redirect to="/main" />}
        <div className={Styles.LoginForm}>
          <form
            className={Styles.Form}
            onSubmit={(e) => {
              e.preventDefault();
              appStore.fetchLogin({
                form: loginForm.values,
              });
            }}
          >
            <div className={Styles.Logo}>
              {/* <Image src={`/asset/img/erodyHello.svg`}/> */}
              <Image src={`/asset/img/qt_logo.png`} className={Styles.Img} />
            </div>
            {!appStore.isLogin ? (
              <div className={Styles.InputGroup}>
                <div className={`${Styles.Row}`}>
                  <div className={Styles.Input}>
                    <Input
                      store={store}
                      style={{
                        backgroundColor: '#fff',
                        color: '#6C757D',
                        minWidth: '270px',
                        maxWidth: '270px',
                        paddingTop,
                        paddingBottom,
                      }}
                      label={`ID`}
                      name={`loginId`}
                      placeholder={`아이디를 입력`}
                      validations={[
                        new Validation(/^.+$/, `아이디를 입력하세요.`),
                      ]}
                      validOnBlur
                    />
                  </div>
                </div>
                <div className={`${Styles.Row}`}>
                  <div className={Styles.Input}>
                    <Input
                      store={store}
                      style={{
                        backgroundColor: '#fff',
                        color: '#6C757D',
                        minWidth: '270px',
                        maxWidth: '270px',
                        paddingTop,
                        paddingBottom,
                      }}
                      label={`PASSWORD`}
                      name={`password`}
                      placeholder={`비밀번호를 입력`}
                      type={`password`}
                      validations={[
                        new Validation(/^.+$/, `비밀번호를 입력하세요.`),
                      ]}
                      validOnBlur
                    />
                  </div>
                </div>
                <div className={`${Styles.Row}`}>
                  <Button
                    disabled={!store.valid}
                    style={{
                      height: 54,
                      fontSize: 20,
                      fontWeight: 700,
                      color: '#fff',
                      minWidth: '270px',
                      maxWidth: '270px',
                    }}
                    variant="primary"
                    type="submit"
                  >
                    로그인
                  </Button>
                </div>
              </div>
            ) : (
              appStore.isFirstLogin && (
                <Collapse in={appStore.isLogin && appStore.isFirstLogin}>
                  <div className={Styles.InputGroup}>
                    <div className={`${Styles.Row}`}>
                      <div className={Styles.Input}>
                        <Input
                          store={store}
                          style={{
                            backgroundColor: '#fff',
                            color: '#6C757D',
                            minWidth: '270px',
                            maxWidth: '270px',
                            paddingTop,
                            paddingBottom,
                          }}
                          label={`PASSWORD`}
                          name={`password`}
                          placeholder={`비밀번호를 입력`}
                          type={`password`}
                          validations={[
                            new Validation(/^.+$/, `비밀번호를 입력하세요.`),
                          ]}
                          validOnBlur
                          readOnly
                        />
                      </div>
                    </div>
                    <div className={`${Styles.Row}`}>
                      <div className={Styles.Input}>
                        <Input
                          store={store}
                          style={{
                            backgroundColor: '#fff',
                            color: '#6C757D',
                            minWidth: '270px',
                            maxWidth: '270px',
                            paddingTop,
                            paddingBottom,
                          }}
                          label={`newPassword`}
                          name={`newPassword`}
                          placeholder={`새로운 비밀번호 입력`}
                          type={this.state.type}
                          validations={[
                            new Validation(/^.+$/, `비밀번호를 입력하세요.`),
                          ]}
                          validOnBlur
                        />
                      </div>
                      {this.state.type === 'password' ? (
                        <BsEye
                          className={Styles.Icon}
                          onClick={() => this.changeType()}
                        />
                      ) : (
                        <BsEyeSlash
                          className={Styles.Icon}
                          onClick={() => this.changeType()}
                        />
                      )}
                    </div>
                    <div className={`${Styles.Row}`}>
                      <div className={Styles.Input}>
                        <Input
                          store={store}
                          style={{
                            backgroundColor: '#fff',
                            color: '#6C757D',
                            minWidth: '270px',
                            maxWidth: '270px',
                            paddingTop,
                            paddingBottom,
                          }}
                          label={`newCheckPassword`}
                          name={`newCheckPassword`}
                          placeholder={`새로운 비밀번호 확인`}
                          type={this.state.type}
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              this.updatePw();
                            }
                          }}
                          validations={[
                            new Validation(/^.+$/, `비밀번호를 입력하세요.`),
                          ]}
                          validOnBlur
                        />
                      </div>
                    </div>
                    <div>
                      <Button
                        disabled={!store.valid}
                        style={{
                          height: 54,
                          fontSize: 20,
                          fontWeight: 700,
                          color: '#fff',
                          minWidth: '270px',
                          maxWidth: '270px',
                        }}
                        variant="primary"
                        type="button"
                        onClick={() => this.updatePw()}
                      >
                        변경하여 로그인
                      </Button>
                    </div>
                  </div>
                </Collapse>
              )
            )}
          </form>
        </div>
      </div>
    );
  }
}

LoginView.propTypes = {};

export default LoginView;
