import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { computed, makeObservable, observable } from 'mobx';
import dayjs from 'dayjs';
import { Image } from 'react-bootstrap';
import RandomColor from '../../components/avatar/RandomColor';
import DefaultAvatar from '../../components/avatar/DefaultAvatar';
import Panel from '../../components/layout/Panel';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import CommonHelper from '../../helper/CommonHelper';
import InfoReport from './InfoReport';
import Styles from './Report.module.scss';
import Avatar from '../../components/avatar/Avatar';
import { CHART_TOOLBAR_OPTION } from '../../util/const';

const botReportDataGridStore = new DataGridStore({
  fetchUrl: `/report/bot/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`agent`, Sort.SORT_DIRECTION.ASC),
  // noHeader: true,
});
@observer
class BotReport extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable
  datas = [];

  @computed
  get dayCategories() {
    return this.datas?.map((data) => dayjs(data.createdAt).format('MM월 DD일'));
  }

  @computed
  get closeTicket() {
    return {
      options: {
        chart: {
          id: 'basic-bar',
          toolbar: CHART_TOOLBAR_OPTION,
        },
        xaxis: {
          categories: this.dayCategories,
        },
      },
      series: [
        {
          name: '종료된 티켓',
          data: this.datas?.map((data) => data.closeTicketCount),
        },
      ],
    };
  }

  @computed
  get completePercent() {
    return {
      options: {
        chart: {
          id: 'basic-bar',
          toolbar: CHART_TOOLBAR_OPTION,
        },
        xaxis: {
          categories: this.dayCategories,
        },
      },
      series: [
        {
          name: '배정된 티켓 종료율',
          data: this.datas?.map((data) => data.completeTicketPercent),
        },
      ],
    };
  }

  @computed
  get assignTicket() {
    return {
      options: {
        chart: {
          id: 'basic-bar',
          toolbar: CHART_TOOLBAR_OPTION,
        },
        xaxis: {
          categories: this.dayCategories,
        },
      },
      series: [
        {
          name: '배정받은 횟수',
          data: this.datas?.map((data) => data.assignmentTicketCount),
        },
      ],
    };
  }

  @computed
  get avgComplete() {
    return {
      options: {
        chart: {
          id: 'basic-bar',
          toolbar: CHART_TOOLBAR_OPTION,
        },
        yaxis: {
          labels: {
            formatter: CommonHelper.timeFormat,
          },
        },
        xaxis: {
          categories: this.dayCategories,
        },
      },
      series: [
        {
          name: '배정부터 종료까지 평균 시간',
          data: this.datas?.map((data) => data.avgCompleteTime),
        },
      ],
    };
  }

  @computed
  get avgAssignment() {
    return {
      options: {
        chart: {
          id: 'basic-bar',
          toolbar: CHART_TOOLBAR_OPTION,
        },
        yaxis: {
          labels: {
            formatter: CommonHelper.timeFormat,
          },
        },
        xaxis: {
          categories: this.dayCategories,
        },
      },
      series: [
        {
          name: '배정부터 종료까지 평균 시간',
          data: this.datas?.map((data) => data.avgAssignmentTime),
        },
      ],
    };
  }

  @computed
  get ratingCount() {
    return {
      options: {
        chart: {
          width: 380,
          type: 'donut',
          toolbar: CHART_TOOLBAR_OPTION,
        },
        labels: ['5점', '4점', '3점', '2점', '1점'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: '총 응답 수',
                },
              },
            },
          },
        },
      },
      series: [
        this.datas
          ?.map((data) => data.ratingFivePointCount)
          .reduce((x, y) => x + y, 0),
        this.datas
          ?.map((data) => data.ratingFourPointCount)
          .reduce((x, y) => x + y, 0),
        this.datas
          ?.map((data) => data.ratingThreePointCount)
          .reduce((x, y) => x + y, 0),
        this.datas
          ?.map((data) => data.ratingTwoPointCount)
          .reduce((x, y) => x + y, 0),
        this.datas
          ?.map((data) => data.ratingOnePointCount)
          .reduce((x, y) => x + y, 0),
      ],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate, selectedBot } = this.props;
    if (
      prevProps.startDate !== startDate ||
      prevProps.endDate !== endDate ||
      prevProps.selectedBot !== selectedBot
    ) {
      this.fetch();
    }
  }

  fetch = () => {
    const { startDate, endDate, selectedBot } = this.props;
    botReportDataGridStore.page = 1;
    botReportDataGridStore.searchCondition.clear();
    botReportDataGridStore.searchCondition.set('startDate', startDate);
    botReportDataGridStore.searchCondition.set('endDate', endDate);
    botReportDataGridStore.searchCondition.set(
      'agentId',
      selectedBot ? selectedBot.id : null,
    );
    botReportDataGridStore.fetch();
    this.botReport();
  };

  botReport = () => {
    const { startDate, endDate, selectedBot } = this.props;
    axios
      .post(`/report/bot`, {
        startDate,
        endDate,
        agentId: selectedBot ? selectedBot.id : null,
      })
      .then((res) => {
        this.datas = res.data.botReports;
      });
  };

  avatar = (agent) => {
    return (
      <>
        {agent.avatar ? (
          <Image
            className={'rounded-circle'}
            style={{ width: '2rem' }}
            src={agent.avatar}
          />
        ) : (
          <div style={{ width: '2rem' }}>
            <RandomColor seed={agent.id}>
              <DefaultAvatar className={'rounded-circle'} />
            </RandomColor>
          </div>
        )}
        <div className={'ms-1'}> {agent.name} </div>
      </>
    );
  };

  viewReturn = () => {
    const { viewMode } = this.props;
    return {
      table: (
        <Panel className={'flex-grow-1'}>
          <DataGrid
            store={botReportDataGridStore}
            keyColumn={`id`}
            columns={[
              <Header
                id={`agent`}
                key={`agent`}
                name={`이름`}
                printFunction={(agent) => {
                  return (
                    <div className={'w-100 d-flex flex-row gap-2'}>
                      <Avatar
                        type={'chatbot'}
                        seed={agent.id}
                        width={'1.5rem'}
                        src={agent?.avatar?.fullUrl}
                      />
                      <span>{agent.name}</span>
                    </div>
                  );
                }}
                sortable
              />,
              <Header
                id={`assignmentTicketCount`}
                key={`assignmentTicketCount`}
                name={`배정받은 횟수`}
                sortable
                width={{ min: 130, current: 130 }}
                info={`봇이 티켓을 배정받은 횟수입니다. 동일한 티켓을 여러 번 배정 혹은 이관받으면 횟수는 누적하여 계산됩니다.`}
              />,
              <Header
                id={`closeTicketCount`}
                key={`closeTicketCount`}
                name={`종료된 티켓`}
                sortable
                width={{ min: 120, current: 120 }}
                info={`봇이 종료한 총 티켓 수입니다.`}
              />,
              <Header
                id={`completeTicketPercent`}
                key={`completeTicketPercent`}
                name={`배정된 티켓 종료율`}
                sortable
                width={{ min: 160, current: 160 }}
                info={`종료된 티켓의 총합을 받은 티켓 수로 나눈 수입니다.`}
              />,
              <Header
                id={`avgCompleteTime`}
                key={`avgCompleteTime`}
                name={`종료까지 평균 시간`}
                width={{ min: 160, current: 160 }}
                printFunction={(avgCompleteTime) =>
                  avgCompleteTime
                    ? CommonHelper.timeFormat(avgCompleteTime)
                    : '-'
                }
                sortable
                info={`봇이 티켓을 배정받은 때로부터 티켓을 종료하기까지 걸린 평균 시간입니다.`}
              />,
              <Header
                id={`avgAssignmentTime`}
                key={`avgAssignmentTime`}
                name={`이관까지 평균 시간`}
                width={{ min: 160, current: 160 }}
                printFunction={(avgAssignmentTime) =>
                  avgAssignmentTime
                    ? CommonHelper.timeFormat(avgAssignmentTime)
                    : '-'
                }
                sortable
                info={`봇이 티켓을 배정받은 때로부터 티켓을 일반 상담원에게 이관하기까지 걸린 평균 시간입니다.`}
              />,
              <Header
                id={`avgRating`}
                key={`avgRating`}
                name={`평균 고객 만족도`}
                width={{ min: 150, current: 150 }}
                printFunction={(avgRating) => avgRating || '-'}
                sortable
                info={`봇이 받은 평균 고객 만족도 점수입니다.`}
              />,
            ]}
          />
        </Panel>
      ),
      graph: (
        <div className={Styles.BotContainer}>
          <div className={Styles.Panel}>
            <InfoReport
              title={'종료된 티켓'}
              summary={'모든 혹은 선택한 봇이 종료한 총 티켓 수입니다.'}
            />
            <ReactApexChart
              options={this.closeTicket.options}
              series={this.closeTicket.series}
              type={'line'}
            />
          </div>
          <div className={Styles.Panel}>
            <InfoReport
              title={'배정된 티켓 종료율'}
              summary={'종료된 티켓의 총합을 받은 티켓 수로 나눈 수입니다.'}
            />
            <ReactApexChart
              options={this.completePercent.options}
              series={this.completePercent.series}
              type={'line'}
            />
          </div>
          <div className={Styles.Panel}>
            <InfoReport
              title={'배정받은 횟수'}
              summary={
                '모든 혹은 선택한 봇이 티켓을 배정받은 횟수입니다. 동일한 티켓을 여러 번 배정 혹은 이관받으면 횟수는 누적하여 계산됩니다.'
              }
            />
            <ReactApexChart
              options={this.assignTicket.options}
              series={this.assignTicket.series}
              type={'line'}
            />
          </div>
          <div className={Styles.Panel}>
            <InfoReport
              title={'배정부터 종료까지 평균 시간'}
              summary={
                '봇이 티켓을 배정받은 때로부터 티켓을 종료하기까지 걸린 평균 시간입니다.'
              }
            />
            <ReactApexChart
              options={this.avgComplete.options}
              series={this.avgComplete.series}
              type={'line'}
            />
          </div>
          <div className={Styles.Panel}>
            <InfoReport
              title={'이관까지 평균 시간'}
              summary={
                '봇이 티켓을 배정받은 때로부터 티켓을 일반 상담원에게 이관하기까지 걸린 평균 시간입니다.'
              }
            />
            <ReactApexChart
              options={this.avgAssignment.options}
              series={this.avgAssignment.series}
              type={'line'}
            />
          </div>
          <div
            className={`${Styles.Panel} d-flex flex-column h-100 justify-center`}
          >
            <InfoReport
              title={'고객 만족도'}
              summary={'모든 혹은 선택한 봇이 받은 고객 만족도 정보입니다.'}
            />
            <div className={'d-flex justify-center'}>
              <ReactApexChart
                options={this.ratingCount.options}
                series={this.ratingCount.series}
                type={'donut'}
                width={380}
              />
            </div>
          </div>
        </div>
      ),
    }[viewMode];
  };
  render() {
    return (
      <div className={'h-100 d-flex flex-column'}>{this.viewReturn()}</div>
    );
  }
}

export { BotReport as default, botReportDataGridStore };
