import React from 'react';
import Styles from './CustomButton.module.scss';

class CustomButton extends React.Component {
  render() {
    let { children, className, onClick, disabled = false } = this.props;
    return (
      <div
        {...this.props}
        className={`${disabled ? Styles.Disabled : ''} ${className} ${Styles.Button} `}
        onClick={() => {
          if (!disabled) {
            onClick && onClick();
          }
        }}
      >
        {children}
      </div>
    );
  }
}

export { CustomButton as default };
