import React, { Component } from 'react';
import { FormStore, Input, Validation } from '../../components/validation';
import { observer } from 'mobx-react';
import axios from 'axios';
import { goBack, navigateReplace } from '../../helper/HistoryHelper';
import { NotificationManager } from 'react-notifications';
import { quickMsgDataGridStore } from './QuickMsgListView';
import { Button, Col, Form, FormGroup, InputGroup, Row } from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import { makeObservable, observable } from 'mobx';
import Styles from './QuickMsg.module.scss';
import { confirmDialogStore, ConfirmDialog } from '@qbot-chat/qbot-uikit/modal';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import appStore from '../../store/AppStore';
import { USER_TYPE } from '@qbot-chat/qbot-uikit';

const form = new FormStore();

@observer
class QuickMsgDetailView extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable
  type = 'PERSONAL';

  reset() {
    form.clear();
  }

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    if (params && params.id) {
      this.fetchDetail(params.id);
    } else {
      this.reset();
    }
  }
  componentDidUpdate(prevProps) {
    let {
      match: { params },
    } = this.props;
    let prevParams = prevProps.match.params;
    if (params.id != prevParams.id) {
      if (params && params.id) {
        this.fetchDetail(params.id);
      } else if (!params.id) {
        this.reset();
      }
    }
  }

  fetchDetail(id) {
    axios.post(`/quickmsg/detail/${id}`, {}).then((res) => {
      form.setValue('id', id);
      this.type = res.data.quickMsgType;
      form.setValue('title', res.data.title);
      form.setValue('text', res.data.content);
      form.setValue('name', res.data.creator.name);
    });
  }

  save() {
    form.setValue('quickMsgType', this.type);
    form.setValue('content', form.valueMap.get('text'));
    if (!form.doCheckValid()) {
      NotificationManager.warning(form.firstErrorMessage);
      return;
    }
    if (!!this.props.match.params.id) {
      confirmDialogStore.openSave('수정', '수정 하시겠습니까?', () => {
        axios.post(`/quickmsg/update`, form.values).then((res) => {
          let id = res.data;
          quickMsgDataGridStore.fetch();
          NotificationManager.success('빠른답변이 수정되었습니다.');
          navigateReplace(`/main/quickmsg/detail/${id}`);
        });
      });
    } else {
      confirmDialogStore.openAdd('저장', '저장 하시겠습니까?', () => {
        axios.post(`/quickmsg/insert`, form.values).then((res) => {
          let id = res.data;
          NotificationManager.success('빠른답변이 등록되었습니다.');
          quickMsgDataGridStore.fetch();
          navigateReplace(`/main/quickmsg/detail/${id}`);
        });
      });
    }
  }

  delete() {
    if (!!this.props.match.params.id) {
      confirmDialogStore.openRemove('삭제', '삭제 하시겠습니까?', () => {
        axios
          .post(`/quickmsg/delete`, { id: this.props.match.params.id })
          .then(() => {
            quickMsgDataGridStore.fetch();
            NotificationManager.success('빠른답변이 삭제되었습니다.');
            navigateReplace(`/main/quickmsg/list`);
          });
      });
    }
  }

  render() {
    let isNew = !form.valueMap.get('id');
    const { AGENT, AGENT_BOT, AGENT_LEADER } = USER_TYPE;
    const isAgent = [AGENT, AGENT_BOT, AGENT_LEADER].includes(appStore.type);
    return (
      <div className={Styles.Container}>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'빠른답변'} />
              <div>
                {((!isNew && isAgent && this.type === 'PERSONAL') ||
                  (!isNew && !isAgent)) && (
                  <Button
                    variant={'outline-danger'}
                    onClick={() => this.delete()}
                  >
                    삭제
                  </Button>
                )}
                <Button
                  variant={'outline-secondary'}
                  className={'ms-4'}
                  onClick={() => goBack()}
                >
                  취소
                </Button>
                {(isNew ||
                  (isAgent && this.type === 'PERSONAL') ||
                  !isAgent) && (
                  <Button
                    variant={'outline-primary'}
                    className={'ms-1'}
                    onClick={() => this.save()}
                  >
                    저장
                  </Button>
                )}
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel className={Styles.Panel}>
          <div>
            <Form>
              {!isNew && (
                <FormGroup as={Row} className={'mb-3'}>
                  <Col md={2}>
                    <div className={Styles.Label}>작성자</div>
                  </Col>
                  <Col md={10} className={'d-flex flex-row'}>
                    <InputGroup>
                      <Input
                        store={form}
                        label={`creator`}
                        name={`name`}
                        disabled
                      />
                    </InputGroup>
                  </Col>
                </FormGroup>
              )}
              <FormGroup as={Row} className={'mb-3'}>
                <Col md={2}>
                  <div className={Styles.Label}>구분</div>
                </Col>
                <Col md={10} className={'d-flex flex-row'}>
                  <div className={'d-flex'}>
                    <Form.Check
                      className={'me-2'}
                      label={'공통'}
                      type={'radio'}
                      name={'type'}
                      id={'common'}
                      checked={this.type === 'COMMON'}
                      onChange={() => (this.type = 'COMMON')}
                      disabled={isAgent}
                    />
                    <Form.Check
                      label={'개인'}
                      type={'radio'}
                      name={'type'}
                      id={'personal'}
                      checked={this.type === 'PERSONAL'}
                      onChange={() => (this.type = 'PERSONAL')}
                      disabled={!isNew && isAgent}
                    />
                  </div>
                </Col>
              </FormGroup>
              <FormGroup as={Row} className={'mb-3'}>
                <Col md={2}>
                  <div className={Styles.Label}>제목</div>
                </Col>
                <Col md={10} className={'d-flex flex-row'}>
                  <InputGroup>
                    <Input
                      store={form}
                      label={`제목`}
                      name={`title`}
                      placeholder={`제목을 입력하세요.`}
                      validations={[
                        new Validation(/^.+$/, `제목을 입력하세요.`),
                      ]}
                      validOnBlur
                      disabled={!isNew && this.type === 'COMMON' && isAgent}
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup as={Row} className={'mb-3'}>
                <Col md={2}>
                  <div className={Styles.Label}>내용</div>
                </Col>
                <Col md={10}>
                  <InputGroup>
                    <Input
                      store={form}
                      label={`내용`}
                      name={`text`}
                      placeholder={`내용을 입력하세요.`}
                      as={`textarea`}
                      validations={[
                        new Validation(/^.+$/m, `내용을 입력하세요.`),
                      ]}
                      style={{ height: '20rem' }}
                      validOnBlur
                      disabled={!isNew && this.type === 'COMMON' && isAgent}
                    />
                  </InputGroup>
                </Col>
              </FormGroup>
            </Form>
          </div>
        </Panel>
        <ConfirmDialog />
      </div>
    );
  }
}

export default QuickMsgDetailView;
