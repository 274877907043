import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormStore, Input } from '../../components/validation';
import axios from 'axios';
import { noticeDataGridStore } from './NoticeListView';
import { goBack, navigateReplace } from '../../helper/HistoryHelper';
import { NotificationManager } from 'react-notifications';
import TitlePanel from '../../components/layout/TitlePanel';
import { Button, Col, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import Panel from '../../components/layout/Panel';
import {
  ConfirmDialog,
  confirmDialogStore,
  DownloadLink,
  fileDownload,
  USER_TYPE,
} from '@qbot-chat/qbot-uikit';
import { makeObservable, observable } from 'mobx';
import { Warning2 } from 'iconsax-react';
import dayjs from 'dayjs';
import { DatePicker, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ACCESS_RIGHTS } from '../../const/AppConst';
import appStore from '../../store/AppStore';
import Styles from './NoticeView.module.scss';
import moment from 'moment';

const { RangePicker } = DatePicker;
const form = new FormStore();
const dateFormat = 'YYYY-MM-DD';

@observer
class NoticeDetailView extends Component {
  @observable
  isLoginIdUsed = true;

  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    makeObservable(this);
  }

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    if (params && params.id) {
      this.fetchDetail(params.id);
    } else {
      form.clear();
    }
  }

  componentDidUpdate(prevProps) {
    let {
      match: { params },
    } = this.props;
    let prevParams = prevProps.match.params;
    if (params.id != prevParams.id) {
      if (params && params.id) {
        this.fetchDetail(params.id);
      } else if (!params.id) {
        form.clear();
      }
    }
  }
  fetchDetail(id) {
    axios.post(`/notice/detail/${id}`, {}).then((res) => {
      form.values = res.data;
      const resData = Object.keys(res.data);
      const fileData = [];
      if (resData.includes('file1')) {
        fileData.push({
          fileData: res.data.file1,
          fileName: res.data.file1Name,
        });
      }
      if (resData.includes('file2')) {
        fileData.push({
          fileData: res.data.file2,
          fileName: res.data.file2Name,
        });
      }
      this.setState({
        files: [...fileData],
      });
    });
  }

  save() {
    if (!form.doCheckValid()) {
      NotificationManager.warning(form.firstErrorMessage);
      return;
    }
    const id = this.props.match.params.id;
    const fileResult = {
      file1: null,
      file1Name: null,
      file2: null,
      file2Name: null,
    };
    const { files } = this.state;
    files.forEach((v, i) => {
      fileResult[`file${i + 1}Name`] = v?.fileName ?? null;
      fileResult[`file${i + 1}`] = v?.fileData ?? null;
    });
    if (!!id) {
      axios
        .post(`/notice/update`, {
          id,
          ...form.values,
          ...fileResult,
        })
        .then((res) => {
          let id = res.data;
          noticeDataGridStore.fetch();
          this.fetchDetail(id);
          NotificationManager.success('변경사항이 저장되었습니다.');
        });
    } else {
      axios
        .post(`/notice/insert`, { ...form.values, ...fileResult })
        .then(() => {
          // let id = res.data;
          NotificationManager.success('공지사항이 등록되었습니다.');
          noticeDataGridStore.fetch();
          navigateReplace(`/main/notice/list`);
        });
    }
  }

  delete() {
    const id = this.props.match.params.id;
    if (!this.props.match.params.id) return;
    confirmDialogStore.openRemove(<Warning2 />, `삭제 하시겠습니까?`, () => {
      axios
        .post(`/notice/delete`, { id })
        .then(() => {
          NotificationManager.success('해당 공지사항이 삭제되었습니다.');
          navigateReplace(`/main/notice/list`);
        })
        .catch((e) => {
          console.error(e);
        });
    });
  }

  setNoticeDate(dates) {
    dates[0] &&
      form.setValue('startedAt', dayjs(dates[0]).format('YYYY-MM-DD'));
    dates[1] && form.setValue('endedAt', dayjs(dates[1]).format('YYYY-MM-DD'));
  }

  async filesUpload(e) {
    const files = Array.from(e.target.files);
    if (files && files.length > 2) {
      return NotificationManager.error('파일은 두개까지만 업로드 가능합니다.');
    }
    const promises = files.map((file, idx) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          const contents = e.target.result;
          const data = {
            fileName: file.name,
            fileData: contents.split(',')[1],
          };
          resolve(data);
        };
        reader.onerror = (error) => reject(error);
      });
    });
    const encodedFiles = await Promise.all(promises);
    this.setState({
      files: encodedFiles,
    });
  }

  fileDelete(name) {
    this.setState((prev) => {
      return {
        files: prev.files.filter((v) => name !== v.fileName),
      };
    });
  }
  render() {
    const isNew = !this.props.match.params.id;
    const isRead = this.props.match.path?.split('/')[3] === 'read';
    const noticeStartedAt = form.values.startedAt;
    const noticeEndedAt = form.values.endedAt;
    const { files } = this.state;
    const isAdminType = ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type);
    return (
      <div className={Styles.DetailWrapper}>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              {isNew ? (
                <TitlePanelLabel label={'공지사항 추가'} />
              ) : (
                <TitlePanelLabel label={'공지사항 수정'} />
              )}
              <div>
                {isAdminType && !isNew && (
                  <Button
                    variant={'outline-danger'}
                    onClick={() => this.delete()}
                  >
                    삭제
                  </Button>
                )}
                <Button
                  variant={'outline-secondary'}
                  className={'ms-4'}
                  onClick={() => goBack()}
                >
                  취소
                </Button>
                {isAdminType && (
                  <Button
                    variant={'outline-primary'}
                    className={`ms-1`}
                    onClick={() => this.save()}
                  >
                    저장
                  </Button>
                )}
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel className={Styles.Fill}>
          {!isNew && (
            <div className={Styles.UploadInfo}>
              <dl>
                <dt>업로드 날짜</dt>
                <dd>{moment(form.values.updatedAt).format(`LLL`)}</dd>
              </dl>
              <dl>
                <dt>수정자</dt>
                <dd>{form.values.updater}</dd>
              </dl>
            </div>
          )}
          <Form
            onSubmit={(e) => e.preventDefault()}
            className={`${Styles.Fill} gap-3`}
          >
            <FormGroup as={Row}>
              <Col>
                <FormLabel>제목</FormLabel>
                <Input
                  store={form}
                  label={`제목`}
                  name={`title`}
                  placeholder={`제목을 입력해주세요.`}
                  disabled={isRead}
                  validOnBlur
                />
              </Col>
              <Col>
                <FormLabel>공지사항 기간</FormLabel>
                <div className="w-50">
                  <RangePicker
                    placeholder={['시작일', '종료일']}
                    size="large"
                    format={dateFormat}
                    allowEmpty
                    value={[
                      noticeStartedAt && dayjs(noticeStartedAt, dateFormat),
                      noticeEndedAt && dayjs(noticeEndedAt, dateFormat),
                    ]}
                    onChange={(dates) => {
                      this.setNoticeDate(dates);
                    }}
                    style={{ width: '100%' }}
                    disabled={isRead}
                  />
                </div>
              </Col>
            </FormGroup>
            {(files.length > 0 || !isRead) && (
              <FormGroup as={Row}>
                <div className={Styles.UploadWrap}>
                  {!isRead && (
                    <div>
                      <Button
                        variant={`outline-primary`}
                        onClick={() => this.fileInput.click()}
                      >
                        파일 업로드
                      </Button>
                      <Popover
                        placement="rightTop"
                        title={'파일업로드'}
                        content={'최대 2개까지 업로드 가능합니다'}
                        arrow={false}
                      >
                        <QuestionCircleOutlined className="ms-2" />
                      </Popover>
                      <input
                        type="file"
                        multiple
                        ref={(ref) => (this.fileInput = ref)}
                        style={{ display: 'none' }}
                        onChange={(e) => this.filesUpload(e)}
                      />
                    </div>
                  )}
                  {files.length > 0 && (
                    <div className={Styles.FileList}>
                      {files?.map((file, i) => (
                        <DownloadLink
                          key={i}
                          file={file.fileData}
                          name={file.fileName}
                          isDelete={isAdminType}
                          onDelete={() => this.fileDelete(file.fileName)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </FormGroup>
            )}
            <FormGroup className={`${Styles.Textarea} ${Styles.Fill}`}>
              <FormLabel>내용</FormLabel>
              <Input
                store={form}
                as={'textarea'}
                label={`내용`}
                name={`content`}
                placeholder={`이름을 입력해주세요`}
                disabled={isRead}
              />
            </FormGroup>
          </Form>
        </Panel>
        <ConfirmDialog />
      </div>
    );
  }
}

export default NoticeDetailView;
