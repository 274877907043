import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import Frame from '../../components/layout/Frame';
import VoiceRecordListView from './VoiceRecordListView';

@observer
class VoiceRecordFrame extends Component {
  render() {
    return (
      <Frame>
        <Switch>
          <Route path="/main/voice-record" component={VoiceRecordListView} />
        </Switch>
      </Frame>
    );
  }
}

export default VoiceRecordFrame;
