import * as React from 'react';

const SVGComponent = ({
  size = 24,

  color = '',
  ...props
}) => {
  return (
    <svg
      id="TicketPropertyIcon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
        width: size,
        height: 'auto',
        fill: color,
      }}
      xmlSpace="preserve"
      {...props}
    >
      {/*--여기까지는 기본값--*/}

      <g>
        <path d="M22.8,15c0,4.4-1.3,5.8-5.8,5.8H7c-4.4,0-5.8-1.3-5.8-5.8v-0.5c0-0.4,0.3-0.8,0.8-0.8c1,0,1.8-0.8,1.8-1.8 S3,10.2,2,10.2c-0.4,0-0.8-0.3-0.8-0.8V9c0-4.4,1.3-5.8,5.8-5.8h10c4.4,0,5.8,1.3,5.8,5.8 M21.2,9c0-3.6-0.7-4.2-4.2-4.2H7 c-3.5,0-4.2,0.7-4.2,4.1c1.4,0.3,2.5,1.6,2.5,3.2s-1.1,2.8-2.5,3.2c0,3.4,0.7,4.1,4.2,4.1h10c3.3,0,4.1-0.6,4.2-3.6" />
        <g>
          <g>
            <path d="M17.7,9.5H8.2c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h9.5c0.5,0,0.8,0.4,0.8,0.9S18.2,9.5,17.7,9.5z" />
          </g>
          <g>
            <path d="M17.7,12.9H8.2c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h9.5c0.5,0,0.8,0.4,0.8,0.9S18.2,12.9,17.7,12.9z" />
          </g>
          <g>
            <path d="M14.1,16.2H8.2c-0.5,0-0.9-0.4-0.9-0.8s0.4-0.8,0.9-0.8h5.9c0.5,0,0.8,0.4,0.8,0.8S14.6,16.2,14.1,16.2z" />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default SVGComponent;
