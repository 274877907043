import React, {Component} from 'react';
import {observer} from 'mobx-react';
import _ from "lodash";


@observer
class RandomColor extends Component {

    constructor(props) {
        super(props);
    }
    randomColorSet = () =>{
        let seed = this.props.seed || '';

        if(typeof seed === 'string'){

            let result = 0;

            seed.split('').forEach((char) =>
                (result += char.charCodeAt(0))
            );
            const selectedColor = this.colorSet[result % this.colorSet.length];
            return selectedColor;

        }else if(typeof seed === 'number'){
            const selectedColor = this.colorSet[seed % this.colorSet.length];
            return selectedColor;
        }
        return this.colorSet[0];
    }

    colorSet = [
        {bgColor:'#F1848A', avatarColor:'#FBF5E4'},
        {bgColor:'#F8F5E9', avatarColor:'#65B3A9'},
        {bgColor:'#1F3B34', avatarColor:'#FEBC11'},
        {bgColor:'#6F5DA8', avatarColor:'#6FC7B1'},
        {bgColor:'#6DCCDD', avatarColor:'#5855A4'},

        {bgColor:'#FDE5DC', avatarColor:'#C7398A'},
        {bgColor:'#FDE5DC', avatarColor:'#243066'},
        {bgColor:'#D94B49', avatarColor:'#F8F5E9'},
        {bgColor:'#1E767B', avatarColor:'#FFFFFF'},
        {bgColor:'#050709', avatarColor:'#FEBC11'},

        {bgColor:'#FFBDD1', avatarColor:'#192F59'},
        {bgColor:'#D8CED0', avatarColor:'#192F59'},
        {bgColor:'#3B225D', avatarColor:'#ABF5FF'},
        {bgColor:'#D6C6E5', avatarColor:'#51307D'},
        {bgColor:'#FCB814', avatarColor:'#FFFFFF'},

        {bgColor:'#31AB75', avatarColor:'#FFFFFF'},
        {bgColor:'#224292', avatarColor:'#FFFFFF'},
        {bgColor:'#C8C42E', avatarColor:'#1D2A16'},
        {bgColor:'#351721', avatarColor:'#BCAAA2'},
        {bgColor:'#184788', avatarColor:'#F0E91B'},
    ]




    componentDidMount() {
    }

    render() {
        let {children, className, width='100%'}=this.props;
        let {bgColor, avatarColor} = this.randomColorSet();
        return (
            <p style={{width:width, marginBottom:0}} className={`${className ? className : ''}`} {..._.omit(this.props,[`className`])}>
                { children &&
                    React.cloneElement(children,{bgColor, avatarColor})
                }
            </p>
        );
    }
}

export default RandomColor;
