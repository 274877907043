import React from 'react';
import Draggable from 'react-draggable';
import Styles from './DragLayerPopup.module.scss';
import { v4 as uuid } from 'uuid';
import Panel from './Panel';
import { MdUnfoldLess, MdClear } from 'react-icons/md';
import { observer } from 'mobx-react';

@observer
class DragLayerPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFold: false,
    };
  }

  render() {
    let {
      children,
      id = `handle_${uuid()}`,
      title,
      onClose,
      maxHeight = 300,
      maxWidth = 300,
    } = this.props;
    return (
      <Draggable bounds="body" handle={`.${id}`}>
        <Panel
          className={`${Styles.DragLayerPopup}`}
          style={{ maxHeight, maxWidth, padding: 0 }}
        >
          <div className={`${id} ${Styles.Header}`}>
            <span className={Styles.Title}>{title}</span>
            <div className={Styles.ButtonWrap}>
              <span
                className={Styles.Fold}
                onClick={() => {
                  this.setState({ isFold: !this.state.isFold });
                }}
              >
                <MdUnfoldLess size={20} />
              </span>
              <span className={Styles.Close} onClick={() => onClose?.()}>
                <MdClear size={20} />
              </span>
            </div>
          </div>
          <div
            className={`${Styles.Content} ${this.state.isFold ? Styles.Fold : ''}`}
          >
            {children}
          </div>
        </Panel>
      </Draggable>
    );
  }
}

export { DragLayerPopup as default };
