import React, { Component } from 'react';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Container, Modal, Spinner } from 'react-bootstrap';
import Styles from './TransferDialog.module.scss';
import axios from 'axios';

import settingStore from '../../store/SettingStore';
import { TICKET_EVENT_TYPE } from '@qbot-chat/qbot-uikit';

class CloseConfirmDialogStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  show = false;

  @observable
  ticket = undefined;

  @action.bound
  open(ticket) {
    this.show = true;
    this.ticket = ticket;
  }

  @action.bound
  close() {
    this.show = false;
    this.ticket = undefined;
  }
}

const closeConfirmDialogStore = new CloseConfirmDialogStore();

@observer
class CloseConfirmDialog extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  handleSave = async () => {
    const { ticket } = closeConfirmDialogStore;
    try {
      this.isSubmitting = true;
      await axios.post('/custom-message/close/confirm/send', ticket);
      this.handleClose();
    } catch (e) {
      console.log(e);
    } finally {
      this.isSubmitting = false;
    }
  };

  handleClose() {
    closeConfirmDialogStore.close();
  }

  render() {
    let { ticketEventMap } = settingStore;
    let closeConfirm = ticketEventMap.get(
      TICKET_EVENT_TYPE.TICKET_CLOSE_CONFIRM_SEND,
    );
    return (
      <Modal
        show={closeConfirmDialogStore.show}
        onHide={this.handleClose.bind(this)}
        dialogClassName={Styles.TransferDialog}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className={Styles.Header}>상담 종료 메시지 보내기</div>
        </Modal.Header>
        <Modal.Body className={Styles.Body}>
          <Container>
            <div>고객에게 보낼 메시지:</div>
            <div className={Styles.Message}>{closeConfirm?.content?.value}</div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.handleClose.bind(this)}>
            취소
          </Button>
          <Button onClick={this.handleSave.bind(this)} variant="primary">
            {this.isSubmitting ? (
              <Spinner animation="border" variant="secondary" />
            ) : (
              <span>보내기</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export { CloseConfirmDialog as default, closeConfirmDialogStore };
