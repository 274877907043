import React from 'react';
import Styles from './HistoryContent.module.scss';
import { FaArrowRightLong } from 'react-icons/fa6';

const valueConvert = (value, options) => {
  if (!options) return value;

  let valueLabel;
  options.forEach((v) => {
    if (v.value === value) return (valueLabel = v.label);
  });
  return valueLabel;
};

const HistoryContent = ({ historyData, prevData, data, children }) => {
  return (
    <div className={Styles.Content}>
      {children}
      {historyData.flat().map((v) => {
        if (v.hidden) return;
        if (!prevData && !data?.[v.name]) return;
        if (prevData?.[v.name] === data?.[v.name]) return;
        return (
          <dl key={v.name}>
            <dt>{v.label}</dt>
            <dd>
              {!!prevData && (
                <>
                  <p className={Styles.PrevText}>{valueConvert(prevData?.[v.name], v?.options)}</p>
                  <FaArrowRightLong />
                </>
              )}
              <p className={!prevData && Styles.AddText}>{valueConvert(data?.[v.name], v?.options)}</p>
            </dd>
          </dl>
        );
      })}
    </div>
  );
};

export default HistoryContent;
