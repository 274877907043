import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Styles from './SettingManageView.module.scss';
import Panel from '../../components/layout/Panel';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import AutoEditor from './auto/AutoEditor';
import settingStore from '../../store/SettingStore';
import { TICKET_EVENT_TYPE } from '@qbot-chat/qbot-uikit';
import TicketEventEditor from './ticket_event/TicketEventEditor';
import SettingMessageEditor from './ticket_event/SettingMessageEditor';

@observer
class AutoManageView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { ticketEventMap, fetchTicketEvent, setting, isLoadedSetting } =
      settingStore;
    let closeConfirm = ticketEventMap.get(
      TICKET_EVENT_TYPE.TICKET_CLOSE_CONFIRM_SEND,
    );
    let noReact = ticketEventMap.get(
      TICKET_EVENT_TYPE.TICKET_CLOSE_CONFIRM_NO_REACT,
    );
    return (
      <div className={Styles.Tab}>
        <TitlePanel>
          <TitlePanelLabel label={'일반 설정 - 자동화'} />
        </TitlePanel>
        {isLoadedSetting && (
          <Panel className={`h-100 overflow-y-scroll`}>
            <div>
              <AutoEditor
                id={`isTransferableByAgent`}
                title={`상담원의 티켓 이관 허용`}
                description={`상담원이 본인의 티켓을 다른 사람에게 이관할 수 있도록 허용하는옵션.`}
                checked={setting?.isTransferableByAgent}
              />
              <AutoEditor
                id={`isCloseableByAgent`}
                title={`상담원의 티켓 종료 허용`}
                description={`고객의 동의를 구하지 않고도 상담원이 티켓을 종료할 수 있도록 허용하는 옵션입니다.${'\n'}상담원은 옵션 사용 여부와 상관없이 SNS로 접수된 티켓을 종료할 수 있습니다.`}
                checked={setting?.isCloseableByAgent}
              />
              <AutoEditor
                id={`isEvaluationableByCustomer`}
                title={`고객의 상담 평가 허용`}
                description={`고객에게 상담을 평가 할 수 있습니다.`}
                checked={setting?.isEvaluationableByCustomer}
                subEditor={
                  <>
                    <Panel className={`${Styles.PanelBorder} mb-3`}>
                      <SettingMessageEditor
                        id={`chatRatingMessage`}
                        title={`상담 평가 메시지`}
                        description={`고객이 상담 평가하는 화면에서 볼 수 있는 메시지`}
                        message={setting?.chatRatingMessage}
                      />
                    </Panel>
                  </>
                }
              />
              <AutoEditor
                id={`isSendableCloseMessage`}
                title={`상담 종료 확인 메시지 허용`}
                description={`고객에게 상담종료를 할 수 있는 메시지를 보낼 수 있습니다.`}
                checked={setting?.isSendableCloseMessage}
                subEditor={
                  <>
                    <Panel className={`${Styles.PanelBorder} mb-3`}>
                      <TicketEventEditor
                        ticketEvent={closeConfirm}
                        fetchTicketEvent={fetchTicketEvent}
                        isTime={false}
                        title={`상담 종료 확인 메시지`}
                      />
                    </Panel>
                    <AutoEditor
                      id={`isCloseMessageNoReactAutoClose`}
                      title={`상담 종료 확인 메시지 응답 없음 자동 종료 허용`}
                      description={`고객이 상담 종료 확인 메시지 응답이 없을 경우 자동 종료가 가능 합니다`}
                      checked={setting?.isCloseMessageNoReactAutoClose}
                      subEditor={
                        <Panel className={`${Styles.PanelBorder} mb-3`}>
                          <TicketEventEditor
                            ticketEvent={noReact}
                            fetchTicketEvent={fetchTicketEvent}
                            isTime={true}
                            title={`응답 없을 경우 종료 메시지`}
                          />
                        </Panel>
                      }
                    />
                  </>
                }
              />
            </div>
          </Panel>
        )}
      </div>
    );
  }
}

export default AutoManageView;
