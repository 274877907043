import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Styles from './SearchDropDown.module.scss';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as IconUp } from '../../../assets/icon/dropdown_up.svg';
import { ReactComponent as IconDown } from '../../../assets/icon/dropdown_down.svg';

const SearchDropDown = (prop, ref) => {
  const [item, setItem] = useState(prop.item);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (item.type === 'dropdown') {
        if (item.data.length === 0) {
          item.data = await item.getDatasApi(item.lc_code);
        }
        if (item.data.length > 0) {
          item.data = item.data.map((d) => {
            d.toggleName = `${d.name}`;
            return d;
          });
          // 전체 추가
          !item.nonAll &&
            item.data.unshift({ name: '전체', code: '-1', toggleName: '전체' });
          item.selectedToggleName = `${item.data[0].toggleName}`;
          item.selectedCode = item.data[0].code;
          setItem({ ...item, data: item.data });
        }
      }
    })();
  }, []);

  const handleDropClick = (eventKey, e) => {
    // let dataIdx = parseInt(eventKey);
    let itemIdx = parseInt(e.currentTarget.dataset.idx);

    const nItem = item;
    nItem.selectedToggleName = nItem.data[itemIdx].name;
    nItem.selectedCode = nItem.data[itemIdx].code;

    setItem({ ...nItem, data: nItem.data });
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return item;
    },
  }));

  return (
    <div className={`${Styles.search_dropdown}`}>
      <Dropdown
        onSelect={handleDropClick}
        onClick={() => {
          setOpen(!open);
        }}
        onBlur={() => {
          setOpen(false);
        }}
      >
        <Dropdown.Toggle className={`${Styles.search_dropdown_select}`}>
          {
            <div>
              <label>{item.name}</label>
              {item.data.length > 0
                ? `${item.selectedToggleName}`
                : 'Loading...'}
            </div>
          }
          <div
            className={`${Styles.search_dropdown_icon} ${
              open ? Styles.open : ''
            }`}
          >
            {open ? (
              <IconUp style={{ transform: 'rotate(180deg)' }} />
            ) : (
              <IconDown />
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Styles.search_dropdown_menu}`}>
          {item.data.map((p, i) => {
            return (
              <Dropdown.Item
                key={i}
                data-idx={i}
                eventKey={0}
                className={`${Styles.search_dropdown_item}`}
              >{`${p.toggleName}`}</Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default forwardRef(SearchDropDown);
