import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from '../SettingManageView.module.scss';
import DirectPanel from "./DirectPanel";
import TitlePanel from "../../../components/layout/TitlePanel";
import TitlePanelLabel from "../../../components/label/TitlePanelLabel";

@observer
class TicketDirectEventManageView extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className={`${Styles.Tab} h-100`}>

                <div className={'h-100 w-100 d-flex flex-column'}>
                    <TitlePanel>
                        <TitlePanelLabel label={'이벤트 설정 - 즉시 이벤트'} />
                    </TitlePanel>
                    <DirectPanel/>
                </div>
            </div>
        );
    }
}

export default TicketDirectEventManageView;