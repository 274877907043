import React from 'react';
import Styles from './Content.module.scss';
import { Spinner } from 'react-bootstrap';
import CommonHelper from '../../../helper/CommonHelper.js';
import { ReplyMessage } from './ReplyMessage.js';
import { DirectInbox } from 'iconsax-react';
import FileSaver from 'file-saver';

class FileContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      isDownloading: false,
      progress: 0,
    };
  }

  fileRef;

  fileDownload(media) {
    let { store } = this.props;
    this.setState({ isDownloading: true });
    store.rsocketStore
      .requestChatApi(
        `/app/download${media?.url}?fileName=${media?.name}`,
        {},
        {
          headers: {
            Accept: '*/*',
          },
          responseType: 'arraybuffer',
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          onDownloadProgress: (e) => {
            this.setState({
              progress: Math.round((e?.loaded / e?.total) * 100),
            });
          },
        },
      )
      .then((res) => {
        let filename = JSON.stringify(res.headers)
          .split('filename=')[1]
          .split('",')[0];
        let blob = new Blob([res.data], {
          type: 'application/octet-stream',
        });

        FileSaver.saveAs(blob, decodeURI(filename));
      })
      .catch((e) => {
        if (e.response.data && e.response.data.message) {
          alert(e.response.data.message);
        }
      })
      .finally(() => {
        this.setState({ isDownloading: false, progress: 0 });
      });
  }

  render() {
    let { value, store, isBubble, isIncoming, replyMessage } = this.props;
    let { size, name, fullUrl } = value;
    return (
      <div
        className={`${isBubble ? Styles.Bubble : ``} ${isIncoming ? Styles.In : Styles.Out}`}
      >
        {replyMessage && <ReplyMessage store={store} message={replyMessage} />}
        <div className={Styles.FileContent}>
          <a
            href={fullUrl}
            target={'_blank'}
            className={`d-none`}
            download
            ref={(ref) => (this.fileRef = ref)}
          />
          <div
            className={Styles.FileDownloadButton}
            onClick={() => {
              this.fileDownload(value);
            }}
          >
            <div>
              <div className={Styles.FileName}>{name}</div>
              <div className={Styles.FileSize}>
                {CommonHelper.getByteSize(size)}
              </div>
            </div>
            <div className={`d-flex align-items-center`}>
              <div className={Styles.DownloadIcon}>
                {this.state.isDownloading ? (
                  <div
                    style={{ width: 24, height: 24 }}
                    className={`position-relative d-flex justify-content-center align-items-center`}
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                      className={`position-absolute`}
                    />
                    <div style={{ fontSize: 10 }}>{this.state.progress}</div>
                  </div>
                ) : (
                  <DirectInbox size={24} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { FileContent };
