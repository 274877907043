import React from 'react';
import { Button } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import {
  BUTTON_GROUP_TYPE,
  BUTTON_TYPE,
  CONTENT_TYPE,
  USER_TYPE,
} from '../../../const/ChatConst.js';
import moment from 'moment';
import Styles from './Content.module.scss';
import { renderIcon } from '../../../const/IconConst.js';
import { ReplyMessage } from './ReplyMessage.js';

const GroupButton = ({
  type,
  children,
  isFirst,
  isLast,
  idx,
  onClick,
  buttons,
}) => {
  if (type == BUTTON_GROUP_TYPE.GRID) {
    return (
      <div className={`${Styles.Button}`} onClick={onClick}>
        {children}
      </div>
    );
  } else {
    return (
      <Button
        className={`${Styles.Button} ${isFirst && idx == 0 ? Styles.First : ''} ${isLast && idx + 1 == buttons.length ? Styles.Last : ''}`}
        variant={`outline-secondary`}
        onClick={onClick}
      >
        {children}
      </Button>
    );
  }
};

class ButtonGroupContent extends React.Component {
  type = {
    GRID: Styles.Grid,
    NORMAL: Styles.Normal,
  };

  render() {
    let {
      value,
      store,
      isFirst = false,
      isLast = false,
      isBubble,
      replyMessage,
    } = this.props;
    let { buttons } = value;
    let buttonGroupType = value?.type || BUTTON_GROUP_TYPE.NORMAL;
    let user = store?.sender;
    return (
      <div
        className={`${isBubble && buttonGroupType !== BUTTON_GROUP_TYPE.GRID ? Styles.Bubble : ''}`}
      >
        {replyMessage && <ReplyMessage store={store} message={replyMessage} />}
        <div
          className={`${Styles.ButtonGroupContent} ${this.type[buttonGroupType]}`}
        >
          {buttons &&
            buttons.map(({ label, type, value, icon, sessionId }, idx) => (
              <div className={`d-flex flex-column`} key={uuid()}>
                <GroupButton
                  isFirst={isFirst}
                  isLast={isLast}
                  idx={idx}
                  buttons={buttons}
                  type={buttonGroupType}
                  onClick={() => {
                    if (user.type !== USER_TYPE.CUSTOMER) return;
                    if (type == BUTTON_TYPE.INNER_LINK) {
                      store.innerLinkFn && store.innerLinkFn(value);
                    } else if (type == BUTTON_TYPE.OUTER_LINK) {
                      window.location.href = value;
                    } else if (type == BUTTON_TYPE.MESSAGE) {
                      let contentType = CONTENT_TYPE.TEXT;
                      let message = {
                        content: {
                          type: contentType,
                          value,
                        },
                        contentType,
                        createdAt: moment(),
                      };
                      store.onSendMessage(message);
                    } else if (type === BUTTON_TYPE.DIALOG_FLOW) {
                      let contentType = CONTENT_TYPE.DIALOG_FLOW_REQUEST;
                      let message = {
                        content: {
                          type: contentType,
                          sessionId,
                          value: label,
                          event: value,
                        },
                        contentType,
                        createdAt: moment(),
                      };
                      store.onSendMessage(message);
                    } else if (type == BUTTON_TYPE.TEL) {
                      window.location.href = `tel:${value}`;
                    } else if (type == BUTTON_TYPE.TEL_CLOSE) {
                      store?.ticketStore?.close(store.channelId);
                      window.location.href = `tel:${value}`;
                    }
                    // else if (type == BUTTON_TYPE.OPEN_PANEL) {
                    // }
                  }}
                >
                  {icon && renderIcon(icon)}
                  {label}
                </GroupButton>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export { ButtonGroupContent };
