import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Redirect, Route, Switch} from "react-router-dom";
import SettingManageView from "./SettingManageView";

@observer
class SettingManageFrame extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className={`w-100 h-100`}>
                <Switch>
                    <Route path="/main/setting/manage/:key" component={SettingManageView}/>
                    <Redirect exact from="/main/setting" to="/main/setting/manage/general"/>
                </Switch>
            </div>
        );
    }
}

export default SettingManageFrame;
