export const timeFormat = "HH:mm";
export const EXEC = "outbound_exec";
export const CALL = "outbound_call";
export const USEYN = "outbound_operating_useYn";
const MON = "outbound_operating_mon";
const TUE = "outbound_operating_tue";
const WED = "outbound_operating_wed";
const THU = "outbound_operating_thu";
const FRI = "outbound_operating_fri";
const SAT = "outbound_operating_sat";
const SUN = "outbound_operating_sun";
export const TRANSFERYN = "outbound_transferYn";
export const OPERATING_ARR = [MON, TUE, WED, THU, FRI, SAT, SUN];
export const OPERATING_ARR_KOR = ["월", "화", "수", "목", "금", "토", "일"];
