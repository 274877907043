import React from 'react';
import {observer} from "mobx-react";
import {Tab} from 'react-bootstrap';


@observer
class ScrollTabContainer extends React.Component{

    constructor(props) {
        super(props);
    }

    render() {
        let { defaultActiveKey, children, onSelect} = this.props
        return(
            <Tab.Container defaultActiveKey={defaultActiveKey} className={`d-flex flex-column w-100`} onSelect={key=>{onSelect && onSelect(key)}}>
                {React.cloneElement(children, {...children.props, className:'d-flex flex-column flex-grow-1 h-100'})}
            </Tab.Container>
        )
    }
}

ScrollTabContainer.propType = {
}

export {ScrollTabContainer};
