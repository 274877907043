import * as React from 'react';
const DefaultTeamImage = ({ bgColor, avatarColor, ...props }) => (
  <svg
    id="defaultTeamImage"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 90 90"
    style={{
      enableBackground: 'new 0 0 90 90',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      className="bg-color"
      fill={bgColor}
      d="M0,0v90h4.1h16.3h5.5c-1-2.1-1.5-4.8-1.5-8.4c0-8.4,9.4-15.2,21.6-15.2s21.6,6.8,21.6,15.2 c0,3.6-0.5,6.3-1.5,8.4h5.4h12.8H90V0H0z M61.9,17.5c6.6,0,12,5.4,12,12c0,6.6-5.4,12-12,12s-12-5.4-12-12 C49.9,22.9,55.3,17.5,61.9,17.5z M26.5,17.5c6.6,0,12,5.4,12,12c0,6.6-5.4,12-12,12c-6.6,0-12-5.4-12-12 C14.5,22.9,19.9,17.5,26.5,17.5z M33.5,67H7.2c-1-2.1-1.5-4.9-1.5-8.5c0-8.4,9.4-15.2,21.6-15.2c1.9,0,3.8,0.2,5.5,0.5 C31,46.2,30,49.3,30,52.6c0,5.6,3,10.5,7.5,13.1C36.1,66,34.8,66.5,33.5,67z M45.2,64.6c-6.6,0-12-5.4-12-12c0-6.6,5.4-12,12-12 c6.6,0,12,5.4,12,12C57.2,59.2,51.8,64.6,45.2,64.6z M82.8,67H58.5c-1.6-0.7-3.3-1.2-5.1-1.6c4.2-2.7,7.1-7.4,7.1-12.8 c0-3.3-1.1-6.4-2.9-8.9c1.6-0.3,3.4-0.4,5.2-0.4c12.2,0,21.6,6.8,21.6,15.2C84.3,62.1,83.7,64.9,82.8,67z"
    />
    <g>
      <circle
        className="avatar-color"
        fill={avatarColor}
        cx={45.2}
        cy={52.6}
        r={12}
      />
      <path
        className="avatar-color"
        fill={avatarColor}
        d="M46,66.3c-12.2,0-21.6,6.8-21.6,15.2c0,3.6,0.5,6.4,1.5,8.5h40.1c1-2.1,1.5-4.9,1.5-8.5 C67.6,73.1,58.2,66.3,46,66.3z"
      />
      <circle
        className="avatar-color"
        fill={avatarColor}
        cx={61.9}
        cy={29.5}
        r={12}
      />
      <circle
        className="avatar-color"
        fill={avatarColor}
        cx={26.5}
        cy={29.5}
        r={12}
      />
      <path
        className="avatar-color"
        fill={avatarColor}
        d="M62.7,43.2c-1.8,0-3.5,0.2-5.2,0.4c1.8,2.5,2.9,5.6,2.9,8.9c0,5.4-2.8,10.1-7.1,12.8c1.8,0.4,3.5,0.9,5.1,1.6 h24.3c1-2.1,1.5-4.9,1.5-8.5C84.3,50,74.9,43.2,62.7,43.2z"
      />
      <path
        className="avatar-color"
        fill={avatarColor}
        d="M37.5,65.7C33,63,30,58.1,30,52.6c0-3.3,1.1-6.4,2.8-8.8c-1.8-0.3-3.6-0.5-5.5-0.5C15.1,43.2,5.7,50,5.7,58.5 c0,3.6,0.5,6.4,1.5,8.5h26.3C34.8,66.5,36.1,66,37.5,65.7z"
      />
    </g>
  </svg>
);
export default DefaultTeamImage;
