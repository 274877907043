import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { navigate } from '../../helper/HistoryHelper';
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
} from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import Styles from './TicketManager.module.scss';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import Select from 'react-select';
import { Add } from 'iconsax-react';
import { DatePicker } from 'antd';
import { AiOutlineSync, AiOutlineTeam } from 'react-icons/ai';
import { MdOutlineYoutubeSearchedFor, MdSupportAgent } from 'react-icons/md';
import { RiChatOffLine, RiRobot2Line } from 'react-icons/ri';
import dayjs from 'dayjs';
import TeamSelect from '../../components/team/TeamSelect';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import Avatar from '../../components/avatar/Avatar';
import FrameWrap from '../../components/layout/FrameWrap';
const { RangePicker } = DatePicker;
import _ from 'lodash';

const ticketDataGridStore = new DataGridStore({
  fetchUrl: `/ticket/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`createdAt`, Sort.SORT_DIRECTION.DESC),
});

window.ticketDataGridStore = ticketDataGridStore;

const cond = [
  {
    label: '같음',
    value: 'is',
  },
  {
    label: '이상',
    value: 'gt',
  },
  {
    label: '이하',
    value: 'lt',
  },
  {
    label: '범위',
    value: 'between',
  },
];

const statusBadge = {
  PENDING: <Badge bg="info">대기</Badge>,
  ACTIVE: <Badge bg="success">상담중</Badge>,
  IDLE: <Badge bg="warning">유휴</Badge>,
  CLOSED: <Badge bg="secondary">완료</Badge>,
};

const assignmentBadge = {
  NOT_TRANSFERRED: (
    <Badge pill bg="info" style={{ fontSize: 16 }}>
      <RiRobot2Line />
    </Badge>
  ),
  ASSIGN_AGENT: (
    <Badge pill bg="primary" style={{ fontSize: 16 }}>
      <MdSupportAgent />
    </Badge>
  ),
  TRANSFER_AGENT: (
    <Badge pill bg="warning" style={{ fontSize: 16 }}>
      <MdSupportAgent />
      <MdSupportAgent />
    </Badge>
  ),
  TRANSFER_TEAM: (
    <Badge pill bg="warning" style={{ fontSize: 16 }}>
      <AiOutlineTeam />
      <MdSupportAgent />
    </Badge>
  ),
  OFFLINE_TRANSFER: (
    <Badge pill bg="warning" style={{ fontSize: 16 }}>
      <RiChatOffLine />
      <MdSupportAgent />
    </Badge>
  ),
};

class TicketManageListSearchStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  addedTicketSearchProperty = new Map();
  @observable
  startDate = dayjs().subtract(1, 'week');
  @observable
  endDate = dayjs();
  @observable
  status = new Map();
  @observable
  customerName = '';
  @observable
  ticketId = '';
  @observable
  agentName = '';
  @observable
  teamOption = null;

  @computed
  get addedTicketSearchPropertyArray() {
    return Array.from(this.addedTicketSearchProperty.values());
  }

  @computed
  get searchDates() {
    return [this.startDate, this.endDate];
  }

  clear() {
    ticketDataGridStore.searchCondition.clear();
    ticketDataGridStore.page = 1;
    this.addedTicketSearchProperty.clear();
    this.startDate = dayjs().subtract(1, 'week');
    this.endDate = dayjs();
    this.status = new Map();
    this.customerName = '';
    this.agentName = '';
    this.ticketId = '';
    this.teamOption = null;
  }
}

const searchStore = new TicketManageListSearchStore();

@observer
class TicketManageListView extends Component {
  state = {
    timerId: null,
  };
  @observable
  ticketPropertySettings = new Map();
  @observable
  ticketSearchType = null;
  @observable
  selectedProperty = null;
  @observable
  selectedCond = null;
  @observable
  stringValue = '';
  @observable
  startDateValue = null;
  @observable
  endDateValue = null;
  @observable
  minValue = null;
  @observable
  maxValue = null;
  @observable
  booleanValue = false;
  @observable
  enumValue = null;

  ticketPropertySearch = debounce((inputValue, callback) => {
    axios.post(`/ticket/property/setting/list/all`, {}).then((res) => {
      res.data &&
        callback(
          res.data
            .filter(
              (ps) =>
                !Array.from(
                  searchStore.addedTicketSearchProperty.values(),
                ).find((x) => x.fieldKey == ps.fieldKey),
            )
            .map((ps) => ({
              label: ps.name,
              value: { fieldKey: ps.fieldKey, type: ps.type, enums: ps.enums },
            })),
        );
    });
  }, 200);
  @observable
  propertySettings = [];

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get ticketPropertySearchInput() {
    return {
      STRING: (
        <Form.Control
          type="text"
          placeholder="값을 입력해 주세요."
          value={this.stringValue}
          onChange={(e) => (this.stringValue = e.target.value)}
          onKeyDown={(e) => {
            if (e.key.toLowerCase() === 'enter') {
              this.addTicketSearchProperty();
            }
          }}
        />
      ),
      DATE: (
        <RangePicker
          style={{ height: '36px' }}
          size={'large'}
          defaultValue={[this.startDateValue, this.endDateValue]}
          onChange={(dates) => {
            this.startDateValue = dates ? dates[0] : null;
            this.endDateValue = dates ? dates[1] : null;
            this.addTicketSearchProperty();
          }}
        />
      ),
      NUMBER: (
        <>
          {this.selectedCond &&
            (this.selectedCond.value !== 'between' ? (
              <Form.Control
                type="number"
                placeholder="값을 입력해 주세요."
                value={this.minValue}
                onChange={(e) => (this.minValue = e.target.value)}
              />
            ) : (
              <div className={'d-flex'}>
                <Form.Control
                  className={'me-3'}
                  type="number"
                  placeholder="최소 값을 입력해 주세요."
                  value={this.minValue}
                  onChange={(e) => (this.minValue = e.target.value)}
                />
                <Form.Control
                  type="number"
                  placeholder="최대 값을 입력해 주세요."
                  value={this.maxValue}
                  onChange={(e) => (this.maxValue = e.target.value)}
                />
              </div>
            ))}
        </>
      ),
      ENUM: (
        <Select
          isMulti
          options={
            this.selectedProperty.value.enums
              ? this.getEnumsToOptions(this.selectedProperty.value.enums)
              : []
          }
          defaultValue={this.enumValue}
          onChange={(e) => (this.enumValue = e)}
        />
      ),
      BOOLEAN: (
        <div className={'d-flex'}>
          <Form.Check
            className={'me-2'}
            name={'booleanValue'}
            label={'예'}
            id={'true'}
            type={'radio'}
            checked={this.booleanValue}
            onClick={() => (this.booleanValue = true)}
          />
          <Form.Check
            name={'booleanValue'}
            label={'아니오'}
            id={'false'}
            type={'radio'}
            checked={!this.booleanValue}
            onClick={() => (this.booleanValue = false)}
          />
        </div>
      ),
    };
  }

  @computed
  get ticketSearchPanel() {
    return (
      this.ticketSearchType && (
        <Panel className={Styles.SearchSetting}>
          <FormGroup as={Row}>
            <Row className={'d-flex align-items-center'}>
              <Col md={2} className={`ps-4`}>
                <FormLabel className={'fw-bold'}>
                  {
                    {
                      status: '티켓 상태',
                      agent: '상담사',
                      customer: '고객명',
                      property: '티켓 정보',
                      team: '팀',
                      ticketId: '티켓 아이디',
                    }[this.ticketSearchType]
                  }
                </FormLabel>
              </Col>
              <Col md={10} className={`pe-0 d-flex justify-content-between`}>
                <div className={`d-flex align-items-center`}>
                  {
                    {
                      status: (
                        <div className={'d-flex'}>
                          <Form.Check
                            className={'me-3'}
                            type={'checkbox'}
                            id={`ALL`}
                            label={`전체`}
                            checked={searchStore.status.size === 4}
                            onClick={(e) => {
                              if (e.target.checked) {
                                searchStore.status.set('PENDING', '대기');
                                searchStore.status.set('ACTIVE', '상담중');
                                searchStore.status.set('IDLE', '유휴');
                                searchStore.status.set('CLOSED', '완료');
                              } else {
                                searchStore.status.clear();
                              }
                              this.search();
                            }}
                          />
                          <span> | </span>
                          <Form.Check
                            className={'me-3 ms-3'}
                            type={'checkbox'}
                            label={statusBadge['PENDING']}
                            id={`PENDING`}
                            checked={searchStore.status.has('PENDING')}
                            onClick={(e) => {
                              e.target.checked
                                ? searchStore.status.set('PENDING', '대기')
                                : searchStore.status.delete('PENDING');
                              this.search();
                            }}
                          />
                          <Form.Check
                            className={'me-3'}
                            type={'checkbox'}
                            label={statusBadge['ACTIVE']}
                            id={`ACTIVE`}
                            checked={searchStore.status.has('ACTIVE')}
                            onClick={(e) => {
                              e.target.checked
                                ? searchStore.status.set('ACTIVE', '상담중')
                                : searchStore.status.delete('ACTIVE');
                              this.search();
                            }}
                          />
                          <Form.Check
                            className={'me-3'}
                            type={'checkbox'}
                            id={`IDLE`}
                            label={statusBadge['IDLE']}
                            checked={searchStore.status.has('IDLE')}
                            onClick={(e) => {
                              e.target.checked
                                ? searchStore.status.set('IDLE', '유휴')
                                : searchStore.status.delete('IDLE');
                              this.search();
                            }}
                          />
                          <Form.Check
                            className={'me-3'}
                            type={'checkbox'}
                            id={`CLOSED`}
                            label={statusBadge['CLOSED']}
                            checked={searchStore.status.has('CLOSED')}
                            onClick={(e) => {
                              e.target.checked
                                ? searchStore.status.set('CLOSED', '완료')
                                : searchStore.status.delete('CLOSED');
                              this.search();
                            }}
                          />
                        </div>
                      ),
                      agent: (
                        <div className={`me-3`}>
                          <Form.Control
                            type="text"
                            placeholder="전체"
                            value={searchStore.agentName}
                            onBlur={() => this.search()}
                            onChange={(e) =>
                              (searchStore.agentName = e.target.value)
                            }
                            onKeyDown={(e) => {
                              if (e.key.toLowerCase() === 'enter') {
                                this.search();
                              }
                            }}
                          />
                        </div>
                      ),
                      customer: (
                        <div className={`me-3`}>
                          <Form.Control
                            type="text"
                            placeholder="전체"
                            value={searchStore.customerName}
                            onBlur={() => this.search()}
                            onChange={(e) =>
                              (searchStore.customerName = e.target.value)
                            }
                            onKeyDown={(e) => {
                              if (e.key.toLowerCase() === 'enter') {
                                this.search();
                              }
                            }}
                          />
                        </div>
                      ),
                      team: (
                        <div className={`me-3`}>
                          <TeamSelect
                            onSelect={(option) => {
                              searchStore.teamOption = option;
                              this.search();
                            }}
                            value={searchStore.teamOption}
                          />
                        </div>
                      ),
                      ticketId: (
                        <div className={'me-3'}>
                          <Form.Control
                            type="text"
                            placeholder="전체"
                            value={searchStore.ticketId}
                            onBlur={() => this.search()}
                            onChange={(e) =>
                              (searchStore.ticketId = e.target.value)
                            }
                            onKeyDown={(e) => {
                              if (e.key.toLowerCase() === 'enter') {
                                this.search();
                              }
                            }}
                          />
                        </div>
                      ),
                      property: (
                        <>
                          <div className={`me-3`}>
                            <InputGroup
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <AsyncSelect
                                menuPortalTarget={document.body}
                                cacheOptions
                                loadOptions={this.ticketPropertySearch}
                                value={this.selectedProperty}
                                defaultOptions
                                onChange={(e) => (this.selectedProperty = e)}
                              />
                            </InputGroup>
                          </div>
                          {this.selectedProperty &&
                            ['NUMBER'].includes(
                              this.selectedProperty.value.type,
                            ) && (
                              <div className={`me-3`}>
                                <InputGroup
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Select
                                    options={cond}
                                    defaultValue={this.selectedCond}
                                    onChange={(e) => {
                                      this.selectedCond = e;
                                    }}
                                  />
                                </InputGroup>
                              </div>
                            )}
                          {this.selectedProperty && (
                            <div>
                              {
                                this.ticketPropertySearchInput[
                                  this.selectedProperty.value.type
                                ]
                              }
                            </div>
                          )}
                        </>
                      ),
                    }[this.ticketSearchType]
                  }
                </div>
                <div>
                  <Button
                    className={Styles.Button}
                    variant={'outline-primary'}
                    onClick={() => this.addTicketSearchProperty()}
                  >
                    {
                      {
                        status: '검색',
                        agent: '검색',
                        customer: '검색',
                        property: '검색',
                        team: '검색',
                        ticketId: '검색',
                      }[this.ticketSearchType]
                    }
                  </Button>
                  <Button
                    className={Styles.Button}
                    variant={'outline-secondary'}
                    onClick={() => (this.ticketSearchType = null)}
                  >
                    닫기
                  </Button>
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Panel>
      )
    );
  }

  getEnumsToOptions(enums) {
    let { ...arr } = enums;
    return Object.entries(arr).map((value) => {
      return {
        label: value[0],
        value: value[1],
      };
    });
  }

  autoFetch() {
    const timerId = setInterval(this.search, 10000);
    this.setState({ timerId });
  }

  componentDidMount() {
    axios.post(`/ticket/property/setting/list/all`, {}).then(({ data }) => {
      if (data) {
        this.propertySettings.replace(data);
      }
      this.search();
    });
    this.autoFetch();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.pathname.startsWith('/main/ticket/detail') &&
      this.props?.pathname === '/main/ticket/list'
    ) {
      ticketDataGridStore.fetch();
      !this.state.timerId && this.autoFetch();
    }

    if (
      !!this.state.timerId &&
      prevProps?.pathname === '/main/ticket/list' &&
      this.props?.pathname.startsWith('/main/ticket/detail')
    ) {
      clearInterval(this.state.timerId);
      this.setState({ timerId: null });
    }
  }
  componentWillUnmount() {
    ticketDataGridStore.searchCondition.clear();
    searchStore.clear();
    clearInterval(this.state.timerId);
  }

  ticketPropertySearchContent(v) {
    return {
      STRING: `${v.label} : ${v.name}-${
        v.stringValue ? v.stringValue : '빈값'
      }`,
      DATE: `${v.label} : ${v.name}-${dayjs(v.startDateValue).format(
        'YYYY-MM-DD',
      )}-${dayjs(v.endDateValue).format('YYYY-MM-DD')}`,
      ENUM: `${v.label} : ${v.name}-${
        v.enumList && v.enumList.length > 0
          ? v.enumList.reduce((p, v) => p + ' | ' + v.label, '')
          : '빈값'
      }`,
      BOOLEAN: `${v.label} : ${v.name}-${v.booleanValue}`,
      NUMBER: `${v.label} : ${v.name}-${
        v.minValue + (v.maxValue ? `~` + v.maxValue : '')
      }`,
    };
  }

  addTicketSearchProperty = () => {
    if (this.selectedProperty) {
      this.selectedProperty.value['label'] = '티켓 정보';
      switch (this.selectedProperty.value.type) {
        case 'STRING':
          this.selectedProperty.value['searchType'] = this.ticketSearchType;
          this.selectedProperty.value['name'] = this.selectedProperty.label;
          this.selectedProperty.value['stringValue'] = this.stringValue;
          break;
        case 'DATE':
          this.selectedProperty.value['searchType'] = this.ticketSearchType;
          this.selectedProperty.value['name'] = this.selectedProperty.label;
          this.selectedProperty.value['startDateValue'] = this.startDateValue;
          this.selectedProperty.value['endDateValue'] = this.endDateValue;
          break;
        case 'ENUM':
          this.selectedProperty.value['searchType'] = this.ticketSearchType;
          this.selectedProperty.value['name'] = this.selectedProperty.label;
          this.selectedProperty.value['enumList'] = this.enumValue
            ? this.enumValue
            : [];
          this.selectedProperty.value['enumValue'] = this.enumValue
            ? this.enumValue.map(({ value }) => value)
            : [];
          break;
        case 'BOOLEAN':
          this.selectedProperty.value['searchType'] = this.ticketSearchType;
          this.selectedProperty.value['name'] = this.selectedProperty.label;
          this.selectedProperty.value['booleanValue'] = this.booleanValue;
          break;
        case 'NUMBER':
          this.selectedProperty.value['searchType'] = this.ticketSearchType;
          this.selectedProperty.value['name'] = this.selectedProperty.label;
          this.selectedProperty.value['minValue'] = this.minValue;
          this.selectedProperty.value['maxValue'] = this.maxValue;
          this.selectedProperty.value['selectedCond'] = this.selectedCond;
          this.selectedProperty.value['cond'] = this.selectedCond.value;
          break;
      }
      searchStore.addedTicketSearchProperty.set(
        this.selectedProperty.value.fieldKey,
        this.selectedProperty.value,
      );
    }

    this.ticketSearchType = null;
    this.selectedProperty = null;
    this.stringValue = null;
    this.startDateValue = null;
    this.endDateValue = null;
    this.enumValue = null;
    this.booleanValue = false;
    this.minValue = null;
    this.maxValue = null;
    this.selectedCond = null;
    this.search();
  };

  setSearchPanel = (key) => {
    let value = searchStore.addedTicketSearchProperty.get(key);
    this.ticketSearchType = value.searchType;
    this.selectedProperty = {
      label: value.name,
      value: value,
    };
    this.stringValue = value.stringValue;
    this.startDateValue = value.startDateValue;
    this.endDateValue = value.endDateValue;
    this.enumValue = value.enumList;
    this.booleanValue = value.booleanValue;
    this.minValue = value.minValue;
    this.maxValue = value.maxValue;
    this.selectedCond = value.selectedCond;
  };

  @action.bound
  search() {
    let obj = Object.fromEntries(ticketDataGridStore.searchCondition);
    ticketDataGridStore.searchCondition.replace([
      [
        'propertySearches',
        JSON.parse(
          JSON.stringify(
            Array.from(searchStore.addedTicketSearchProperty.values()),
          ),
        ),
      ],
      [
        'startDate',
        dayjs(
          `${searchStore.startDate.$y}-${searchStore.startDate.$M + 1}-${
            searchStore.startDate.$D + 1
          }`,
        ),
      ],
      [
        'endDate',
        dayjs(
          `${searchStore.endDate.$y}-${searchStore.endDate.$M + 1}-${
            searchStore.endDate.$D + 1
          }`,
        ),
      ],
      ['statuses', Array.from(searchStore.status.keys())],
      ['customerName', searchStore.customerName],
      ['agentName', searchStore.agentName],
      ['team', { id: searchStore.teamOption?.value }],
      ['ticketId', searchStore.ticketId],
    ]);
    if (
      !_.isEqual(obj, Object.fromEntries(ticketDataGridStore.searchCondition))
    ) {
      ticketDataGridStore.refresh();
    } else {
      ticketDataGridStore.fetch();
    }
  }

  clear() {
    searchStore.clear();
    this.search();
  }

  render() {
    return (
      <FrameWrap>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'모든티켓 조회'} />
              <Button
                variant="outline-danger"
                className={'d-flex align-items-center me-5'}
                onClick={() => this.clear()}
              >
                <AiOutlineSync className={'me-1'} />
                <span>검색조건 초기화</span>
              </Button>
              <Button
                variant="outline-secondary"
                className={'d-flex align-items-center me-3'}
                onClick={() => this.search()}
              >
                <MdOutlineYoutubeSearchedFor className={'me-1'} />
                <span>다시검색</span>
              </Button>
              {/* <Button
                variant="outline-primary"
                onClick={() => (this.ticketSearchType = 'property')}
              >
                + 검색조건 추가
              </Button> */}
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <div className={Styles.SearchContentWrap}>
            <FormGroup className={`${Styles.SearchContent}`}>
              <div className={`${Styles.SubTitle}`}>조회 조건</div>
              <div>
                <RangePicker
                  className={'me-3 mt-2 mb-2'}
                  placeholder={['상담시작일', '상담종료일']}
                  value={searchStore.searchDates}
                  onChange={(dates) => {
                    searchStore.startDate = dates ? dates[0] : null;
                    searchStore.endDate = dates ? dates[1] : null;
                    this.search();
                  }}
                  disabledDate={(current) =>
                    current && current > dayjs().endOf('day')
                  }
                />
              </div>
              <div>
                <InputGroup className={'me-3 mt-2 mb-2'}>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      this.ticketSearchType = 'status';
                    }}
                  >
                    티켓상태 :{' '}
                    {`${
                      searchStore.status &&
                      searchStore.status.size > 0 &&
                      searchStore.status.size < 4
                        ? Object.entries(
                            Object.fromEntries(searchStore.status),
                          ).reduce((p, value) => p + ' | ' + value[1], '')
                        : '전체'
                    }`}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    style={{ paddingLeft: '.3rem', paddingRight: '.3rem' }}
                    onClick={() => {
                      searchStore.status.clear();
                      this.search();
                    }}
                  >
                    <AiOutlineSync size={'1rem'} />
                  </Button>
                </InputGroup>
              </div>
              <div>
                <InputGroup className={'me-3 mt-2 mb-2'}>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      this.ticketSearchType = 'agent';
                    }}
                  >
                    상담사 :{' '}
                    {searchStore.agentName ? searchStore.agentName : '전체'}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    style={{ paddingLeft: '.3rem', paddingRight: '.3rem' }}
                    onClick={() => {
                      searchStore.agentName = '';
                      this.search();
                    }}
                  >
                    <AiOutlineSync size={'1rem'} />
                  </Button>
                </InputGroup>
              </div>
              <div>
                <InputGroup className={'me-3 mt-2 mb-2'}>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      this.ticketSearchType = 'customer';
                    }}
                  >
                    고객명 :{' '}
                    {searchStore.customerName
                      ? searchStore.customerName
                      : '전체'}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    style={{ paddingLeft: '.3rem', paddingRight: '.3rem' }}
                    onClick={() => {
                      searchStore.customerName = '';
                      this.search();
                    }}
                  >
                    <AiOutlineSync size={'1rem'} />
                  </Button>
                </InputGroup>
              </div>
              <div>
                <InputGroup className={'me-3 mt-2 mb-2'}>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      this.ticketSearchType = 'team';
                    }}
                  >
                    팀 :{' '}
                    {searchStore.teamOption?.label
                      ? searchStore.teamOption?.label
                      : '전체'}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    style={{ paddingLeft: '.3rem', paddingRight: '.3rem' }}
                    onClick={() => {
                      searchStore.teamOption = null;
                      this.search();
                    }}
                  >
                    <AiOutlineSync size={'1rem'} />
                  </Button>
                </InputGroup>
              </div>
              <div>
                <InputGroup className={'me-3 mt-2 mb-2'}>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      this.ticketSearchType = 'ticketId';
                    }}
                  >
                    티켓 아이디 :{' '}
                    {searchStore.ticketId ? searchStore.ticketId : '전체'}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    style={{ paddingLeft: '.3rem', paddingRight: '.3rem' }}
                    onClick={() => {
                      searchStore.ticketId = '';
                      this.search();
                    }}
                  >
                    <AiOutlineSync size={'1rem'} />
                  </Button>
                </InputGroup>
              </div>
              {searchStore.addedTicketSearchPropertyArray.map((v, key) => {
                return (
                  <div key={key}>
                    <InputGroup className={'me-3 mt-2 mb-2'}>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          this.setSearchPanel(v.fieldKey);
                        }}
                      >
                        {this.ticketPropertySearchContent(v)[v.type]}
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        style={{ paddingLeft: '.3rem', paddingRight: '.3rem' }}
                        onClick={() => {
                          searchStore.addedTicketSearchProperty.delete(
                            v.fieldKey,
                          );
                          this.search();
                        }}
                      >
                        <Add size={'1rem'} style={{ rotate: '45deg' }} />
                      </Button>
                    </InputGroup>
                  </div>
                );
              })}
              {this.ticketSearchType && this.ticketSearchPanel}
            </FormGroup>
          </div>
          {/*{
                        this.ticketSearchType && this.ticketSearchPanel
                    }*/}
        </Panel>
        <Panel className={`flex-grow-1`}>
          <DataGrid
            store={ticketDataGridStore}
            keyColumn={`id`}
            columns={[
              ...[
                <Header
                  id={`index`}
                  key={`index`}
                  name={`번호`}
                  width={{ min: 40, current: 50, max: 60 }}
                />,
                <Header
                  id={`id`}
                  key={`id`}
                  name={`ID`}
                  width={{ min: 100, current: 125, max: 285 }}
                  printFunction={(id) => (
                    <div className="d-flex">
                      <Badge pill bg="secondary" className="IdBadge">
                        {id}
                      </Badge>
                    </div>
                  )}
                />,
                <Header
                  id={`status`}
                  key={`status`}
                  name={`상태`}
                  width={{ min: 60, current: 75, max: 90 }}
                  printFunction={(v) => (
                    <div className="d-flex">{statusBadge[v]}</div>
                  )}
                />,

                <Header
                  id="customer.name"
                  key="customer.name"
                  name={`고객`}
                  width={{ min: 100, current: 110, max: 150 }}
                  printFunction={(customerName) => <div>{customerName}</div>}
                />,
                <Header
                  id="transferredType"
                  key="transferredType"
                  name={`배정상태`}
                  printFunction={(desc) => (desc ? assignmentBadge[desc] : '-')}
                />,
                <Header
                  id="assignment"
                  key="assignment"
                  name={`상담사`}
                  width={{ min: 100, current: 140, max: 160 }}
                  printFunction={(assignment) => {
                    let agent = assignment?.agent;
                    return (
                      <div className={'w-100 d-flex flex-row gap-2'}>
                        {agent && (
                          <>
                            <Avatar
                              type={'agent'}
                              seed={agent?.id}
                              width={'1.5rem'}
                              src={agent?.avatar?.fullUrl}
                            />
                            <span>{agent?.name}</span>
                          </>
                        )}
                      </div>
                    );
                  }}
                />,
                <Header
                  id="team"
                  key="team"
                  name={`팀`}
                  width={{ min: 130, current: 150, max: 170 }}
                  printFunction={(data) => (
                    <div className={'w-100 d-flex flex-row gap-2'}>
                      <Avatar
                        type={'team'}
                        seed={data?.id}
                        width={'1.5rem'}
                        src={data?.avatar?.fullUrl}
                      />
                      <span>{data?.name}</span>
                    </div>
                  )}
                />,
                <Header
                  id="createdAt"
                  key="createdAt"
                  sortable={true}
                  name={`상담시작일시`}
                  width={{ min: 100, current: 180, max: 250 }}
                  type="datetime"
                />,
                <Header
                  id={`closedAt`}
                  key={`closedAt`}
                  sortable={true}
                  name={`상담종료일시`}
                  width={{ min: 100, current: 180, max: 250 }}
                  type="datetime"
                />,
              ],
              ...this.propertySettings.map(({ fieldKey, name }) => (
                <Header
                  key={`properties.${fieldKey}`}
                  id={`properties.${fieldKey}`}
                  name={name}
                  initHide
                />
              )),
            ]}
            onRowClick={(rowData) => {
              navigate(`/main/ticket/detail/${rowData.id}`);
            }}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export { TicketManageListView as default, ticketDataGridStore };
