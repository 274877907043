import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { goBack } from '../../helper/HistoryHelper';
import Panel from '../../components/layout/Panel';
import { ConfirmDialog } from '@qbot-chat/qbot-uikit';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import TitlePanel from '../../components/layout/TitlePanel';
import { makeObservable, observable } from 'mobx';
import { Select as ServiceSelect } from 'antd';
import FrameWrap from '../../components/layout/FrameWrap';
import { TfiPlus } from 'react-icons/tfi';
import { FaTrash } from 'react-icons/fa';
import { callDataGridStore, axiosInstancePDS } from './CallListView';
import appStore from '../../store/AppStore';

@observer
class CallAddView extends Component {
  @observable
  NUMBER = 'phoneNumber';

  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      selectScenarioList: [],
      scenarioList: [],
      currentScenarioId: '',
    };
  }

  componentDidMount() {
    axiosInstancePDS
      .post('/call/scenarioList', {
        companyId: appStore.companyId,
      })
      .then((res) => {
        const result = res.data;
        let listArr = [];
        result?.items?.map((data) => {
          let obj = {
            label: `${data.scenarioId} (${data.scenarioName})`,
            value: `${data.scenarioId} (${data.scenarioName})`,
          };
          listArr.push(obj);
        });
        this.setState({ selectScenarioList: [...listArr] });
      })
      .catch((e) => {
        NotificationManager.error('데이터를 불러올 수 없습니다.');
        console.log(e);
      });
  }

  insert(e) {
    if (!e) return;
    let currentData = [...this.state.scenarioList];
    const found = this.state.scenarioList?.find(
      (data) => data.scenarioId === e,
    );
    if (found) {
      this.state.scenarioList.map((data, idx) => {
        if (data.scenarioId === found.scenarioId) {
          currentData[idx][this.NUMBER].push('');
        }
      });
    } else {
      currentData.push({
        scenarioId: e,
        phoneNumber: [''],
      });
    }
    return this.setState({
      scenarioList: [...currentData],
      currentScenarioId: e,
    });
  }

  delete({ id, idIdx, rowIdx }) {
    const currentList = [...this.state.scenarioList];
    if (id) {
      currentList.splice(idIdx, 1);
    } else {
      currentList[idIdx][this.NUMBER].splice(rowIdx, 1);
    }
    return this.setState({
      scenarioList: [...currentList],
    });
  }

  save() {
    const reqData = [];
    this.state.scenarioList.map((data) => {
      const scenarioId = data.scenarioId?.split(' ')[0];
      data[this.NUMBER].map((data) => {
        reqData.push({
          scenarioId: scenarioId,
          phoneNumber: data,
        });
      });
    });
    if (!reqData.length) {
      goBack();
      return NotificationManager.warning('저장된 데이터가 없습니다.');
    }
    axiosInstancePDS
      .post('/call/addNumber', reqData)
      .then(() => {
        callDataGridStore.fetch();
        NotificationManager.success('저장을 완료했습니다.');
      })
      .catch(() => {
        NotificationManager.error('데이터 저장에 실패하였습니다.');
      });
  }

  render() {
    return (
      <FrameWrap className={`d-flex flex-column flex-default overflow-y-auto`}>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'아웃바운드 전화번호 등록하기'} />
              <div>
                <Button variant={'outline-secondary'} onClick={() => goBack()}>
                  취소
                </Button>
                <Button
                  variant={'outline-primary'}
                  className={`ms-4`}
                  onClick={() => this.save()}
                >
                  등록완료
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup as={Row}>
              <Col className="pt-4">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">시나리오 선택</FormLabel>
                    <div className="d-flex align-items-center w-75 gap-2">
                      <ServiceSelect
                        style={{ width: '100%', height: 38 }}
                        size="large"
                        options={this.state.selectScenarioList}
                        placeholder={`시나리오ID (시나리오명) 선택`}
                        onChange={(e) => this.insert(e)}
                      />
                      <Button
                        className="d-flex justify-content-center align-items-center"
                        variant="primary"
                        onClick={() =>
                          this.insert(this.state.currentScenarioId)
                        }
                        style={{ height: 38 }}
                      >
                        <TfiPlus />
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </FormGroup>
            {this.state.scenarioList?.map((id, i) => {
              return (
                <FormGroup as={Row} key={i}>
                  <Col className="pt-5">
                    <div className="mx-4 pb-5 border-bottom">
                      <div className="d-flex justify-content-between align-items-center mx-4">
                        <FormLabel className="fw-bold">
                          {id.scenarioId}
                        </FormLabel>
                        <div className="d-flex align-items-center w-75 gap-2">
                          <Form.Control
                            placeholder="전화번호 입력"
                            onChange={(e) => {
                              let newList = [...this.state.scenarioList];
                              newList[i][this.NUMBER][0] = e.target.value;
                              this.setState({
                                scenarioList: [...newList],
                              });
                            }}
                          />
                          <Button
                            className="d-flex justify-content-center align-items-center"
                            variant="primary"
                            onClick={() =>
                              this.delete({ id: id.scenarioId, idIdx: i })
                            }
                            style={{ height: 38 }}
                          >
                            <FaTrash />
                          </Button>
                        </div>
                      </div>
                      {id.phoneNumber?.map((num, j) => {
                        if (j === 0) {
                          return;
                        } else
                          return (
                            <div
                              className="d-flex justify-content-end mt-2 mx-4"
                              key={j}
                            >
                              <div className="d-flex align-items-center w-75 gap-2">
                                <Form.Control
                                  placeholder="전화번호 입력"
                                  onChange={(e) => {
                                    let newList = [...this.state.scenarioList];
                                    newList[i][this.NUMBER][j] = e.target.value;
                                    this.setState({
                                      scenarioList: [...newList],
                                    });
                                  }}
                                />
                                <Button
                                  className="d-flex justify-content-center align-items-center"
                                  variant="primary"
                                  onClick={() =>
                                    this.delete({ idIdx: i, rowIdx: j })
                                  }
                                  style={{ height: 38 }}
                                >
                                  <FaTrash />
                                </Button>
                              </div>
                            </div>
                          );
                      })}
                    </div>
                  </Col>
                </FormGroup>
              );
            })}
          </Form>
        </Panel>
        <ConfirmDialog />
      </FrameWrap>
    );
  }
}

export default CallAddView;
