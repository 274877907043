import * as React from 'react';
const DefaultChatbotImage = ({ bgColor, avatarColor, props }) => (
  <svg
    id="DefaultChatbotImage"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 90 90"
    style={{
      enableBackground: 'new 0 0 90 90',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <ellipse
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -16.0278 50.6008)"
        className="bg-color"
        fill={bgColor}
        cx={53.1}
        cy={44.6}
        rx={5.6}
        ry={5.6}
      />
      <path
        className="bg-color"
        fill={bgColor}
        d="M0,0v90h4.1h16.3h5.5h40.2h5.4h12.8H90V0H0z M73,65c0,2.9-2.4,5.2-5.2,5.2l-4.9,8.1L58,70.2H22.3 c-2.9,0-5.2-2.4-5.2-5.2V32.5c0-2.9,2.4-5.2,5.2-5.2h19c0.6-3.1,1.9-7.4,4.8-9.6c0.8-0.6,1.6-1,2.5-1.2c0.7-1.8,2.4-3.1,4.5-3.1 c2.7,0,4.8,2.2,4.8,4.8c0,2.7-2.2,4.8-4.8,4.8c-2.1,0-3.9-1.4-4.6-3.3c-0.2,0.1-0.5,0.2-0.7,0.4c-1.9,1.4-3,4.5-3.6,7.1h23.4 c2.9,0,5.2,2.4,5.2,5.2V65z"
      />
      <ellipse
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -22.477 35.0312)"
        className="bg-color"
        fill={bgColor}
        cx={31}
        cy={44.6}
        rx={5.6}
        ry={5.6}
      />
    </g>
    <path
      className="avatar-color"
      fill={avatarColor}
      d="M67.7,27.3H44.3c0.5-2.7,1.6-5.7,3.6-7.1c0.2-0.2,0.4-0.3,0.7-0.4c0.6,1.9,2.4,3.3,4.6,3.3 c2.7,0,4.8-2.2,4.8-4.8c0-2.7-2.2-4.8-4.8-4.8c-2.1,0-3.8,1.3-4.5,3.1c-0.9,0.2-1.7,0.6-2.5,1.2c-3,2.2-4.2,6.4-4.8,9.6h-19 c-2.9,0-5.2,2.4-5.2,5.2V65c0,2.9,2.4,5.2,5.2,5.2H58l4.8,8.1l4.9-8.1c2.9,0,5.2-2.4,5.2-5.2V32.5C73,29.6,70.6,27.3,67.7,27.3z  M31,50.2c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6C36.6,47.7,34.1,50.2,31,50.2z M53.1,50.2 c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6C58.6,47.7,56.1,50.2,53.1,50.2z"
    />
  </svg>
);
export default DefaultChatbotImage;
