import React from 'react';
import {Badge} from 'react-bootstrap';
import Styles from './AgentDropDown.module.scss'
import {observer} from "mobx-react";
import {AVATAR_TYPE} from "../../const/ChatConst.js";
import Avatar from "../chat/avatar/Avatar.js";

const agentStatusBadge = {
    ACTIVE : <Badge bg="success">온라인</Badge>,
    OFFLINE : <Badge bg="danger">오프라인</Badge>,
    AWAY : <Badge bg="warning">자리비움</Badge>,
}
@observer
class AgentProfile extends React.Component{

    render(){
        let { user } = this.props;
        return(
            <div className={`${Styles.AgentProfile} ${user?.status === 'ACTIVE' ? Styles.Active : Styles.InActive}`}>
                <div className={Styles.Avatar}>
                    <Avatar type={AVATAR_TYPE.AGENT} seed={user?.id} src={user?.avatar?.fullUrl}/>
                </div>
                <div className={Styles.Name}>
                    {user?.name || '상담사 선택'}
                </div>
                <div className={'ms-2'}>{agentStatusBadge[user?.status]}</div>
            </div>
        )
    }
}

export {AgentProfile as default}