import React, { Component } from 'react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { navigate } from '../../helper/HistoryHelper';
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import { observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import TitlePanel from '../../components/layout/TitlePanel';
import FrameWrap from '../../components/layout/FrameWrap';
import Avatar from '../../components/avatar/Avatar';
import _ from 'lodash';

const quickMsgDataGridStore = new DataGridStore({
  fetchUrl: `/quickmsg/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC),
});
// const TYPE_NAME = {
//     COMMON: '공통',
//     PERSONAL: '개인'
// }
@observer
class QuickMsgListView extends Component {
  @observable
  keyword = '';

  @observable
  type = 'ALL';

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    quickMsgDataGridStore.refresh();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pathname &&
      prevProps.pathname.startsWith('/main/quickmsg/detail') &&
      this.props.pathname === '/main/quickmsg/list'
    ) {
      quickMsgDataGridStore.fetch();
    }
  }

  componentWillUnmount() {
    quickMsgDataGridStore.searchCondition.clear();
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      quickMsgDataGridStore.page = 1;
      this.search();
    }
  };

  search = () => {
    let obj = Object.fromEntries(quickMsgDataGridStore.searchCondition);
    quickMsgDataGridStore.searchCondition.set('type', this.type);
    quickMsgDataGridStore.searchCondition.set('keyword', this.keyword);

    if (
      !_.isEqual(obj, Object.fromEntries(quickMsgDataGridStore.searchCondition))
    ) {
      quickMsgDataGridStore.refresh();
    } else {
      quickMsgDataGridStore.fetch();
    }
  };

  render() {
    return (
      <FrameWrap>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'빠른답변'} />
              <Button
                variant={'outline-primary'}
                onClick={() => {
                  navigate(`/main/quickmsg/new`);
                }}
              >
                + 빠른답변 추가
              </Button>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Panel style={{ border: '1px solid #E8E8E8', borderRadius: '.5rem' }}>
            <FormGroup>
              <Col className={`d-flex flex-column`}>
                <div className={'d-flex justify-content-between'}>
                  <Col md={3}>
                    <ToggleButtonGroup
                      style={{ width: '100%' }}
                      type="radio"
                      name="options"
                      value={this.type}
                      onChange={(v) => {
                        this.type = v;
                        this.search();
                      }}
                    >
                      <ToggleButton
                        id={'all'}
                        value={'ALL'}
                        variant={'outline-secondary'}
                      >
                        전체
                      </ToggleButton>
                      <ToggleButton
                        id={'common'}
                        value={'COMMON'}
                        variant={'outline-secondary'}
                      >
                        공통
                      </ToggleButton>
                      <ToggleButton
                        id={'personal'}
                        value={'PERSONAL'}
                        variant={'outline-secondary'}
                      >
                        개인
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Col>
                  <Col md={{ span: 8, offset: 1 }}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        제목+내용
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="제목+내용으로 검색"
                        onChange={(e) => {
                          this.keyword = e.target.value;
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                      />
                      <Button
                        variant={'outline-secondary'}
                        onClick={() => this.search()}
                      >
                        검색
                      </Button>
                    </InputGroup>
                  </Col>
                </div>
                <div className={'d-flex flex-row-reverse'}></div>
              </Col>
            </FormGroup>
          </Panel>
        </Panel>
        <Panel className={'flex-default'}>
          <DataGrid
            store={quickMsgDataGridStore}
            keyColumn={`id`}
            columns={[
              <Header
                id={`index`}
                key={`index`}
                name={`번호`}
                width={{ min: 40, current: 50, max: 60 }}
              />,
              <Header
                id={`quickMsgType`}
                key={`quickMsgType`}
                name={`구분`}
                width={{ min: 50, current: 60, max: 80 }}
                printFunction={(type) =>
                  ({
                    COMMON: <Badge bg="primary">공통</Badge>,
                    PERSONAL: <Badge bg="success">개인</Badge>,
                  })[type]
                }
              />,
              <Header
                id={`title`}
                key={`title`}
                name={`제목`}
                width={{ min: 150, current: 200, max: 500 }}
              />,
              <Header
                id={`content`}
                key={`content`}
                name={`답변 내용`}
                width={{ min: 150, current: 200, max: 500 }}
              />,
              <Header
                id={`createdAt`}
                key={`createdAt`}
                sortable={true}
                name={`생성일시`}
                width={{ min: 100, current: 180, max: 250 }}
                type="datetime"
              />,
              <Header
                id={`creator`}
                key={`creator`}
                name={`생성자`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(creator) => creator?.name}
              />,
              <Header
                id={`updatedAt`}
                key={`updatedAt`}
                sortable={true}
                name={`수정일시`}
                width={{ min: 100, current: 180, max: 250 }}
                type="datetime"
              />,
              <Header
                id={`updater`}
                key={`updater`}
                name={`수정자`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(updater) => updater?.name}
              />,
            ]}
            onRowClick={(rowData) => {
              navigate(`/main/quickmsg/detail/${rowData.id}`);
            }}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export { QuickMsgListView as default, quickMsgDataGridStore };
