import React, { Component } from "react";
import { observer } from "mobx-react";
import { Redirect, Route, Switch } from "react-router-dom";
import Frame from "../../components/layout/Frame";
import CallDetailView from "./CallDetailView";
import CallListView from "./CallListView";
import CallAddView from "./CallAddView";

@observer
class CallFrame extends Component {
  render() {
    const { location } = this.props;
    return (
      <Frame>
        <div
          className={`${
            location.pathname === "/main/call/list" ? "d-block" : "d-none"
          } h-100`}
        >
          <CallListView pathname={location.pathname} />
        </div>
        <Switch>
          <Route path="/main/call/new" component={CallAddView} />
          <Route path="/main/call/detail" component={CallDetailView} />
          <Redirect exact from="/main/call" to="/main/call/list" />
        </Switch>
      </Frame>
    );
  }
}

export default CallFrame;
