import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Styles from "./SearchInputDown.module.scss";
import { Dropdown, Form } from "react-bootstrap";
import { ReactComponent as IconUp } from "../../../assets/icon/dropdown_up.svg";
import { ReactComponent as IconDown } from "../../../assets/icon/dropdown_down.svg";

const SearchInputDown = (prop, ref) => {
  const [item, setItem] = useState(prop.item);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (item.type === "inputdown") {
        if (item.data.length > 0) {
          item.data = item.data.map((d) => {
            d.toggleName = `${d.name}`;
            return d;
          });
          item.selectedToggleName = `${item.data[0].toggleName}`;
          item.selectedCode = item.data[0].code;
          setItem({ ...item, data: item.data });
        }
      }
    })();
  }, []);

  const handleInputDropClick = (eventKey, e) => {
    let itemIdx = e?.currentTarget ? parseInt(e.currentTarget.dataset.idx) : 0;

    const nItem = item;
    nItem.fieldName = nItem.data[itemIdx].code;
    nItem.selectedToggleName = nItem.data[itemIdx].name;
    nItem.selectedCode = nItem.data[itemIdx].code;

    setItem({ ...nItem, data: nItem.data });
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      return item;
    },
  }));

  useEffect(() => {
    setItem((prevItem) => ({
      ...prevItem,
      selectedCode: item.data[0].code,
      selectedToggleName: item.data[0].toggleName,
      value: "",
    }));
    setOpen(false);
  }, [prop.reset]);

  return (
    <div
      key={item.fieldName}
      className={Styles.search_inputdown}
      style={prop.width && { width: `${prop.width}px` }}
    >
      <Dropdown
        onSelect={handleInputDropClick}
        onClick={(e) => {
          if (e.target.tagName !== "A") setOpen(!open);
        }}
        onBlur={() => {
          setOpen(false);
        }}
      >
        <Dropdown.Toggle className={Styles.search_inputdown_select}>
          {
            <div>
              {item.data.length > 0
                ? `${item.selectedToggleName}`
                : "Loading..."}
            </div>
          }
          <div
            className={`${Styles.search_dropdown_icon} ${
              open ? Styles.open : ""
            }`}
          >
            {open ? (
              <IconUp style={{ transform: "rotate(180deg)" }} />
            ) : (
              <IconDown />
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${Styles.search_dropdown_menu}`}>
          {item.data?.map((p, i) => {
            return (
              <Dropdown.Item
                key={i}
                data-idx={i}
                eventKey={0}
                className={Styles.search_dropdown_item}
              >{`${p.toggleName}`}</Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      <Form.Control
        style={prop.width && { width: `${prop.width - 90}px` }}
        maxLength={item.maxLength}
        tabIndex={prop.tabIndex}
        value={item.value}
        type="text"
        onChange={(e) => {
          const { value } = e.target;
          const newValue = prop.upper ? value.toUpperCase() : value;
          setItem({ ...item, value: newValue });
        }}
      />
    </div>
  );
};

export default forwardRef(SearchInputDown);
