export const printNewPrimary = (primary) => {
  const primaryArray = generateColorPalette(primary);
  const newPrimaryObj = {
    light: {},
    dark: {},
  };

  primaryArray.forEach((v, i) => {
    const index = i + 1;
    const key = 'primary' + index;
    newPrimaryObj.light[key] = v;
    newPrimaryObj.dark[key] = primaryArray[primaryArray.length - index];
  });

  return newPrimaryObj;
};

export function addColorDataSet(colors) {
  const ID_KEY = 'theme-style';
  const existingStyle = document.getElementById(ID_KEY);
  if (existingStyle) {
    existingStyle.parentNode.removeChild(existingStyle);
  }
  const styleTag = document.createElement('style');
  styleTag.id = ID_KEY;
  const VariableCss = (mode) => {
    return Object.entries(colors[mode])
      .map(([key, color]) => `--qt-${camelToKebab(key)}:${color};`)
      .join('\n');
  };

  const css = `
      body,
      body[data-theme='light'] {
        ${VariableCss('light')}
      }

      body[data-theme='dark'] {
        ${VariableCss('dark')}
      }
  `;
  styleTag.textContent = css;
  document.head.appendChild(styleTag);
}

function generateColorPalette(color) {
  const rgbColor = hexToRgb(color);
  const result = [];
  // 흰색이 섞인 밝은 색조 계산 및 추가
  for (let i = 1; i <= 5; i++) {
    const brightRatio = i * 0.15; // 각 단계마다 0.1씩 증가 (값은 조절 가능)
    const brightened = rgbColor.map((v) => {
      // 최댓값인 255와의 보정값을 계산하여 흰색이 섞인 색상
      return Math.min(255, Math.round(v + (255 - v) * brightRatio));
    });
    result.unshift(rgbToHex(...brightened));
  }
  // 원본 색상 추가
  result.push(color);
  // 검정색이 섞인 어두운 색조 계산 및 추가
  for (let j = 1; j <= 5; j++) {
    const darkRatio = j * 0.15; // 각 단계마다 0.1씩 증가 (값은 조절 가능)
    const darkened = rgbColor.map((v) => {
      // 최솟값인 0과의 보정값을 계산하여 검정색이 섞인 색상
      return Math.max(0, Math.round(v - v * darkRatio));
    });
    result.push(rgbToHex(...darkened));
  }

  return result;
}

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
};
const rgbToHex = (r, g, b) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

function camelToKebab(camelCaseStr) {
  return camelCaseStr.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}
