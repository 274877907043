import React, { useState, forwardRef, useImperativeHandle } from "react";
import Styles from "./SearchInputGroup.module.scss";
import { InputGroup, Form } from "react-bootstrap";

const SearchInputGroup = (prop, ref) => {
  const [item, setItem] = useState(prop.item);

  useImperativeHandle(ref, () => ({
    getData() {
      return item.value;
    },
  }));

  return (
    <InputGroup className={Styles.search_input_box}>
      <InputGroup.Text id="basic-addon1" className={Styles.search_input_label}>
        {item.name}
      </InputGroup.Text>
      <Form.Control
        maxLength={item.maxLength}
        defaultValue={item.value}
        className={Styles.search_input}
        type="text"
        onChange={(e) => {
          setItem({ ...item, value: e.target.value });
        }}
      ></Form.Control>
    </InputGroup>
  );
};

export default forwardRef(SearchInputGroup);
