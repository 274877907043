import { action, makeObservable, observable } from 'mobx';
import { addColorDataSet, printNewPrimary } from '../utils/color';

import palette from '../styles/_palette.scss';
import { decapitalize, isEmptyObj } from '../utils/common';

export const BASE_COLOR = {
  gray1: '',
  gray2: '',
  gray3: '',
  gray4: '',
  gray5: '',
  gray6: '',
  gray7: '',
  gray8: '',
  gray9: '',
  gray10: '',
  gray11: '',
  gray12: '',
  gray13: '',

  coolGray1: '',
  coolGray2: '',
  coolGray3: '',
  coolGray4: '',
  coolGray5: '',
  coolGray6: '',
  coolGray7: '',
  coolGray8: '',
  coolGray9: '',
  coolGray10: '',
  red1: '',
  red2: '',
  red3: '',
  red4: '',
  red5: '',
  red6: '',
  red7: '',
  red8: '',
  red9: '',
  red10: '',
  red11: '',
  red12: '',
  yellow1: '',
  yellow2: '',
  yellow3: '',
  yellow4: '',
  yellow5: '',
  yellow6: '',
  yellow7: '',
  yellow8: '',
  yellow9: '',
  yellow10: '',
  yellow11: '',
  yellow12: '',
  green1: '',
  green2: '',
  green3: '',
  green4: '',
  green5: '',
  green6: '',
  green7: '',
  green8: '',
  green9: '',
  green10: '',
  green11: '',
  green12: '',
  blue1: '',
  blue2: '',
  blue3: '',
  blue4: '',
  blue5: '',
  blue6: '',
  blue7: '',
  blue8: '',
  blue9: '',
  blue10: '',
  blue11: '',
  blue12: '',
  warmGray1: '',
  warmGray2: '',
  warmGray3: '',
  warmGray4: '',
  warmGray5: '',
  warmGray6: '',
  warmGray7: '',
  warmGray8: '',
  warmGray9: '',
  warmGray10: '',
  primary1: '',
  primary2: '',
  primary3: '',
  primary4: '',
  primary5: '',
  primary6: '',
  primary7: '',
  primary8: '',
  primary9: '',
  primary10: '',
  primary11: '',
  chPrimaryInverse: '',
  chPrimary: '',
  chSecondary: '',
  chTertiary: '',
  chDisabledPlaceholder: '',
  chSuccess: '',
  chActive: '',
  chWarning: '',
  chDanger: '',

  bg: '',
  bgHover: '',
  bgFocus: '',
  dividerInverse: '',
  divider: '',
};

const THEME_KEY = 'theme';
class ColorStore {
  constructor() {
    this.theme = JSON.parse(localStorage.getItem(THEME_KEY)) ?? 'light';
    this.colors = BASE_COLOR;
    this.primaryColors = {};
    this.setColors();
    makeObservable(this);
  }
  @observable
  primaryColors;

  @observable
  theme;

  @observable
  colors;

  @action.bound
  setColors() {
    const newColors = BASE_COLOR;
    document.body.dataset.theme = this.theme;
    Object.entries(palette ?? {}).forEach(([key, value]) => {
      if (key.startsWith(this.theme)) {
        const keyText = decapitalize(key.split(this.theme)[1]);
        newColors[keyText] = value;
      }
    });
    this.colors = isEmptyObj(this.primaryColors)
      ? newColors
      : { ...newColors, ...this.primaryColors[this.theme] };
  }

  @action.bound
  setTheme(theme) {
    this.theme = theme;
    localStorage.setItem(THEME_KEY, JSON.stringify(theme));
    this.setColors();
  }

  @action.bound
  setPrimaryColor(primary, primaryObj) {
    const newPrimaryColors = primaryObj ?? printNewPrimary(primary);
    this.primaryColors = newPrimaryColors;
    addColorDataSet(newPrimaryColors);
    this.colors = { ...this.colors, ...newPrimaryColors[this.theme] };
  }
}

const colorStore = new ColorStore();

export default colorStore;
