import React, {Component} from 'react';
import {observer} from "mobx-react";
import {CONTENT_TYPE} from "../../../const/ChatConst.js";
import {TextContent} from "./TextContent.js";
import {ImageContent} from "./ImageContent.js";
import {FileContent} from "./FileContent.js";
import {VideoContent} from "./VideoContent.js";
import {AudioContent} from "./AudioContent.js";
import {CustomContent} from "./CustomContent.js";
import {ButtonGroupContent} from "./ButtonGroupContent.js";
import {MessageLine} from "./MessageLine.js";

@observer
class MessageContent extends Component {

    render() {
        let {content, store, isIncoming=false, isFirst = true, isLast = true, replyMessage, messageKey } = this.props;
        let {type, value, isBubble = true, quickReplies} = content;
        switch (type) {
            case CONTENT_TYPE.TEXT :
                return <TextContent value={value} store={store} isBubble={isBubble} isIncoming={isIncoming} replyMessage={replyMessage}/>;
            case CONTENT_TYPE.IMAGE :
                return <ImageContent value={value} store={store} isFirst={isFirst} isLast={isLast} isBubble={isBubble} isIncoming={isIncoming} replyMessage={replyMessage}/>;
            case CONTENT_TYPE.FILE :
                return <FileContent value={value} store={store} isBubble={isBubble} isIncoming={isIncoming} replyMessage={replyMessage}/>;
            case CONTENT_TYPE.VIDEO :
                return <VideoContent value={value} store={store} isBubble={isBubble} isIncoming={isIncoming} replyMessage={replyMessage}/>;
            case CONTENT_TYPE.AUDIO :
                return <AudioContent value={value} store={store} isBubble={isBubble} isIncoming={isIncoming} replyMessage={replyMessage}/>;
            case CONTENT_TYPE.CUSTOM :
                return <CustomContent value={value} store={store} isBubble={isBubble} isIncoming={isIncoming} replyMessage={replyMessage} quickReplies={quickReplies} messageKey={messageKey}/>;
            case CONTENT_TYPE.DIALOG_FLOW_REQUEST :
                return <TextContent value={value} store={store} isBubble={isBubble} isIncoming={isIncoming} replyMessage={replyMessage}/>;
            case CONTENT_TYPE.DIALOG_FLOW_RESPONSE :
                return <CustomContent value={value} store={store} isBubble={isBubble} isIncoming={isIncoming} replyMessage={replyMessage} quickReplies={quickReplies} messageKey={messageKey}/>;
            case CONTENT_TYPE.BUTTON :
                return <ButtonGroupContent value={value} store={store} isFirst={isFirst} isLast={isLast} isBubble={isBubble} isIncoming={isIncoming} replyMessage={replyMessage}/>;
            case CONTENT_TYPE.LINE :
                return <MessageLine value={value}/>;
            default:
                return <TextContent value={value} store={store} isBubble={isBubble} isIncoming={isIncoming} replyMessage={replyMessage}/>;
        }
    }
}

export default MessageContent;