import React, { useCallback } from 'react';
import { Handle, useReactFlow } from 'reactflow';
import Styles from './NodeFlow.module.scss';
import { Badge } from 'react-bootstrap';
import { SCRIPT_TYPE_ENUM, getDepthNodes } from './FlowOptions';

function CustomNode({
  sourcePosition,
  targetPosition,
  selected,
  data,
  id,
}) {
  const { getEdges, getNodes, setNodes, setEdges } = useReactFlow();
  const deleteNodeById = useCallback(
    (id) => {
      const nodes = getNodes();
      const edges = getEdges();
      const newNodes = nodes
        .filter((node) => node.id !== id)
        ?.map((node) => {
          return {
            ...node,
            data: {
              ...node.data,
              prevIds: node.data.prevIds.filter((v) => v !== id),
              nextIds: node.data.nextIds.filter((v) => v !== id),
            },
          };
        });

      const newEdges = edges.filter(
        (edge) => edge.source !== id && edge.target !== id,
      );
      setNodes(!!newNodes.length ? getDepthNodes(newNodes, newEdges) : []);
      setEdges(newEdges);
    },
    [id],
  );

  return (
    <>
      <div
        className={`${Styles.NodeInner} ${selected ? Styles.Selected : ''} ${data.isNew ? Styles.isNew : ''}`}
      >
        <button onClick={() => deleteNodeById(id)}>×</button>
        <div className={Styles.NodeBody}>
          <Badge
            pill
            bg={SCRIPT_TYPE_ENUM[data.scriptType].colorType}
            className="mb-1"
          >
            {SCRIPT_TYPE_ENUM[data.scriptType].label}
          </Badge>
          <div>{data.content}</div>
        </div>
        {data.scriptType !== 'S' && (
          <Handle type="target" position={targetPosition} />
        )}
        {data.scriptType !== 'E' && (
          <Handle type="source" position={sourcePosition} />
        )}
      </div>
    </>
  );
}

export default CustomNode;
