import * as moment from 'moment';

class CommonHelper {
  static groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  static range(a, b) {
    let result = [];
    for (let i = a; i < b; i++) {
      result.push(i);
    }
    return result;
  }

  static findKeys(m, ...keys) {
    let result = {};
    m.forEach((v, k) => keys.includes(k) && (result[k] = v));
    return result;
  }

  static omit(obj, ...keys) {
    let result = Object.assign({}, obj);
    keys.forEach((key) => delete result[key]);
    return result;
  }

  static clearValue(m) {
    m.keys().forEach((k) => m.set(k, ''));
  }

  static mapToObject(m) {
    let obj = {};
    m.forEach((v, k) => {
      obj[k] = v;
    });
    return obj;
  }

  static copyPropertiesToMap(obj, m) {
    Object.entries(obj).forEach(([k, v]) => m.set(k, v));
  }

  static objectToMap(obj) {
    let m = new Map();
    CommonHelper.copyPropertiesToMap(obj, m);
    return m;
  }

  static urlHostname(data) {
    let a = document.createElement('a');
    a.href = data;
    return a.hostname;
  }

  static yearRange(from, to) {
    let diff = to - from;
    let diffs = CommonHelper.range(0, diff + 1);
    return diffs.map((d) => moment(from, 'YYYY').add(d, 'year').format('YYYY'));
  }

  static arrayMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  static getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  static timeFormat(value) {
    if (value > 3600) {
      let hour = Math.round(value / 3600);
      let minute = Math.round((value % 3600) / 60);
      let second = (value % 3600) % 60;
      return hour + 'h' + minute + 'm' + second + 's';
    } else if (value > 60) {
      let minute = Math.round(value / 60);
      let second = value % 60;
      return minute + 'm' + second + 's';
    } else {
      return value + 's';
    }
  }

  static isIsoDate(str) {
    return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{1,3}Z/.test(str);
  }
}

export default CommonHelper;
