import React from 'react';
import Styles from './Panel.module.scss';
import _ from 'lodash';

class Panel extends React.Component{
    render() {
        let {children, className} = this.props
        return (
            <div className={`${Styles.Panel} ${className ? className : ''}`} {..._.omit(this.props,[`className`])}>
                {children}
            </div>
        )
    }
}

export default Panel;
