import {
  CloseOutlined,
  LeftOutlined,
  LinkOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import S from './NoticeCarousel.module.scss';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { Flex, Skeleton } from 'antd';
import classNames from 'classnames';
import { QtParagraph, QtText } from '../custom/CustomTypography';

const formatMessage = (message) => {
  return message.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const NoticeCarousel = ({
  items,
  title,
  loop = true,
  className,
  onClickItemTitle,
  onClickClose,
  loading,
  ...props
}) => {
  const length = items.length;
  const isSlideActive = length > 1;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    loop,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  if (loading)
    return (
      <Skeleton.Node active className={classNames(S.NoticeCarousel, className)}>
        <LoadingOutlined className={S.LoaddingIcon} />
      </Skeleton.Node>
    );

  if (!length) return;
  return (
    <div className={classNames(S.NoticeCarousel, className)} {...props}>
      <div className={S.Header}>
        <QtText ellipsis className={S.Title} weight={500}>
          {title}
        </QtText>
        <Flex gap={4}>
          {loaded && instanceRef.current && (
            <div className={S.Controller}>
              <LeftOutlined
                className={S.Icon}
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
              />
              <QtText isCaption ellipsis weight={300}>
                {currentSlide + 1}/{length}
              </QtText>
              <RightOutlined
                className={S.Icon}
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
              />
            </div>
          )}
          {!!onClickClose && (
            <CloseOutlined className={S.CloseIcon} onClick={onClickClose} />
          )}
        </Flex>
      </div>
      <ul
        ref={isSlideActive ? sliderRef : null}
        className={classNames('keen-slider', isSlideActive && S.Grab)}
      >
        {items.map((v) => (
          <li className="keen-slider__slide" key={v.id ?? v.updatedAt}>
            <QtParagraph
              isCaption
              ellipsis={{ rows: 2 }}
              onClick={() => onClickItemTitle?.(v)}
              className={classNames(
                S.ItemTitle,
                !!onClickItemTitle && S.LinkActive,
              )}
              weight={500}
            >
              <LinkOutlined className={S.LinkIcon} />
              <span>{v.title}</span>
            </QtParagraph>
            <QtParagraph
              isCaption
              ellipsis={{ rows: 5 }}
              className={classNames(S.Content)}
            >
              {formatMessage(v.content)}
            </QtParagraph>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default NoticeCarousel;
