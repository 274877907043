import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Tab } from 'react-bootstrap';
import Styles from './SettingManageView.module.scss';
import SettingMenuGroup from './SettingMenuGroup';
import SettingMenu from './SettingMenu';
import GeneralManageView from './GeneralManageView';
import TicketTimeEventManageView from './ticket_event/TicketTimeEventManageView';
import settingStore from '../../store/SettingStore';
import TicketDirectEventManageView from './ticket_event/TicketDirectEventManageView';
import AutoManageView from './AutoManageView';
import IconSettingView from '../icon/IconSettingView';
import { Route, Switch } from 'react-router-dom';
import { navigate } from '../../helper/HistoryHelper';
import ChatbotManageFrame from '../chatbot/ChatbotManageFrame';
import WorkTimeManageFrame from './team/WorkTimeManageView';
import ChatBotIcon from '../../components/icon/ChatBotIcon';
import {
  CalendarEdit,
  Play,
  Ranking,
  Refresh,
  Setting,
  TimerStart,
} from 'iconsax-react';
import TicketPropertyIcon from '../../components/icon/TicketPropertyIcon';
import TicketPropertyFrame from '../ticket_property/TicketPropertyFrame';
import RankView from '../rank/RankView';
import { ACCESS_RIGHTS } from '../../const/AppConst';
import BadwordManageView from './badword/BadwordManageView';
import TalkFilterIcon from '../../components/icon/TalkFilterIcon';
import appStore from '../../store/AppStore';

@observer
class SettingManageView extends Component {
  constructor(props) {
    super(props);
    let key = this.props?.match?.params?.key || 'general';
    this.state = { key };
  }

  componentDidMount() {
    let key = this.props?.match?.params?.key || 'general';
    this.setState({ key: key });
    settingStore.fetchTicketEvent();
  }

  componentDidUpdate(prevProps) {
    let key = this.props?.match?.params?.key || 'general';
    let prevKey = prevProps?.match?.params?.key;
    if (key !== prevKey) {
      this.setState({ key: key });
    }
  }

  onKeyChange = (key) => {
    navigate(`/main/setting/manage/${key}`);
  };

  render() {
    let onKeyChange = this.onKeyChange;
    let key = this.state.key;
    return (
      <div className={`d-flex h-100`}>
        <div className={Styles.Menus}>
          <div className={Styles.Title}>설정</div>
          <SettingMenuGroup
            name={`일반 설정`}
            isOpen={['general', 'auto', 'icon'].includes(key)}
          >
            <SettingMenu
              icon={<Setting />}
              name={`일반`}
              id={`general`}
              onKeyChange={onKeyChange}
              activeKey={key}
            />
            <SettingMenu
              icon={<Refresh />}
              name={`자동화`}
              id={`auto`}
              onKeyChange={onKeyChange}
              activeKey={key}
            />
            {/*<SettingMenu icon={<Happyemoji />} name={`아이콘`} id={`icon`} onKeyChange={onKeyChange} activeKey={key}/>*/}
            <SettingMenu
              icon={<Ranking />}
              name={`직급`}
              id={`rank`}
              onKeyChange={onKeyChange}
              activeKey={key}
            />
            <SettingMenu
              icon={<TalkFilterIcon className={'MenuTitle'} />}
              name={`욕설필터`}
              id={`badword`}
              onKeyChange={onKeyChange}
              activeKey={key}
            />
          </SettingMenuGroup>
          <SettingMenuGroup
            name={`운영 시간 설정`}
            isOpen={['work_time'].includes(key)}
          >
            <SettingMenu
              icon={<CalendarEdit />}
              name={`운영 시간`}
              id={`work_time`}
              onKeyChange={onKeyChange}
              activeKey={key}
            />
          </SettingMenuGroup>
          <SettingMenuGroup
            name={`챗봇 설정`}
            isOpen={['chat_bot'].includes(key)}
          >
            <SettingMenu
              icon={<ChatBotIcon className={'MenuTitle'} />}
              name={`챗봇`}
              id={`chat_bot`}
              onKeyChange={onKeyChange}
              activeKey={key}
            />
          </SettingMenuGroup>
          <SettingMenuGroup
            name={`이벤트 설정`}
            isOpen={['time_event', 'direct_event'].includes(key)}
          >
            <SettingMenu
              icon={<TimerStart />}
              name={`타임 이벤트`}
              id={`time_event`}
              onKeyChange={onKeyChange}
              activeKey={key}
            />
            <SettingMenu
              icon={<Play />}
              name={`즉시 이벤트`}
              id={`direct_event`}
              onKeyChange={onKeyChange}
              activeKey={key}
            />
          </SettingMenuGroup>
          <SettingMenuGroup
            name={`티켓 설정`}
            isOpen={['property'].includes(key)}
          >
            <SettingMenu
              icon={<TicketPropertyIcon className={'MenuTitle'} />}
              name={`티켓 필드`}
              id={`property`}
              onKeyChange={onKeyChange}
              activeKey={key}
            />
          </SettingMenuGroup>
        </div>
        <Tab.Container
          defaultActiveKey={`ticket_event`}
          activeKey={this.state.key}
        >
          <Tab.Content className={`flex-default h-100`}>
            <Switch>
              {ACCESS_RIGHTS['ADMIN'].includes(appStore.type) && (
                <>
                  <Route
                    path="/main/setting/manage/general"
                    component={GeneralManageView}
                  />
                  <Route
                    path="/main/setting/manage/auto"
                    component={AutoManageView}
                  />
                  <Route
                    path="/main/setting/manage/icon"
                    component={IconSettingView}
                  />
                  <Route
                    path="/main/setting/manage/time_event"
                    component={TicketTimeEventManageView}
                  />
                  <Route
                    path="/main/setting/manage/direct_event"
                    component={TicketDirectEventManageView}
                  />
                  <Route
                    path="/main/setting/manage/chat_bot"
                    component={ChatbotManageFrame}
                  />
                  <Route
                    path="/main/setting/manage/work_time"
                    component={WorkTimeManageFrame}
                  />
                  <Route
                    path="/main/setting/manage/property"
                    component={TicketPropertyFrame}
                  />
                  <Route
                    path="/main/setting/manage/rank"
                    component={RankView}
                  />
                  <Route
                    path="/main/setting/manage/badword"
                    component={BadwordManageView}
                  />
                </>
              )}
            </Switch>
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }
}

export default SettingManageView;
