import React, { Component } from 'react';
import { action, makeObservable, observable } from 'mobx';
import ModalPortal from './ModalPortal.js';
import styles from './dialog.module.scss';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
class ConfirmDialogStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  show = false;

  @observable
  title = '';

  @observable
  summary = '';

  @observable
  content = '';

  @observable
  onOk;

  @observable
  okLabel = '확인';

  @observable
  okColor = 'success';

  @action.bound
  clear() {
    this.title = '';
    this.summary = '';
    this.content = '';
    this.onOk = undefined;

    this.okLabel = '확인';
    this.okColor = 'outline-success';
  }

  @action.bound
  open({
    title,
    summary,
    onOk,
    content = '',
    okLabel = '확인',
    okColor = 'outline-success',
  }) {
    this.clear();
    this.title = title;
    this.summary = summary;
    this.content = content;
    this.onOk = onOk;

    this.okLabel = okLabel;
    this.okColor = okColor;

    this.show = true;
  }

  @action.bound
  openSave(title, summary, onOk, content = '') {
    this.open({ title, summary, onOk, content, okLabel: '저장' });
  }

  @action.bound
  openAdd(title, summary, onOk, content = '') {
    this.open({ title, summary, onOk, content, okLabel: '추가' });
  }

  @action.bound
  openRemove(title, summary, onOk, content = '', okLabel = '삭제') {
    this.open({
      title,
      summary,
      onOk,
      content,
      okLabel,
      okColor: 'outline-danger',
    });
  }

  @action.bound
  openReset(title, summary, onOk, content = '', okLabel = '초기화') {
    this.open({
      title,
      summary,
      onOk,
      content,
      okLabel,
      okColor: 'outline-danger',
    });
  }
  @action.bound
  openDelegate(title, summary, onOk, content = '', okLabel = '위임') {
    this.open({
      title,
      summary,
      onOk,
      content,
      okLabel,
      okColor: 'outline-danger',
    });
  }

  @action.bound
  close() {
    this.show = false;
  }
}

const confirmDialogStore = new ConfirmDialogStore();

@observer
class ConfirmDialog extends Component {
  render() {
    // const { children } = this.props;
    return (
      <>
        {confirmDialogStore.show && (
          <ModalPortal>
            <div className={styles.Container}>
              <div
                className={styles.Background}
                onClick={() => confirmDialogStore.close()}
              ></div>
              <div className={styles.Modal}>
                <div className={styles.Header}>
                  <div>{confirmDialogStore.title}</div>
                </div>
                <div className={styles.Body}>
                  {confirmDialogStore.summary && (
                    <div key="confirm-summary">
                      {confirmDialogStore.summary}
                    </div>
                  )}
                  {confirmDialogStore.content && (
                    <div
                      className="text-center"
                      key="confirm-content"
                      dangerouslySetInnerHTML={{
                        __html: confirmDialogStore.content,
                      }}
                    ></div>
                  )}
                </div>
                <div className={styles.Footer}>
                  <Button
                    className={styles.Button}
                    style={{ marginRight: '1rem' }}
                    variant={'outline-secondary'}
                    onClick={() => confirmDialogStore.close()}
                  >
                    취소
                  </Button>
                  <Button
                    className={styles.Button}
                    onClick={() => {
                      confirmDialogStore.onOk && confirmDialogStore.onOk();
                      confirmDialogStore.close();
                    }}
                    variant={confirmDialogStore.okColor}
                  >
                    {confirmDialogStore.okLabel}
                  </Button>
                </div>
              </div>
            </div>
          </ModalPortal>
        )}
        <div id={`modal-root`} />
      </>
    );
  }
}

export { ConfirmDialog, confirmDialogStore };
