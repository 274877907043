import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from "./SettingManageView.module.scss";
import {Collapse} from "react-bootstrap";
import {IoIosArrowDown} from "react-icons/io";

@observer
class SettingMenuGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen : false
        }
    }

    componentDidMount() {
        this.setState({isOpen:this.props.isOpen})
    }

    render() {
        let {name, children} = this.props;
        return (
            <div className={Styles.MenuGroup}>
                <div className={Styles.Name} onClick={()=>this.setState({isOpen:!this.state.isOpen})}>
                    {name}
                    <div className={`${Styles.Arrow} ${this.state.isOpen ? Styles.IsOpen : ''}`}>
                        <IoIosArrowDown size={`.8rem`}/>
                    </div>
                </div>
                <Collapse in={this.state.isOpen}>
                    <div>
                        {
                            children
                        }
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default SettingMenuGroup;
