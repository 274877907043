import React from 'react';
import {BookSaved, BoxSearch, Building4, Gift, Home2, MoneyRecive, ReceiptSearch, TicketDiscount} from "iconsax-react";
import EMoney from '../../assets/icon/emoney.svg';
import Delivery from '../../assets/icon/delivery.svg'
import Event from '../../assets/icon/event.svg'
import SsgGo from '../../assets/icon/ssggo.svg'
import Tenent from '../../assets/icon/tenent.svg'
import TraderIntro from '../../assets/icon/traders-introduce.svg'
import {Image} from "react-bootstrap";

export const ICON_TYPE = {
    SEARCH_PRODUCT:`SEARCH_PRODUCT`,
    E_MONEY:`E_MONEY`,
    GIFT_CARD:`GIFT_CARD`,
    GIFT_USED:`GIFT_USED`,
    HOLIDAY:`HOLIDAY`,
    COUPON:`COUPON`,
    REFUND:`REFUND`,
    DELIVERY:`DELIVERY`,
    GIFT_SET:`GIFT_SET`,
    EVENT:`EVENT`,
    CULTURE_CENTER:`CULTURE_CENTER`,
    SSG_GO:`SSG_GO`,
    TENENT:`TENENT`,
    TRADERS_INTRO:`TRADERS_INTRO`,
}

export const renderIcon = (type) => {
    switch (type) {
        case ICON_TYPE.SEARCH_PRODUCT: return <BoxSearch/>;
        case ICON_TYPE.E_MONEY: return <Image src={EMoney}/>;
        case ICON_TYPE.GIFT_CARD: return <TicketDiscount/>;
        case ICON_TYPE.GIFT_USED: return <ReceiptSearch/>;
        case ICON_TYPE.HOLIDAY: return <Building4/>;
        case ICON_TYPE.COUPON: return <BookSaved/>;
        case ICON_TYPE.REFUND: return <MoneyRecive/>;
        case ICON_TYPE.DELIVERY: return <Image src={Delivery}/>;
        case ICON_TYPE.GIFT_SET: return <Gift/>;
        case ICON_TYPE.EVENT: return <Image src={Event}/>;
        case ICON_TYPE.CULTURE_CENTER: return <Home2/>;
        case ICON_TYPE.SSG_GO: return <Image src={SsgGo}/>;
        case ICON_TYPE.TENENT: return <Image src={Tenent}/>;
        case ICON_TYPE.TRADERS_INTRO: return <Image src={TraderIntro}/>;
    }
}