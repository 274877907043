import React from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';
import S from './CustomTypography.module.scss';
const { Text, Title, Paragraph } = Typography;

const withFontWeight =
  (Component) =>
  ({ weight, isCaption, style, className, ...props }) => (
    <Component
      className={classNames(S.Text, isCaption && S.Caption, className)}
      style={{ fontWeight: weight, ...style }}
      {...props}
    />
  );

export const QtText = withFontWeight(Text);
export const QtTitle = withFontWeight(Title);
export const QtParagraph = withFontWeight(Paragraph);
