/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { Form, Row, Col, Dropdown } from 'react-bootstrap';
import CSVDownloader from '../../../utils/CSVDownloader';
import Styles from './DataGridV2.module.scss';
import './datagridv2.css';

const DataGridV2 = (props) => {
  // config 거래가 로드되었는지 확인한다.
  // eslint-disable-next-line no-unused-vars
  const [isLoaded, setLoaded] = useState(false);
  // Datagrid 설정값
  const [config, setConfig] = useState(props.paramConfig);
  // 컬럼명
  const [columnNames, setColumnNames] = useState([{ dataField: '', text: '' }]);
  // 검색 항목 필터 배열
  const [searchFilter, setSearchFilter] = useState(
    props.paramConfig.columnNames,
  );
  // 테이블 데이터 배열
  const [tableItems, setTableItems] = useState(props.paramConfig.datas);
  // 검색 항목 선택 된 아이템 배열
  // const [clickedFilterItems, setFilterItem] = useState([]);
  // 검색 키워드
  // const [keyword, setKeyword] = useState({ val: '' });

  // const [field, setField] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(config.sizePerPage || 20);
  const [sizePerPageList, setSizePerPageList] = useState([
    config.sizePerPage || 20,
    30,
    50,
    100,
  ]);

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  const gridBoxRef = useRef();
  const gridBodyRef = useRef();
  const tableRef = useRef();

  useEffect(() => {
    // scroll 기능하도록 grid 위 box 최초 렌더링 된 크기로 width set
    gridBoxRef.current.style.setProperty(
      'width',
      gridBoxRef.current.clientWidth + 'px',
    );

    // show 컬럼만 체크되게..
    searchFilter.map((item) => {
      if (item.show) {
        item.checked = true;
      } else {
        item.checked = false;
      }
      return item;
    });

    //setColumnNames(searchFilter.filter((item) => item.checked === true));

    let nColumnNames = searchFilter.filter((item) => item.checked === true);
    let alreadySetWidth = 0;
    let aleredySetcount = 0;
    for (let col of nColumnNames) {
      if (col.hasOwnProperty('headerStyle')) {
        if (col.headerStyle.hasOwnProperty('width')) {
          alreadySetWidth += parseInt(
            col.headerStyle.width.replace(/px/gi, ''),
          );
          aleredySetcount++;
        }
        if (!col.checked) {
          aleredySetcount++;
        }
      }
    }

    alreadySetWidth += (nColumnNames.length - aleredySetcount) * 100;

    let setMinWidth = '100px';

    if (gridBoxRef.current.clientWidth > alreadySetWidth) {
      // grid 전체 width 확인 후 min-width 구하기
      setMinWidth =
        (gridBoxRef.current.clientWidth - alreadySetWidth) /
          (nColumnNames.length - aleredySetcount) +
        'px';
    }

    setColumnNames(
      nColumnNames.map((item) => {
        if (!item.hasOwnProperty('headerStyle')) {
          item.headerStyle = { width: setMinWidth };
        }
      }),
    );
  }, []);

  useEffect(() => {
    setTableItems(props.paramConfig.datas);
  }, [props]);

  useEffect(() => {
    if (searchFilter.length === 0) {
      setSearchFilter([...searchFilter, config.columnNames]);
    } else {
      setColumnNames(searchFilter.filter((item) => item.checked === true));
    }
  }, [searchFilter]);

  useEffect(() => {
    if (tableItems?.length === 0) return;
    setLoaded(true);
  }, [tableItems]);

  useEffect(() => {
    // grid height 자동조정
    let setHeight = gridBodyRef.current.clientHeight - 100 + 'px';
    gridBoxRef.current.children[0].style.setProperty('height', setHeight);
  }, [columnNames]);

  /**
   * 테이블 컬럼 toggle 기능
   * @param {*} e check click 대상
   */
  const onFilterSelect = (e) => {
    setSearchFilter(
      searchFilter.map((item) => {
        if (item.dataField === e.target.id) {
          item.checked = item.checked === true ? false : true;
        }
        return item;
      }),
    );
  };

  // const pageListRenderer = ({ pages, onPageChange }) => {
  //   const pageWithoutIndication = pages.filter(
  //     (p) => typeof p.page !== 'string',
  //   );
  //   return (
  //     <div>
  //       {pageWithoutIndication.map((p, i) => (
  //         <button
  //           className="btn btn-success"
  //           onClick={() => onPageChange(p.page)}
  //           key={i}
  //         >
  //           {p.page}
  //         </button>
  //       ))}
  //     </div>
  //   );
  // };

  const sortSizePerPageList = () => {
    const sppl = [...sizePerPageList, config.sizePerPage];
    const newSppl = [...new Set(sppl)].sort((a, b) => a - b);
    setSizePerPageList(newSppl);
  };

  const options = {
    //pageListRenderer,
    custom: true,
    totalSize: tableItems.length,
    page: curPage, // 재조회시 초기화
    sizePerPage: sizePerPage || 20,
    sizePerPageList: [sizePerPage || 20, 30, 50, 100],
  };

  const handleSizePerPage = ({ onSizePerPageChange, page }, newSizePerPage) => {
    onSizePerPageChange(newSizePerPage, page);
    setSizePerPage(newSizePerPage);
    props.handleSizePerPage && props.handleSizePerPage(newSizePerPage);
  };

  const handlePageChange = (page, { onPageChange }) => {
    onPageChange(page);
    setCurPage(page);

    const tableBody = document.querySelector('.react-bootstrap-table');
    if (tableBody) {
      tableBody.scrollTop = 0;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const onPageSelect = (eventKey) => {};

  // eslint-disable-next-line no-unused-vars
  const handleDataChange = (cData) => {};

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true,
  //   clickToExpand: true,
  // };

  // expand 예제
  // const customExpandBox = (row) => {
  //   return (
  //     <div
  //       style={{
  //         backgroundColor: '#eee',
  //         'text-align': 'left',
  //         textWrap: 'wrap',
  //         padding: '10px 10px 0px 10px',
  //       }}
  //     >
  //       <p>{`[질문] ${row.answer}`}</p>
  //       <p>{`[답변] ${row.re_answer}`}</p>
  //     </div>
  //   );
  // };

  // expand 예제
  // const expandRow = {
  //   //mode: 'checkbox',
  //   mode: 'radio',
  //   showExpandColumn: false,
  //   renderer: (row) => customExpandBox(row),
  // };

  useEffect(() => {
    setConfig(props.paramConfig);
  }, [props.paramConfig]);

  useEffect(() => {
    props.handleCurPage && props.handleCurPage(curPage);
  }, [curPage]);

  useEffect(() => {
    sortSizePerPageList();
  }, []);

  return (
    <div ref={gridBodyRef} className={`${Styles.qdatagrid}`}>
      {!config.noFilter && (
        <Row className={`${Styles.qdatagrid_filter}`}>
          <Col>
            <Dropdown
              className={`${Styles.qdatagrid_filter_dropdown} d-inline mx-2`}
              autoClose="outside"
              // onSelect={onFilterSelect}
              role="menuitemcheckbox"
            >
              <Dropdown.Toggle
                className={`${Styles.qdatagrid_filter_btn}`}
                id="dropdown-autoclose-outside"
              >
                컬럼필터
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {searchFilter.map((item, idx) => {
                  if (item.dataField == 'no') {
                    return (
                      <Form.Check // prettier-ignore
                        type="checkbox"
                        id={item.dataField}
                        label={item.text}
                        checked={item.checked}
                        key={idx}
                        readonly
                      />
                    );
                  } else {
                    return (
                      // <Dropdown.Item className={`${Styles.qdatagrid_filter_btn_dropdown_item}`} key={item.dataField} eventKey={idx}>{item.text}</Dropdown.Item>
                      <Form.Check // prettier-ignore
                        className={`${Styles.qdatagrid_filter_btn_dropdown_item}`}
                        type="checkbox"
                        id={item.dataField}
                        label={item.text}
                        checked={item.checked}
                        key={idx}
                        onChange={onFilterSelect}
                      />
                    );
                  }
                })}
              </Dropdown.Menu>
            </Dropdown>
            {config.csv && (
              <CSVDownloader
                data={config.datas}
                date={config.params}
                location={config.pathname}
              />
            )}
          </Col>
          <Col className={`${Styles.qdatagrid_filter_custom}`}>
            {props.children}
          </Col>
        </Row>
      )}

      <Row
        className={config.window ? `${Styles.qdatagrid_row}` : `${Styles.pm_0}`}
      >
        {
          <div
            className={
              config.isHeader
                ? `${Styles.qdatagrid_headerline}`
                : `${Styles.qdatagrid_outline}`
            }
          >
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => (
                <div
                  ref={gridBoxRef}
                  className={
                    !config.isHeader && config.noFilter
                      ? `${Styles.bootstrap_table}`
                      : null
                  }
                >
                  <BootstrapTable
                    ref={tableRef}
                    wrapperClasses="table-responsive"
                    // rowClasses="text-nowrap"
                    hover
                    bordered={false}
                    bootstrap4
                    keyField={config.columnKey}
                    data={tableItems}
                    columns={columnNames}
                    //pagination={paginationFactory()}
                    {...paginationTableProps}
                    rowEvents={{
                      onClick: (e, row, rowIndex) => {
                        // 선택 된 행만 배경색 변경되도록
                        setSelectedRowIndex(rowIndex);

                        // data 변경이 없을 시 page 저장
                        setCurPage(tableRef.current.paginationContext.currPage);

                        // 사용자 onClick 함수 호출
                        config.rowClickEvents.onClick(e, row, rowIndex);
                      },
                      onDoubleClick: (e, row, rowIndex) => {
                        setSelectedRowIndex(rowIndex);
                        setCurPage(tableRef.current.paginationContext.currPage);
                        // 사용자 onDoubleClick 함수 호출
                        config.rowClickEvents.onDoubleClick &&
                          config.rowClickEvents.onDoubleClick(e, row, rowIndex);
                      },
                    }}
                    onDataSizeChange={handleDataChange}
                    rowClasses={(row, rowIndex) => {
                      let classes = null;

                      // 선택 된 행만 배경색 변경되도록
                      if (rowIndex === selectedRowIndex) {
                        classes = 'qtable_select_row';
                      }

                      return classes;
                    }}
                    // rowStyle={(row, rowIndex) => {}} 행 스타일
                    selectRow={
                      config.isCheckbox && {
                        mode: 'checkbox',
                        clickToSelect: true,
                        classes: 'qtable_select_row',
                        onSelect: (e, row, isSelect, rowIndex) => {
                          config.rowClickEvents.onCheckClick(
                            e,
                            row,
                            isSelect,
                            rowIndex,
                          );
                        },
                        onSelectAll: (e, row, isSelect, rowIndex) => {
                          config.rowClickEvents.onSelectAll(
                            e,
                            row,
                            isSelect,
                            rowIndex,
                          );
                        },
                      }
                    }
                    noDataIndication={() => {
                      if (props.fetched) {
                        return <div>조회 된 데이터가 없습니다.</div>;
                      } else {
                        return <div>새로운 검색 조건으로 검색 중입니다...</div>;
                      }
                    }}
                    // expandRow={config.customExpandBox ? {
                    //   mode:'radio',
                    //   showExpandColumn: false,
                    //   onlyOneExpanding: true,
                    //   renderer: row => config.customExpandBox(row)
                    // } : false}
                  />
                  <div className={`${Styles.qtable_page_btn_group} w-100`}>
                    <span>Results per page </span>

                    <Dropdown
                      className={`d-inline mx-2`}
                      // autoClose="outside"
                      onSelect={onPageSelect}
                    >
                      <Dropdown.Toggle
                        className={`${Styles.qdatagrid_page_item_btn}`}
                        id="dropdown-autoclose-outside"
                      >
                        {sizePerPage}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className={`${Styles.qdatagrid_pagination_menu}`}
                      >
                        {sizePerPageList.map((size) => {
                          return (
                            <Dropdown.Item
                              key={size}
                              eventKey={size}
                              onClick={() => {
                                handleSizePerPage(paginationProps, size);
                              }}
                            >
                              {size}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <span>Total : </span>
                    <span>{tableItems.length}</span>
                    <PaginationListStandalone
                      className={`${Styles.qdatagrid_page_right}`}
                      {...paginationProps}
                      onPageChange={(page) => {
                        handlePageChange(page, paginationProps);
                      }}
                    />
                  </div>
                </div>
              )}
            </PaginationProvider>
          </div>
        }
      </Row>
    </div>
  );
};

export default DataGridV2;
