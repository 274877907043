import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Frame from '../../components/layout/Frame';
import ScenarioListView from './ScenarioListView';
import ScenarioDetailView from './ScenarioDetailView';

const ScenarioFrame = () => {
  return (
    <Frame>
      <Switch>
        <Route path="/main/scenario/list" component={ScenarioListView} />
        <Route
          path="/main/scenario/detail/:id"
          component={ScenarioDetailView}
        />
        <Route path="/main/scenario/new" component={ScenarioDetailView} />
        <Redirect exact from="/main/scenario" to="/main/scenario/list" />
      </Switch>
    </Frame>
  );
};

export default ScenarioFrame;
