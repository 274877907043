import React from 'react';
import { observer } from 'mobx-react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { CONTENT_TYPE } from '@qbot-chat/qbot-uikit/chat';

@observer
class LineEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  onChangeItem(content) {
    let { type = CONTENT_TYPE.LINE, idx, onChangeItem } = this.props;
    let newValue = { type, value: content };
    onChangeItem && onChangeItem(newValue, idx);
  }

  render() {
    let { type = CONTENT_TYPE.LINE, value } = this.props;
    let { thickness, colorCode } = value;
    return (
      <div className={`d-flex gap-2`}>
        <div style={{ flex: 1 }}>
          <InputGroup
            size={'sm'}
            className={`justify-content-end align-items-center`}
          >
            <InputGroup.Text>굵기</InputGroup.Text>
            <FormControl
              placeholder={`굵기`}
              value={thickness || ''}
              type={`number`}
              aria-valuemin={1}
              min={1}
              max={10}
              onKeyDown={(e) => {
                e.preventDefault();
                return false;
              }}
              onChange={(e) => {
                this.onChangeItem({ colorCode, thickness: e.target.value });
              }}
            />
          </InputGroup>
        </div>
        <div style={{ flex: 1 }}>
          <InputGroup
            size={'sm'}
            className={`justify-content-end align-items-center`}
          >
            <InputGroup.Text style={{ zIndex: 1 }}>색상</InputGroup.Text>
            <FormControl
              placeholder={``}
              value={colorCode || ''}
              style={{ background: '#fff', zIndex: 1 }}
              readOnly={true}
              onClick={() => {
                this.ref.click();
              }}
            />
            <input
              type={'color'}
              ref={(ref) => (this.ref = ref)}
              className={`position-absolute`}
              style={{ left: 3, bottom: 0, width: 1, height: 1 }}
              onChange={(e) => {
                this.onChangeItem({ colorCode: e.target.value, thickness });
              }}
            />
            <Button
              variant={`light`}
              onClick={() => {
                this.ref.click();
              }}
              style={{ background: colorCode, width: 31 }}
            >
              &nbsp;
            </Button>
          </InputGroup>
        </div>
      </div>
    );
  }
}

export default LineEditor;
