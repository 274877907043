import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {omit} from 'lodash';

@observer
class RadioGroup extends React.Component {

    render() {
        const {name, onChange, value, marginRightRem = 0, className = 'd-flex'} = this.props;
        return (
            <div {...omit(this.props, 'name', 'onChange', 'value', 'marginRightRem')} className={className}>
                {
                    React.Children.map(this.props.children,
                        child => {
                            const {value: childValue} = child.props;

                            return (React.cloneElement(child, {
                                    key    : name,
                                    id     : `${name}-${childValue}`,
                                    name,
                                    onChange,
                                    checked: value === childValue,
                                    style : {marginRight: `${marginRightRem}rem`},
                                    ...child.props
                                })
                            );
                        })
                }
            </div>
        );
    }
}

RadioGroup.propTypes = {
    name          : PropTypes.string,
    onChange      : PropTypes.func,
    value         : PropTypes.any,
    marginRightRem: PropTypes.number,
};

export default RadioGroup;