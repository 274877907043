import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { FiPaperclip } from 'react-icons/fi';
import Style from './DownloadLink.module.scss';
import { fileUrl } from '../../utils/file';

const DownloadLink = ({ file, name, isDelete, onDelete, ...props }) => {
  if (!file || !name) return;
  return (
    <div className={Style.Link} data-delete={isDelete} {...props}>
      <a href={fileUrl(file, name)} download={name}>
        <FiPaperclip />
        <span>{name}</span>
      </a>
      {isDelete && (
        <CloseOutlined className={Style.DeleteIcon} onClick={onDelete} />
      )}
    </div>
  );
};

export default DownloadLink;
