import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ChatStore } from '../../store/ChatStore.js';
import { Dropdown } from 'react-bootstrap';

@observer
class ChannelDropDown extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let { channelStore, chatStore } = this.props;
    let { isOpenDropDown = false, selectedChannelId, x, y } = channelStore;
    return (
      <Dropdown
        show={isOpenDropDown}
        style={{ left: x, top: y }}
        className={`position-absolute`}
        autoClose
        onToggle={(e) => {
          channelStore.isOpenDropDown = e;
        }}
      >
        <Dropdown.Menu show={isOpenDropDown}>
          <Dropdown.Item
            className={``}
            onClick={() => {
              channelStore.leave(selectedChannelId).then(() => {
                if (chatStore) {
                  chatStore.messageMap.replace(
                    chatStore.messages
                      .filter(
                        (message) => message.channelId != selectedChannelId,
                      )
                      .map((message) => [message.id, message]),
                  );
                  if (chatStore.channelId == selectedChannelId)
                    chatStore.channelId = undefined;
                }
              });
            }}
          >
            방나가기
          </Dropdown.Item>
          <Dropdown.Item
            className={``}
            onClick={() => {
              channelStore.close(selectedChannelId).then(() => {
                if (chatStore) {
                  chatStore.messageMap.replace(
                    chatStore.messages
                      .filter(
                        (message) => message.channelId != selectedChannelId,
                      )
                      .map((message) => [message.id, message]),
                  );
                  if (chatStore.channelId == selectedChannelId)
                    chatStore.channelId = undefined;
                }
              });
            }}
          >
            티켓 종료
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

ChannelDropDown.propType = {
  store: PropTypes.instanceOf(ChatStore),
};

export { ChannelDropDown };
