import React, {Component} from 'react';
import Styles from "./TicketManager.module.scss";

class TicketInfoView extends Component {

    brakePoints = [600, 1200];

    masonry;
    constructor(props) {
        super(props);
        this.state = {columns: 1};
    }
    componentDidMount() {
        this.onResize();
        window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    getColumns(w){
        return this.brakePoints.reduceRight( (p, c, i) => {
            return c < w ? p : i;
        }, this.brakePoints.length) + 1;
    }

    onResize = () => {
        const columns = this.getColumns(this.masonry.offsetWidth);
        if(columns !== this.state.columns){
            this.setState({columns: columns});
        }
    }

    mapChildren(){
        let col = [];
        const numC = this.state.columns;
        for(let i = 0; i < numC; i++){
            col.push([]);
        }
        return this.props.children.reduce((p,c,i) => {
            p[i%numC].push(c);
            return p;
        }, col);
    }
    render() {
        return (
            <div className={Styles.InfoContainer} ref={ref => this.masonry = ref}>
                {this.mapChildren().map((col, ci) => {
                    return (
                        <div className={Styles.InfoCard} key={ci} >
                            {col.map((child, i) => {
                                return <div className={'mb-4'} key={i} >{child}</div>
                            })}
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default TicketInfoView;