import React, { Component } from 'react';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit/modal';
import Styles from './IconGrid.module.scss';
import { observer } from 'mobx-react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { MdClear } from 'react-icons/md';
import { Image } from 'react-bootstrap';
import _ from 'lodash';

@observer
class IconGrid extends Component {
  constructor(props) {
    super(props);
  }
  /*items = () => {
        let arr = [];
        for(let i = 0; i < 50; i++){
            arr = arr.concat(this.props.store.dataSet.items)
        }
        return arr;
    }*/

  render() {
    let { store, dataGridStoreRefresh, className } = this.props;
    return (
      <div
        className={`${className ? className : ''}`}
        {..._.omit(this.props, [`className`])}
      >
        <div
          className={'w-100 h-100 d-flex flex-column justify-content-between'}
        >
          <div className={`${Styles.IconList} w-100 overflow-y-auto`}>
            {
              /*store.dataSet.items.map((item) => (*/
              this.props.store.dataSet.items.map((item) => (
                <div className={Styles.Icon} key={item.name}>
                  <div className={Styles.IconWrap}>
                    <div className={Styles.Image}>
                      <Image src={item.image?.fullUrl} />
                    </div>
                  </div>

                  <div className={Styles.IconName}>{item.name}</div>

                  <div
                    className={`${Styles.DeleteIcon}`}
                    onClick={() => {
                      confirmDialogStore.openRemove(
                        '삭제',
                        '삭제 하시겠습니까?',
                        () => {
                          axios
                            .post('/setting/icon/delete', item)
                            .then((res) => {
                              console.log(res);
                              NotificationManager.success(
                                '아이콘이 삭제되었습니다.',
                              );
                              dataGridStoreRefresh();
                            })
                            .catch((e) => console.error(e));
                        },
                      );
                    }}
                  >
                    <MdClear />
                  </div>
                </div>
              ))
            }
          </div>
          <div className={`${Styles.Footer}`}>
            <div>
              총{' '}
              <span style={{ fontWeight: 400, color: '#007DFC' }}>
                {store.dataSet.itemsCount}
              </span>
              건
            </div>
            {/*<div>
                            <Pagination store={store}/>
                        </div>*/}
            <div />
          </div>
        </div>
      </div>
    );
  }
}

export default IconGrid;
