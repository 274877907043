import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { PiFileCsvDuotone } from "react-icons/pi";
import { NotificationManager } from "react-notifications";
import Styles from "./CSVDownloader.module.scss";
import moment from "moment";
import axios from "axios";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: "/",
  responseType: "json",
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
});

function CSVDownloader({ data, date, location }) {
  const [CSVData, setCSVData] = useState(data);

  const menuName =
    location?.match(/\/([^\/]*)$/)[1] === "classification"
      ? "재분류"
      : "표준셋";

  const filename = date?.s_evt_dt
    ? `${menuName}_${date.s_brand_id}_${date.s_evt_dt}_${moment().format(
        "YYMMDD_hhmmss",
      )}`
    : `${menuName}_${moment().format("YYMMDD_hhmmss")}`;

  const checkData = () => {
    if (!CSVData.length) {
      NotificationManager.warning("다운로드할 데이터가 없습니다.");
      return false;
    }
  };

  const reorderedData = CSVData.map(item => ({
    uid: item.uid || null,
    intent_id: item.intent_id || null,
    brand_id: item.brand_id || null,
    intent_group: item.intent_group || null,
    question: item.question || null,
    answer: item.answer || null,
    answer_add: item.answer_add || null,
    use_yn: item.use_yn || null,
    answer_type: item.answer_type || null,
    call_type: item.call_type || null,
    button_label: item.button_label || null,
    url_call: item.url_call || null,
    intent_desc: item.intent_desc || null,
    reg_id: item.reg_id || null,
    fir_reg_at: item.fir_reg_at || null,
    lst_mod_id: item.lst_mod_id || null,
    lst_mod_at: item.lst_mod_at || null,
  }));

  useEffect(() => {
    setCSVData(data);
  }, [data]);

  useEffect(() => {
    if (menuName === "재분류") {
      axiosInstance
        .post("/chatlog/api/v1/reclassification/excelDownload", {
          s_evt_dt: date.s_evt_dt,
          s_brand_id: date.s_brand_id,
        })
        .then(res => {
          setCSVData(res.data);
        });
    }
  }, [menuName, date]);

  return (
    <div className={Styles.csv_div}>
      <CSVLink
        data={menuName === "표준셋" ? reorderedData : CSVData}
        filename={filename}
        onClick={() => checkData()}
      >
        <PiFileCsvDuotone />
      </CSVLink>
    </div>
  );
}

export default CSVDownloader;
