import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import { headerPrint, headerWidthPrint } from '../../util/dataUtils';
import { SCRIPT_TYPE_OPTIONS } from '../../const/options';
import { navigate } from '../../helper/HistoryHelper';
import FrameWrap from '../../components/layout/FrameWrap';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import { DataGrid, DataGridStore, Sort } from '@qbot-chat/qbot-uikit/grid';
import Panel from '../../components/layout/Panel';
import Styles from '../annotation/AnnotationList.module.scss';
import { TbRefresh } from 'react-icons/tb';
import { FormStore } from '../../components/validation';
import { SCRIPT_TYPE_ENUM } from '../../components/flow/FlowOptions';

const intentDataStore = new DataGridStore({
  fetchUrl: `/annotation/standard/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`intent_id`, Sort.SORT_DIRECTION.ASC),
});

const form = new FormStore();

const AnnotationList = () => {
  const [searchKeyword, setSearchKeyword] = useState('');

  const standardIntentData = [
    {
      label: '인텐트 ID',
      name: 'intentId',
      props: {
        maxLength: 5,
        button: null,
      },
      headerProps: {
        sort: 1,
        sortable: true,
        width: headerWidthPrint(50, 100, 200),
        printFunction: (id) => (
          <Badge pill bg="secondary" className="IdBadge">
            {id}
          </Badge>
        ),
      },
    },
    {
      label: '인텐트 그룹',
      name: 'intentGroup',
      headerProps: {
        sort: 2,
        width: headerWidthPrint(100, 120, 200),
      },
    },
    {
      label: '내용',
      name: 'content',
      as: 'textarea',
      headerProps: {
        sort: 3,
        width: headerWidthPrint(200, 500, 600),
      },
    },
    {
      label: '스크립트 유형',
      name: 'scriptType',
      options: SCRIPT_TYPE_OPTIONS,
      headerProps: {
        sort: 4,
        width: headerWidthPrint(80, 120, 200),
        printFunction: (scriptType) => (
          <Badge pill bg={SCRIPT_TYPE_ENUM[scriptType]?.colorType}>
            {SCRIPT_TYPE_ENUM[scriptType]?.label}
          </Badge>
        ),
        className: 'justify-content-center',
      },
    },
    {
      label: '인텐트 설명',
      name: 'intentDesc',
      headerProps: {
        sort: 5,
        width: headerWidthPrint(100, 150, 200),
      },
    },
    // {
    //   label: '오답횟수',
    //   name: 'incorrectCnt',
    //   headerProps: {
    //     sort: 6,
    //     width: headerWidthPrint(50, 80, 100),
    //   },
    // },
  ];

  useEffect(() => {
    intentDataStore.refresh();
    return () => {
      intentDataStore.searchCondition.clear();
      form.clear();
    };
  }, []);

  const onSearch = (e, keyword) => {
    e?.preventDefault();
    intentDataStore.searchCondition.set('keyword', keyword ?? '');
    intentDataStore.fetch();
  };

  const onSearchReset = () => {
    setSearchKeyword('');
    onSearch();
  };

  return (
    <FrameWrap>
      <TitlePanel>
        <FormGroup>
          <Col className="justify-content-between align-items-center d-flex">
            <TitlePanelLabel label="어노테이션 관리" />
          </Col>
        </FormGroup>
      </TitlePanel>
      <Panel className="flex-default d-flex gap-4">
        <div className={Styles.DataGridWrapper}>
          <Panel style={{ border: '1px solid #E8E8E8', borderRadius: '.5rem' }}>
            <Form
              onSubmit={(e) => onSearch(e, searchKeyword)}
              className={Styles.SearchForm}
            >
              <FormGroup className={Styles.SearchContent}>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">
                    인텐트 ID, 인텐트 그룹, 내용
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="인텐트 ID, 인텐트 그룹, 내용으로 검색"
                    value={searchKeyword ?? ''}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchKeyword(e.target.value);
                    }}
                  />
                </InputGroup>
                <Button variant="outline-secondary" onClick={onSearchReset}>
                  <TbRefresh />
                </Button>
                <Button variant="outline-secondary" type="submit">
                  검색
                </Button>
              </FormGroup>
            </Form>
          </Panel>
          <DataGrid
            store={intentDataStore}
            keyColumn="intentId"
            columns={headerPrint(standardIntentData)}
            onRowClick={(rowData) => {
              navigate(
                `/main/annotation/detail?intentId=${rowData.intentId}&scriptType=${rowData.scriptType}&standardSetType=${rowData.standardSetType}`,
              );
            }}
          />
        </div>
      </Panel>
    </FrameWrap>
  );
};

export { AnnotationList as default, intentDataStore };
