import React from 'react';
import S from './Profile.module.scss';
import { DownOutlined } from '@ant-design/icons';
import { AGENT_STATUS } from '../../../const/AppConst';
import appStore from '../../../store/AppStore';
import DropMenu from './NavDropMenu';
import axios from 'axios';
import { ticketStore } from '../../../store/AdminChatStore';
import { observer } from 'mobx-react';
import ProfileImg from '../../avatar/ProfileImg';
import { QtText } from '../../custom/CustomTypography';

const Profile = observer(({ isAgent, collapsed }) => {
  const statusItems =
    isAgent &&
    Object.entries(AGENT_STATUS).map(([status, value]) => ({
      key: status,
      label: value.label,
      isActive: appStore.status === status,
      onClick: () => {
        axios
          .post('/agent/status/update', {
            id: appStore.id,
            status,
          })
          .then(() => {
            appStore.status = status;
            if (status === 'ACTIVE') {
              ticketStore.initAssignment();
            } else if (status === 'OFFLINE') {
              ticketStore.allTransfer();
            }
          });
      },
    }));
  return (
    <DropMenu
      collapsed={collapsed}
      placement={!collapsed ? 'bottom' : 'rightTop'}
      items={statusItems}
    >
      <div className={S.Profile}>
        <div className={S.Content}>
          <ProfileImg
            size={30}
            src={appStore.avatar}
            seed={appStore.id}
            status={appStore.status}
            isDot={isAgent}
          />
          <p className={S.ProfileInfo}>
            <QtText ellipsis weight={500}>
              {appStore?.name}
            </QtText>
            {appStore.team && (
              <QtText isCaption ellipsis>
                {appStore.team.name}
              </QtText>
            )}
          </p>
        </div>
        {isAgent && !collapsed && <DownOutlined className={S.Icon} />}
      </div>
    </DropMenu>
  );
});

export default Profile;
