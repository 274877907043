import React from 'react';
import { Image } from 'react-bootstrap';
import Styles from './TeamDropDown.module.scss';
import { observer } from 'mobx-react';
import { AVATAR_TYPE } from '../../const/ChatConst.js';
import Avatar from '../chat/avatar/Avatar.js';

@observer
class TeamProfile extends React.Component {
  render() {
    let { team } = this.props;
    let url = team?.avatar?.fullUrl;
    return (
      <div className={Styles.TeamProfile}>
        <div className={Styles.Avatar}>
          {url ? (
            <Image src={url} />
          ) : (
            <Avatar type={AVATAR_TYPE.TEAM} seed={'111111111111111'} src={''} />
          )}
        </div>
        <div className={Styles.Name}>{team?.name || '팀 선택'}</div>
      </div>
    );
  }
}

export { TeamProfile as default };
