export const USE_YN_OPTIONS = [
  { value: 'Y', label: 'Y' },
  { value: 'N', label: 'N' },
];

export const SCRIPT_TYPE_OPTIONS = [
  { value: 'Q', label: '질문' },
  { value: 'A', label: '답변' },
  { value: 'S', label: '시작' },
  { value: 'E', label: '종료' },
];

export const CALL_TYPE_OPTIONS = [
  { value: 'single', label: 'Single' },
  { value: 'divide', label: 'Divide' },
];

export const ANSWER_TYPE_OPTIONS = [
  { value: 'guide', label: '안내' },
  { value: 'connect', label: '연결' },
];
