import React from 'react';
import Styles from './ReplyMessage.module.scss';
import { observer } from 'mobx-react';
import ContractionMessage from './ContractionMessage.js';

@observer
class ReplyMessage extends React.Component {
  render() {
    let { message, store, isIncoming } = this.props;
    return (
      <>
        <div
          className={`${Styles.Reply} ${isIncoming ? Styles.In : Styles.Out}`}
          onClick={() => {
            store.scrollToReplyMessage(message);
          }}
        >
          <span className={Styles.Name}>
            {message?.sender?.name} 님에게 답장
          </span>
          <span className={Styles.Content}>
            <ContractionMessage message={message} />
          </span>
        </div>
        <hr className={Styles.Line} />
      </>
    );
  }
}

export { ReplyMessage };
