import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormStore, Input } from '../../components/validation';
import axios from 'axios';
import { goBack } from '../../helper/HistoryHelper';
import { NotificationManager } from 'react-notifications';
import TitlePanel from '../../components/layout/TitlePanel';
import { Button, Card, Col, FormGroup, InputGroup } from 'react-bootstrap';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import Panel from '../../components/layout/Panel';
import SelectBox from '../../components/input/SelectBox';
import { DataGrid, DataGridStore, Sort } from '@qbot-chat/qbot-uikit/grid';
import Styles from './AnnotationList.module.scss';
import FrameWrap from '../../components/layout/FrameWrap';
import { headerPrint, headerWidthPrint } from '../../util/dataUtils';
import { observer } from 'mobx-react';

const form = new FormStore();
const annotationStore = new DataGridStore({
  fetchUrl: `/annotation/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`intent_id`, Sort.SORT_DIRECTION.ASC),
});

const AnnotationDetailView = observer(() => {
  const location = useLocation();

  const [intentList, setIntentList] = useState([]);
  const [detailInfo, setDetailInfo] = useState({ question: '', answer: '' });
  const [intentSelected, setIntentSelected] = useState(false);
  const [annoSelected, setAnnoSelected] = useState(false);

  const intentId = useRef('');
  const scriptType = useRef('');
  const standardSetType = useRef('');
  const annotationUid = useRef('');

  const annotationData = [
    {
      label: 'UID',
      name: 'uid',
      headerProps: {
        sort: 1,
        width: headerWidthPrint(100, 120, 200),
      },
    },
    {
      label: '내용',
      name: 'content',
      as: 'textarea',
      headerProps: {
        sort: 2,
        width: headerWidthPrint(200, 300, 600),
      },
    },
    {
      label: '생성자',
      name: 'regId',
      headerProps: {
        sort: 3,
        width: headerWidthPrint(50, 150, 200),
      },
    },
    {
      label: '생성일시',
      name: 'regDate',
      headerProps: {
        sort: 4,
        type: 'datetime',
        width: headerWidthPrint(50, 180, 200),
      },
    },
  ];

  // 인텐트 ID에 따른 인텐트 목록 조회
  const fetchIntentList = () => {
    const data = {
      intentId: intentId?.current,
      scriptType: scriptType?.current,
    };
    axios
      .post(`/annotation/intent/list`, data)
      .then((res) => {
        const resIntentList =
          res?.data?.map((data) => ({
            value: data.intentId,
            label: `[${data.intentId}] ${data.intentGroup} ${data.intentDesc}`,
          })) || [];
        setIntentList(resIntentList);
      })
      .catch((error) => {
        console.error('fetchIntentList ::', error);
      });
  };

  // 인텐트 상세 조회
  const fetchAnnotationDetail = () => {
    const data = {
      standardSetType: standardSetType?.current,
      questionIntentId: form.values?.questionIntentId,
      answerIntentId: form.values?.answerIntentId,
    };
    axios
      .post(`/annotation/detail`, data)
      .then((res) => {
        setDetailInfo({ ...res?.data });
      })
      .catch((error) => {
        console.error('fetchAnnotationDetail ::', error);
      });
  };

  // 인텐트에 해당하는 학습데이터 조회
  const fetchAnnotationList = () => {
    annotationStore.searchCondition.set(
      'questionIntentId',
      form.values?.questionIntentId,
    );
    annotationStore.searchCondition.set(
      'answerIntentId',
      form.values?.answerIntentId,
    );
    annotationStore.searchCondition.set('scriptType', scriptType?.current);
    annotationStore.searchCondition.set(
      'standardSetType',
      standardSetType?.current,
    );

    annotationStore.refresh();
  };

  // 인텐트 ID 클릭 시 동작 (인텐트 상세 조회 및 학습데이터 조회)
  const setQnAInfo = (e) => {
    setAnnoSelected(false);
    form.setValue('content', '');

    if (e) {
      form.setValue(
        'answerIntentId',
        scriptType?.current === 'A' ? intentId?.current : e.value,
      );
      form.setValue(
        'questionIntentId',
        scriptType?.current === 'A' ? e.value : intentId?.current,
      );

      setIntentSelected(true);
      fetchAnnotationDetail();
      fetchAnnotationList();
    } else {
      setIntentSelected(false);
      setDetailInfo({ question: '', answer: '' });
      form.clear();
      annotationStore.searchCondition.clear();
      annotationStore.refresh();
    }
  };

  const setAnnotationData = (rowData) => {
    setAnnoSelected(true);
    annotationUid.current = rowData.uid;
    form.setValue('content', rowData.content);
  };

  // 학습데이터 저장
  const saveAnnotation = () => {
    if (!annoSelected) {
      // 학습데이터 추가
      const { questionIntentId, answerIntentId, content } = form.values ?? {};
      if (!content.trim()) return;
      const data = {
        questionIntentId,
        answerIntentId,
        content,
        scriptType: scriptType?.current,
        standardSetType: standardSetType?.current,
      };

      axios
        .post(`/annotation/insert`, data)
        .then((res) => {
          if (res?.status == 200) {
            NotificationManager.success('학습데이터가 추가되었습니다.');
            annotationStore.fetch();
            form.setValue('content', '');
          }
        })
        .catch((error) => {
          console.error('saveAnnotation(insert) ::', error);
        });
    } else {
      // 학습데이터 수정
      const data = {
        uid: annotationUid?.current,
        content: form.values?.content,
        standardSetType: standardSetType?.current,
      };

      axios
        .post(`/annotation/update`, data)
        .then((res) => {
          if (res?.status == 200) {
            NotificationManager.success('학습데이터가 수정되었습니다.');
            annotationStore.fetch();
            setAnnoSelected(false);
            form.setValue('content', '');
          }
        })
        .catch((error) => {
          console.error('saveAnnotation(update) ::', error);
        });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    intentId.current = params.get('intentId');
    scriptType.current = params.get('scriptType');
    standardSetType.current = params.get('standardSetType');

    if (standardSetType?.current != 'E') {
      fetchIntentList();
    } else {
      form.setValue('questionIntentId', intentId?.current);
      fetchAnnotationDetail();
    }

    return () => {
      setIntentList([]);
      form.clear();
      annotationStore.clear();
    };
  }, []);

  return (
    <FrameWrap>
      <TitlePanel>
        <FormGroup>
          <Col className="justify-content-between align-items-center d-flex">
            <TitlePanelLabel label={'학습데이터 추가'} />
            <div>
              <Button
                variant="outline-secondary"
                className="ms-4"
                onClick={goBack}
              >
                목록으로
              </Button>
            </div>
          </Col>
        </FormGroup>
      </TitlePanel>
      <Panel className="flex-default d-flex gap-4">
        <div className={Styles.DatainfoWrapper}>
          <InputGroup className={Styles.InputWrapper}>
            <span>인텐트 ID</span>
            <SelectBox
              className="ms-4 text-start"
              store={form}
              name="intentId"
              placeholder="인텐트 선택"
              isDisabled={standardSetType?.current == 'E'}
              options={intentList}
              onChange={setQnAInfo}
            />
          </InputGroup>
          <div className={Styles.CardWrapper}>
            <Card
              className={`${Styles.Card} ${['S', 'Q'].includes(scriptType?.current) || standardSetType?.current == 'E' ? 'card-highlight' : ''}`}
            >
              <Card.Header
                className={
                  ['S', 'Q'].includes(scriptType?.current) ||
                  standardSetType?.current == 'E'
                    ? 'card-header-highlight'
                    : ''
                }
              >
                Question
              </Card.Header>
              <Card.Body>{detailInfo?.question}</Card.Body>
            </Card>
            <Card
              className={`${Styles.Card} ${scriptType?.current == 'A' ? 'card-highlight' : ''}`}
            >
              <Card.Header
                className={
                  scriptType?.current == 'A' ? 'card-header-highlight' : ''
                }
              >
                Answer
              </Card.Header>
              <Card.Body>{detailInfo?.answer}</Card.Body>
            </Card>
          </div>
        </div>
        <div className={Styles.DataGridWrapper}>
          <InputGroup className={Styles.InputWrapper}>
            <Input
              className="me-2"
              store={form}
              label="학습데이터 추가"
              name="content"
              disabled={!intentSelected && standardSetType?.current != 'E'}
              placeholder="학습데이터를 추가해주세요."
            />
            {annoSelected ? (
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    setAnnoSelected(false);
                    form.setValue('content', '');
                  }}
                >
                  취소
                </Button>
                <Button
                  variant="outline-primary"
                  className="ms-1"
                  onClick={() => {
                    saveAnnotation();
                  }}
                >
                  수정
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="outline-primary"
                  disabled={
                    (!intentSelected && standardSetType?.current != 'E') ||
                    !form.values?.content
                  }
                  onClick={() => {
                    saveAnnotation();
                  }}
                >
                  추가
                </Button>
              </div>
            )}
          </InputGroup>
          {console.log(form.values?.content)}
          <DataGrid
            store={annotationStore}
            keyColumn="intentId"
            columns={headerPrint(annotationData ?? [])}
            isCheckable={true}
            onRowClick={(rowData) => {
              setAnnotationData(rowData);
            }}
          />
        </div>
      </Panel>
    </FrameWrap>
  );
});

export default AnnotationDetailView;
