import React from 'react';
import MessageContent from './MessageContent.js';
import { CONTENT_TYPE } from '../../../const/ChatConst.js';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    let { store, isIncoming } = this.props;
    if (this.state.hasError) {
      return (
        <MessageContent
          store={store}
          isIncoming={isIncoming}
          content={{
            type: CONTENT_TYPE.TEXT,
            value: '정상 적인 메시지가 아닙니다.',
          }}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
