
export const TICKET_STATUS = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    IDLE: 'IDLE',
    CLOSED: 'CLOSED'
}

export const TICKET_PROPERTY_TYPE = {
    STRING : "STRING",
    NUMBER : "NUMBER",
    BOOLEAN : "BOOLEAN",
    DATE : "DATE",
    ENUM : "ENUM"
}

export const TICKET_EVENT_TYPE = {
    TICKET_ACTIVE_WAIT : "TICKET_ACTIVE_WAIT",
    TICKET_ACTIVE : "TICKET_ACTIVE",
    TICKET_IDLE_WAIT : "TICKET_IDLE_WAIT",
    TICKET_IDLE : "TICKET_IDLE",
    TICKET_ASSIGNMENT : "TICKET_ASSIGNMENT",
    TICKET_ASSIGNMENT_REQUEST : "TICKET_ASSIGNMENT_REQUEST",
    TICKET_ASSIGNMENT_WAIT : "TICKET_ASSIGNMENT_WAIT",
    TICKET_CLOSE_WAIT : "TICKET_CLOSE_WAIT",
    TICKET_CLOSED : "TICKET_CLOSED",
    TICKET_CREATED : "TICKET_CREATED",
    TICKET_STATUS_UPDATED : "TICKET_STATUS_UPDATED",
    TICKET_TRANSFER: "TICKET_TRANSFER",
    TICKET_TRANSFER_WAIT: "TICKET_TRANSFER_WAIT",
    TICKET_CLOSE_CONFIRM_SEND: "TICKET_CLOSE_CONFIRM_SEND",
    TICKET_CLOSE_CONFIRM_NO_REACT: "TICKET_CLOSE_CONFIRM_NO_REACT",
    TICKET_ENTER: "TICKET_ENTER",
}

export const MESSAGE_TYPE = {
    NORMAL: 'NORMAL',
    ADMIN: 'ADMIN',
    SYSTEM: 'SYSTEM',
    GREETING: 'GREETING',
    RECOMMEND: 'RECOMMEND'
}

export const CONTENT_TYPE = {
    IMAGE: 'IMAGE',
    TEXT: 'TEXT',
    FILE: 'FILE',
    VIDEO: 'VIDEO',
    AUDIO: 'AUDIO',
    BUTTON: 'BUTTON',
    LINE: 'LINE',
    CUSTOM: 'CUSTOM',
    DIALOG_FLOW_REQUEST: 'DIALOG_FLOW_REQUEST',
    DIALOG_FLOW_RESPONSE: 'DIALOG_FLOW_RESPONSE',
}

export const BUTTON_GROUP_TYPE = {
    GRID: 'GRID',
    NORMAL: 'NORMAL'
}

export const CHAT_EVENT_TYPE = {
    ACTIVE: 'ACTIVE',
    PENDING: 'PENDING',
    IDLE: 'IDLE',
    CLOSE: 'CLOSE',
    ENTER: 'ENTER',
    ASSIGNMENT: 'ASSIGNMENT',
    UNASSIGNED: 'UNASSIGNED',
    NOT_CONFIRM_COUNT: 'NOT_CONFIRM_COUNT',
    TYPING_START: 'TYPING_START',
    TYPING_END: 'TYPING_END',
    CONFIRM_MESSAGE: 'CONFIRM_MESSAGE',
    ASSIGNED: 'ASSIGNED',
}

export const ADMIN_MESSAGE_TYPE = {
    ENTER : 'ENTER',
    LEAVE : 'LEAVE'
}

export const CHANNEL_STATUS = {
    ACTIVE: '진행중',
    CLOSED: '종료됨',
}

export const BUTTON_TYPE = {
    INNER_LINK: 'INNER_LINK',
    OUTER_LINK: 'OUTER_LINK',
    MESSAGE: 'MESSAGE',
    TEL: 'TEL',
    TEL_CLOSE: 'TEL_CLOSE',
    OPEN_PANEL: 'OPEN_PANEL',
    DIALOG_FLOW: 'DIALOG_FLOW',
}

export const ANIMALS = [
    '고양이', '강아지', '거북이', '토끼', '뱀', '사자', '호랑이', '표범', '치타', '하이에나', '기린', '코끼리', '코뿔소', '하마', '악어', '펭귄', '부엉이', '올빼미', '곰', '돼지', '소', '닭', '독수리', '타조', '고릴라', '오랑우탄', '침팬지', '원숭이', '코알라', '캥거루', '고래', '상어', '칠면조', '직박구리', '쥐', '청설모', '메추라기', '앵무새', '삵', '스라소니', '판다', '오소리', '오리', '거위', '백조', '두루미', '고슴도치', '두더지', '우파루파', '맹꽁이', '너구리', '개구리', '두꺼비', '카멜레온', '이구아나', '노루', '제비', '까치', '고라니', '수달', '당나귀', '순록', '염소', '공작', '바다표범', '들소', '박쥐', '참새', '물개', '바다사자', '살모사', '구렁이', '얼룩말', '산양', '멧돼지', '카피바라', '도롱뇽', '북극곰', '퓨마', '미어캣', '코요테', '라마', '딱따구리', '기러기', '비둘기', '스컹크', '돌고래', '까마귀', '매', '낙타', '여우', '사슴', '늑대', '재규어', '알파카', '양', '다람쥐', '담비'
]

export const ADJECTIVES = [
    '적응할 수 있는', '공손한', '친근한', '공정한', '시간을 잘 지키는', '모험심이 강한', '궁금한', '재미있는', '친절한', '목적의식이 있는', '다정한', '결단력 있는', '재미를 추구하는', '적극적인', '활발한', '두뇌 회전이 빠른', '기민한', '믿을 수 있는', '관대한', '다정한', '빛나는', '야심 있는', '단호한', '온화한', '충실한', '현실적인', '쾌활한', '손재주가 좋은', '진실한', '즐거운', '사색적인', '영악한', '성실한', '나긋나긋한', '의욕을 가진', '믿을 수 있는', '배려하는', '사교성이 좋은', '우아한', '동기를 부여하는', '지혜가 있는', '진품인', '활발한', '자애로운', '품위 있는', '보살피는', '진실된', '알고 있는', '성실한', '행복한', '관찰력 있는', '붙임성 있는', '엄청난', '희망에 찬', '근면한', '솔직한', '강한', '용감한', '활동적인', '정직한', '마음이 트인', '공감하는', '용감한', '호감이 가는', '희망에 찬', '희망을 주는', '개방적인', '잘 믿는', '차분한', '열정적인', '낙천적인', '믿을만한', '겸손한', '유능한', '공정한', '체계적인', '긍정적인', '유머러스한', '배려하는', '용감한', '외향적인', '쾌활한', '이상주의적인', '동정하는', '유연한', '인내심 있는', '마음이 따뜻한', '창의적인', '자신감 있는', '집중을 잘하는', '집요한', '따뜻이 맞이하는', '혁신적', '배려하는', '너그러운', '장난기 많은', '지혜로운', '통찰력 있는', '좋아하는', '한결같은', '솔직한', '엉뚱한', '직관적', '긍정적인', '용감한', '자유분방한', '창의적인', '꼼꼼한', '즐거운'
]

export const USER_TYPE = {
    CUSTOMER: 'CUSTOMER',
    AGENT: 'AGENT',
    AGENT_LEADER: 'AGENT_LEADER',
    AGENT_BOT: 'AGENT_BOT',
    ADMIN: 'ADMIN',
    SUPER: 'SUPER',
}

export const AVATAR_TYPE = {
    CUSTOMER: 'CUSTOMER',
    AGENT: 'AGENT',
    AGENT_BOT: 'AGENT_BOT',
    AGENT_LEADER: 'AGENT_LEADER',
    TEAM: 'TEAM',
    ADMIN: 'ADMIN',
    SUPER: 'SUPER',
}

export const NOTIFICATION_TYPE = {
    SUCCESS:`SUCCESS`,
    INFO:`INFO`,
    WARNING:`WARNING`,
    DANGER:`DANGER`,
}

export const COMMAND_EVENT_TYPE = {
    REQUEST_AGENT: '상담사 연결',
    TICKET_CLOSE: '상담 종료'
}