import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import RandomColor from './RandomColor';
import DefaultAvatar from './DefaultAvatar';
import _ from 'lodash';
import DefaultChatbotImage from './DefaultChatbotImage';
import DefaultTeamImage from './DefaultTeamImage';
import PropTypes from 'prop-types';
import { AVATAR_TYPE } from '../../../const/ChatConst.js';
import DefaultCustomerImage from './DefaultCustomerImage.js';

class Avatar extends Component {
  render() {
    let {
      className = 'rounded-circle d-flex',
      seed,
      width = '100%',
      src,
      type,
    } = this.props;
    return (
      <>
        {src ? (
          <Image
            className={`${className ? className : ''}`}
            {..._.omit(this.props, [`className`])}
            style={{ width: width, objectFit: 'cover', aspectRatio: '1/1' }}
            src={src ?? ''}
          />
        ) : (
          type && (
            <RandomColor seed={seed} width={width}>
              {
                {
                  [AVATAR_TYPE.AGENT]: (
                    <DefaultAvatar
                      className={`${className ? className : ''}`}
                      {..._.omit(this.props, [`className`])}
                      style={{ width: width }}
                    />
                  ),
                  [AVATAR_TYPE.AGENT_LEADER]: (
                    <DefaultAvatar
                      className={`${className ? className : ''}`}
                      {..._.omit(this.props, [`className`])}
                      style={{ width: width }}
                    />
                  ),
                  [AVATAR_TYPE.TEAM]: (
                    <DefaultTeamImage
                      className={`${className ? className : ''}`}
                      {..._.omit(this.props, [`className`])}
                      style={{ width: width }}
                    />
                  ),
                  [AVATAR_TYPE.AGENT_BOT]: (
                    <DefaultChatbotImage
                      className={`${className ? className : ''}`}
                      {..._.omit(this.props, [`className`])}
                      style={{ width: width }}
                    />
                  ),
                  [AVATAR_TYPE.CUSTOMER]: (
                    <DefaultCustomerImage
                      className={`${className ? className : ''}`}
                      {..._.omit(this.props, [`className`])}
                      style={{ width: width }}
                    />
                  ),
                }[type]
              }
            </RandomColor>
          )
        )}
      </>
    );
  }
}

Avatar.propTypes = {
  type: PropTypes.string.isRequired,
  /* 타입 = agent, team, bot */
  src: PropTypes.string,
  /* 이미지 경로 */
  seed: PropTypes.oneOfType([PropTypes.number,PropTypes.string,])
  /* 랜덤이미지 생성시 고유 값 */
};

export default Avatar;
