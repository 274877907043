import React from 'react';
import Select from 'react-select';
import { observer } from 'mobx-react';
import { omit } from 'lodash';
const styles = {
  clearIndicator: (base) => ({
    ...base,
    padding: `0 .25rem`,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: `0 .25rem`,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    marginTop: '.25rem',
    marginBottom: '.25rem',
  }),
  container: (base) => ({
    ...base,
    marginRight: '.25rem',
    flex: 1,
    maxHeight: 31,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 .5rem',
  }),
  control: (base) => ({
    ...base,
    minHeight: 31,
    maxHeight: 31,
  }),
  input: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),

  group: (base) => ({
    ...base,
  }),
  groupHeading: (base) => ({
    ...base,
  }),

  loadingIndicator: (base) => ({
    ...base,
  }),
  loadingMessage: (base) => ({
    ...base,
  }),
  menu: (base) => ({
    ...base,
  }),
  menuList: (base) => ({
    ...base,
  }),
  menuPortal: (base) => ({
    ...base,
  }),
  multiValue: (base) => ({
    ...base,
  }),
  multiValueLabel: (base) => ({
    ...base,
  }),
  multiValueRemove: (base) => ({
    ...base,
  }),
  noOptionsMessage: (base) => ({
    ...base,
  }),
  option: (base) => ({
    ...base,
  }),
  placeholder: (base) => ({
    ...base,
  }),
  singleValue: (base) => ({
    ...base,
  }),
};

@observer
class SelectBox extends React.Component {
  constructor(props, context) {
    super(props, context);
    let { store, name, value, validations } = this.props;
    store.validationMap.set(name, validations);
    if (!store.valueMap.get(name)) store.valueMap.set(name, value || '');
  }
  render() {
    const {
      name,
      options,
      onChange,
      store,
      placeholder = '선택...',
      onBlur,
      validOnBlur,
      readOnly,
      isLoading = false,
      onFocus,
      validOnFocus,
    } = this.props;
    const value = store.valueMap.get(name);
    const selectedOption = options.find(
      (option) => option.value === value || null,
    );
    return (
      <div style={{ flex: 1 }}>
        <Select
          placeholder={placeholder}
          ref={(ref) => store.refs.set(name, ref)}
          isClearable
          isLoading={isLoading}
          styles={{
            container: (base) => ({
              ...base,
            }),
            control: (base) => ({
              ...base,
              boxShadow: 0,
              minHeight: '0.75rem',
              borderColor:
                store.hasTried.get(name) && store.getErrorMessages(name)[0]
                  ? 'red'
                  : 'hsl(0,0%,80%)',
              '&:hover': {
                borderColor:
                  store.hasTried.get(name) && store.getErrorMessages(name)[0]
                    ? 'red'
                    : 'hsl(0,0%,80%)',
              },
            }),
            menu: (base) => ({
              ...base,
              zIndex: 999,
            }),
            valueContainer: (base) => ({
              ...base,
              padding: '1.5px .5rem',
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              padding: '0 .25rem',
            }),
            clearIndicator: (provided) => ({
              ...provided,
              padding: '0 .25rem',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              padding: '0 .25rem',
            }),
            indicatorSeparator: (base) => ({
              ...base,
              margin: '.25rem 0',
            }),
          }}
          theme={(theme) => ({
            ...theme,
          })}
          isDisabled={readOnly || isLoading}
          options={options}
          onChange={(data) => {
            store.valueMap.set(name, data ? data.value : null);
            onChange && onChange(data);
          }}
          value={selectedOption || null}
          onBlur={(e) => {
            if (validOnBlur) store.hasTried.set(name, true);
            onBlur && onBlur(e);
          }}
          onFocus={(e) => {
            if (validOnFocus) store.hasTried.set(name, true);
            onFocus && onFocus(e);
          }}
          {...omit(this.props, 'name', 'options', 'onChange', 'conditionMap')}
        />
        {store.hasTried.get(name) && store.getErrorMessages(name)[0] && (
          <div className={`position-absolute`} style={{ color: 'red' }}>
            * {store.getErrorMessages(name)[0]}
          </div>
        )}
      </div>
    );
  }
}

export default SelectBox;

export { styles };
