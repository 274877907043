import { makeAutoObservable } from 'mobx';

class AudioStore {
  audioContext = new (window.AudioContext || window.webkitAudioContext)();
  source = null; // 현재 재생 중인 AudioBufferSourceNode를 저장
  isPlaying = false; // 재생 상태

  constructor() {
    makeAutoObservable(this);
  }

  play(blob) {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const arrayBuffer = fileReader.result;

      this.audioContext.decodeAudioData(
        arrayBuffer,
        (buffer) => {
          // 이미 재생 중이라면 중지
          if (this.isPlaying) {
            this.stop();
          }

          // 새로운 소스 생성
          this.source = this.audioContext.createBufferSource();
          this.source.buffer = buffer;
          this.source.connect(this.audioContext.destination);

          // 재생 시작
          this.isPlaying = true;

          // 재생이 끝났을 때 처리
          this.source.onended = () => {
            this.isPlaying = false;
            this.source = null; // 소스를 초기화
          };

          this.source.start(0);
        },
        (error) => {
          console.error(error);
        },
      );
    };

    fileReader.readAsArrayBuffer(blob);
  }

  stop() {
    if (!this.source) return;
    this.source.stop(0); // 재생 중지
    this.isPlaying = false;
    this.source = null; // 소스를 초기화
  }
}

export const audioStore = new AudioStore();
