import React from 'react';
import PropTypes from 'prop-types';
import { ChatStore } from '../../store/ChatStore.js';
import { observer } from 'mobx-react';
import Character from '../../../assets/character.svg';
import { Button, Image } from 'react-bootstrap';
import Styles from './Close.module.scss';

@observer
class Close extends React.Component {
  render() {
    let { onRestart, message = '챗봇 상담을 종료했습니다.' } = this.props;
    return (
      <div className={Styles.Container}>
        <div className={Styles.Content}>
          <div className={Styles.Character}>
            <Image src={Character} />
          </div>
          <div>
            <div className={Styles.LabelWrap}>
              <div className={Styles.Title}>{message}</div>
              <ul className={Styles.Label}>
                <li>
                  <p>
                    일정시간 입력이 없는 경우, 개인 정보 보호를 위해 챗봇과
                    상담을 종료합니다.
                  </p>
                </li>
                <li>
                  <p>채팅 상담을 원하시면, 상담 재시작 버튼을 선택해 주세요.</p>
                </li>
              </ul>
            </div>

            <Button
              style={{
                width: '100%',
                fontWeight: 'bold',
                padding: '0.75rem 0',
                borderRadius: 0,
                backgroundColor: '#FFE926',
                border: '#FFE926',
                color: 'var(--bs-gray-900)',
              }}
              onClick={() => {
                onRestart && onRestart();
              }}
            >
              상담 재시작
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Close.defaultProps = {};

Close.propType = {
  store: PropTypes.instanceOf(ChatStore),
};

export { Close };
