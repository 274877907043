import React from 'react';
import { Avatar, Badge } from 'antd';
import { randomColorSet } from '../../util/colorSet';
import { AGENT_STATUS } from '../../const/AppConst';
import classNames from 'classnames';
import S from './ProfileImg.module.scss';
import DefaultAvatar from './DefaultAvatar';
import DefaultTeamImage from './DefaultTeamImage';
import DefaultChatbotImage from './DefaultChatbotImage';

const ProfileImg = ({
  seed,
  size = 40,
  type = 'agent',
  status,
  className,
  isDot,
  ...props
}) => {
  const statusColor = status ? AGENT_STATUS[status]?.color : null;
  const randomColor = randomColorSet(seed);

  const defaultIcon = {
    agent: <DefaultAvatar {...randomColor} />,
    team: <DefaultTeamImage {...randomColor} />,
    chatbot: <DefaultChatbotImage {...randomColor} />,
  }[type];

  return (
    <Badge
      dot
      color={statusColor}
      count={!status && 0}
      className={classNames(S.Badge, !isDot && S.Hidden)}
    >
      <Avatar
        size={size}
        icon={defaultIcon}
        className={classNames(S.Avatar, className)}
        {...props}
      />
    </Badge>
  );
};

export default ProfileImg;
