import React, {Component} from 'react';
import {action, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Col, Form, InputGroup, Modal, Row, Spinner} from 'react-bootstrap';
import Styles from './RoadShowView.module.scss';
import axios from 'axios';
import {FormStore, Input, Validation} from '../../components/validation';
import moment from 'moment';
import appStore from '../../store/AppStore';
import {toast} from 'react-toastify';
import {confirmDialogStore, ConfirmDialog} from "@qbot-chat/qbot-uikit/modal";
import {Warning2} from 'iconsax-react';
import SelectBox from '../../components/input/SelectBox';
import ModalBase from "../../components/modal/ModalBase";
import {DatePicker} from "antd";
const { RangePicker } = DatePicker;
import dayjs from "dayjs";
import {ACCESS_RIGHTS} from "../../const/AppConst";
import {USER_TYPE} from "@qbot-chat/qbot-uikit";

class RoadShowDialogStore {

    @observable
    show = false;

    id;

    branchId;

    onSave;

    roadShowFormStore = new FormStore();
    @observable
    isSaving = false;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    open(id, onSave, branchId) {
        this.id = id;
        this.onSave = onSave;

        if (id) {
            axios.get(`/traders/roadshow/detail/${id}`)
                .then(({data}) => {
                    this.roadShowFormStore.values = data;
                });
        } else {
            this.roadShowFormStore.clear({store_id: branchId});
        }

        this.show = true;
    }

    @action.bound
    delete() {
        confirmDialogStore.openRemove(
            <Warning2/>,
            '삭제 하시겠습니까?',
            () => {
                axios.post(`/traders/roadshow/delete/${this.id}`)
                    .then(() => {
                        toast.info('로드쇼를 삭제했습니다.');
                        this.onSave && this.onSave();
                        this.close();
                    });
            }
        );
    }

    @action.bound
    close() {
        this.show = false;
        this.id = undefined;
        this.roadShowFormStore.clear();
    }

    @action.bound
    save() {

        if (!this.roadShowFormStore.doCheckValid()) {
            return;
        }

        this.isSaving = true;
        axios.post('/traders/roadshow/mod/write', {...this.roadShowFormStore.values})
            .then(() => {
                toast.info('로드쇼를 저장하였습니다.');
                this.onSave && this.onSave();
            })
            .finally(() => {
                this.close();
                this.isSaving = false;
            });

    }
}

const roadShowDialogStore = new RoadShowDialogStore();


@observer
class RoadShowDialog extends Component {

    handleClose() {
        roadShowDialogStore.close();
    }

    handleSelectStore(option) {
        roadShowDialogStore.roadShowFormStore.setValue('store_name', option ? option.label : null);
    }


    render() {
        const {id, show, roadShowFormStore} = roadShowDialogStore;
        const {reg_dts, mod_dts} = roadShowFormStore.values;
        return (
            <ModalBase
                   show={show}
                   size={'lg'}
                   onHide={this.handleClose.bind(this)}
            >
                <Modal.Header closeButton>
                    <div className={Styles.Header}>
                        {
                            id ? '로드쇼 수정' : '새 로드쇼 작성'
                        }
                    </div>
                </Modal.Header>
                <Modal.Body className={Styles.Body}>
                    <Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            지점
                        </Col>
                        <Col md={10} sm={10}>
                            <SelectBox options={appStore.trBranchesOptions}
                                       store={roadShowFormStore}
                                       name="store_id"
                                       onChange={this.handleSelectStore}
                                       validations={[
                                           new Validation(/^.+$/, '지점을 입력해주세요.')
                                       ]}/>
                        </Col>
                    </Form.Group>
                    {/*<Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            점포명
                        </Col>
                        <Col md={10} sm={10}>
                            <Input store={roadShowFormStore} name="store_name" size="md"
                                   validations={[new Validation(/^.+$/, '점포명을 입력해주세요.')]}
                            />
                        </Col>
                    </Form.Group>*/}
                    <Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            제목
                        </Col>
                        <Col md={10} sm={10}>
                            <Input store={roadShowFormStore} name="title" size="md"
                                   validations={[new Validation(/^.+$/, '제목을 입력해주세요.')]}
                                   maxLength={255}
                                   postfix={
                                       <InputGroup.Text>
                                           <Form.Text muted>
                                               {roadShowFormStore.valueMap.get('title')?.length || 0}/255
                                           </Form.Text>
                                       </InputGroup.Text>
                                   }
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            테마
                        </Col>
                        <Col md={10} sm={10}>
                            <Input store={roadShowFormStore} name="gcode_name" size="md"
                                   validations={[new Validation(/^.+$/, '테마를 입력해주세요.')]}
                                   maxLength={50}
                                   postfix={
                                       <InputGroup.Text>
                                           <Form.Text muted>
                                               {roadShowFormStore.valueMap.get('gcode_name')?.length || 0}/50
                                           </Form.Text>
                                       </InputGroup.Text>
                                   }
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            위치
                        </Col>
                        <Col md={10} sm={10}>
                            <Input store={roadShowFormStore} name="location" size="md" maxLength={50}
                                   postfix={
                                       <InputGroup.Text>
                                           <Form.Text muted>
                                               {roadShowFormStore.valueMap.get('location')?.length || 0}/50
                                           </Form.Text>
                                       </InputGroup.Text>
                                   }
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            시작-종료일
                        </Col>
                        <Col md={10} sm={10}>
                            <RangePicker className={'me-3 mt-2 mb-2'} popupStyle={{ zIndex: '999999' }}
                                         value={[roadShowFormStore.valueMap.get('from_date') ?
                                             dayjs(roadShowFormStore.valueMap.get('from_date')) :
                                             undefined
                                             ,
                                             roadShowFormStore.valueMap.get('due_date') ?
                                                 dayjs(roadShowFormStore.valueMap.get('due_date')) :
                                                 undefined
                                         ]}
                                         onChange={(dates) => {
                                             roadShowFormStore.setValue('from_date', dates ? dates[0] : null);
                                             roadShowFormStore.setValue('due_date', dates ? dates[1] : null);
                                         }}
                            />
                            {/*<DateRangePicker store={roadShowFormStore} fromName="from_date" toName="due_date"*/}
                            {/*                 fromValidations={[new Validation(date => !!date, '시작날짜를 선택해주세요.')]}*/}
                            {/*                 toValidations={[new Validation(date => !!date, '종료날짜를 선택해주세요.')]}*/}
                            {/*                 allowPast={!!id}*/}
                            {/*                 converterTo={ (d) => d?.format() }*/}
                            {/*/>*/}
                        </Col>
                    </Form.Group>

                    {
                        id &&
                        <>
                            <Form.Group as={Row} className="mb-4">
                                <Col md={2} sm={2}>
                                    <Form.Label>작성자</Form.Label>
                                </Col>
                                <Col md={10} sm={10}>
                                    <div>{roadShowFormStore.valueMap.get('author_name')}</div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4">
                                <Col md={2} sm={2}>
                                    <Form.Label>생성일</Form.Label>
                                </Col>
                                <Col md={4} sm={4}>
                                    <div>{reg_dts && moment(reg_dts).format('YYYY-MM-DD a h:mm:ss')}</div>
                                </Col>
                                <Col md={2} sm={2}>
                                    <Form.Label>수정일</Form.Label>
                                </Col>
                                <Col md={4} sm={4}>
                                    <div>{mod_dts && moment(mod_dts)?.format('YYYY-MM-DD a h:mm:ss')}</div>
                                </Col>
                            </Form.Group>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        id && ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type) &&
                        <div className="flex-grow-1">
                            <Button variant="outline-danger" onClick={roadShowDialogStore.delete}>삭제</Button>
                        </div>
                    }

                    <Button variant="outline-secondary" onClick={roadShowDialogStore.close}>닫기</Button>
                    {
                        ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type) &&
                        <Button variant="outline-primary"
                                onClick={roadShowDialogStore.save}
                                disabled={roadShowDialogStore.isSaving}
                        >
                            {
                                roadShowDialogStore.isSaving ?
                                    <Spinner/>
                                    :
                                    '저장'
                            }

                        </Button>
                    }
                </Modal.Footer>
                <ConfirmDialog/>
            </ModalBase>
        );
    }

}

export {RoadShowDialog as default, roadShowDialogStore};
