import React, { useState } from 'react';
import S from './NavDropMenu.module.scss';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { QtText } from '../../custom/CustomTypography';

const DropMenu = ({
  collapsed,
  children,
  items,
  placement = 'rightTop',
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  if (!items?.length) return children;
  return (
    <Tooltip
      title={
        <ul
          className={classNames(
            S.DropDown,
            collapsed && S.Collapsed,
            className,
          )}
        >
          {items.map((v) => (
            <li
              className={classNames({ [S.Active]: v.isActive })}
              key={v.key}
              onClick={() => {
                v.onClick?.();
                setIsOpen(false);
              }}
            >
              <QtText ellipsis>{v.label}</QtText>
            </li>
          ))}
        </ul>
      }
      open={isOpen}
      onOpenChange={setIsOpen}
      arrow={false}
      trigger="click"
      color="transparent"
      overlayInnerStyle={{ padding: 0 }}
      placement={placement}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default DropMenu;
