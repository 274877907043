import React, { useState } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  getSmoothStepPath,
  useReactFlow,
} from 'reactflow';
import Styles from './NodeFlow.module.scss';
import { getDepthNodes } from './FlowOptions';

function CustomEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  selected,
}) {
  const { getEdges, getNodes, setNodes, setEdges } = useReactFlow();
  const [isHover, setIsHover] = useState(false);
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const onDeleteEdge = () => {
    const nodes = getNodes();
    const edges = getEdges();

    const newNodes = nodes.map((node) => {
      if (node.id === source) {
        return {
          ...node,
          data: {
            ...node.data,
            nextIds: node.data.nextIds.filter((v) => v !== target),
          },
        };
      }
      if (node.id === target) {
        return {
          ...node,
          data: {
            ...node.data,
            prevIds: node.data.prevIds.filter((v) => v !== source),
          },
        };
      }
      return node;
    });
    const newEdges = edges.filter((edge) => edge.id !== id);

    setNodes(getDepthNodes(newNodes, newEdges));
    setEdges(newEdges);
  };
  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{ ...style, stroke: isHover ? '#0d6efd' : '' }}
      />
      <EdgeLabelRenderer>
        <button
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
          className={`${Styles.Edgebutton} ${!!selected ? Styles.Selected : ''}`}
          onClick={onDeleteEdge}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          ×
        </button>
      </EdgeLabelRenderer>
    </>
  );
}

export default CustomEdge;
