import React from 'react';
import PropTypes from 'prop-types';
import CharacterSvg from '../../../../../assets/character.svg';
import BubbleSvg from '../../../../../assets/bubble.svg';
import {Image} from "react-bootstrap";
import Styles from './GreetingMessage.module.scss'

const withPropsSvg = (src, propKey, cssKey, propKey2, cssKey2) => (props) => {
    const { [propKey]: propValue, [propKey2]: propValue2, ...rest } = props;
    return (
        <Image src={src}
            {...rest}
            style={{ [cssKey]: propValue, [cssKey2]: propValue2 }}
        />
    );
};

const Erody = withPropsSvg(CharacterSvg, 'hoodColor', '--hood-color', 'trBadge', '--tr-badge');
const Bubble = withPropsSvg(BubbleSvg, 'bgColor', '--bg-color');

const Character = ({brandName}) => (
    <div className={Styles.CharacterContainer}>
        <div className={Styles.Message}>
            <div className={Styles.Content}>
                안녕하세요.
                <div className={`fw-bold`}>
                    고객님,
                    <br />
                    {brandName}
                    {' '}
                    상담봇
                    <br />
                    입니다.
                </div>
            </div>
            <div className={Styles.Bubble}>
                <Bubble bgColor="var(--emart-point-color)"/>
            </div>
        </div>
        <div className={Styles.Character} style={{height:113}}>
            <Erody hoodColor="var(--erody-hood-color)" trBadge="var(--erody-tr-badge)" width={104} height={113} />
        </div>
    </div>
);

Character.propTypes = {
    brandName: PropTypes.string,
};

Character.defaultProps = {
    brandName: '퀀텀AI',
};

export default Character;
