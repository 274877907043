import React from "react";
import Styles from "./Badge.module.scss";

class Badge extends React.Component{
    render() {

        let {location, id, color} = this.props;

        return (
            <div className={Styles.Location}>
                <div style={{backgroundColor:color}}>{location}{id}</div>
            </div>
        )
    }
}

export {Badge as default};