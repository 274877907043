import * as React from 'react';

const SVGComponent = ({
  size = 24,

  color = '',
  ...props
}) => {
  return (
    <svg
      id="TicketStatusClosedIcon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
        width: size,
        height: 'auto',
        fill: color,
      }}
      xmlSpace="preserve"
      {...props}
    >
      {/*--여기까지는 기본값--*/}

      <path d="M22.8,15c0,4.4-1.3,5.8-5.8,5.8H7c-4.4,0-5.8-1.3-5.8-5.8v-0.5c0-0.4,0.3-0.8,0.8-0.8c1,0,1.8-0.8,1.8-1.8 S3,10.2,2,10.2c-0.4,0-0.8-0.3-0.8-0.8V9c0-4.4,1.3-5.8,5.8-5.8h10c4.4,0,5.8,1.3,5.8,5.8 M21.2,9c0-3.6-0.7-4.2-4.2-4.2H7 c-3.5,0-4.2,0.7-4.2,4.1c1.4,0.3,2.5,1.6,2.5,3.2s-1.1,2.8-2.5,3.2c0,3.4,0.7,4.1,4.2,4.1h10c3.3,0,4.1-0.6,4.2-3.6" />
      <path d="M-5.4,15c0,4.4-1.3,5.8-5.8,5.8h-10c-4.4,0-5.8-1.3-5.8-5.8v-0.5c0-0.4,0.3-0.8,0.8-0.8c1,0,1.8-0.8,1.8-1.8 s-0.8-1.7-1.8-1.7c-0.4,0-0.8-0.3-0.8-0.8V9c0-4.4,1.3-5.8,5.8-5.8h10c4.4,0,5.8,1.3,5.8,5.8 M-7,9c0-3.6-0.7-4.2-4.2-4.2h-10 c-3.5,0-4.2,0.7-4.2,4.1c1.4,0.3,2.5,1.6,2.5,3.2s-1.1,2.8-2.5,3.2c0,3.4,0.7,4.1,4.2,4.1h10c3.3,0,4.1-0.6,4.2-3.6" />
      <path d="M-13.1,11.3L-17,9.1c-0.5-0.3-1.2,0.1-1.2,0.7v4.5c0,0.6,0.7,1,1.2,0.7l3.9-2.2C-12.5,12.4-12.5,11.6-13.1,11.3 z" />
      <path d="M14.1,15.1H9.9c-0.6,0-1-0.4-1-1V9.9c0-0.6,0.4-1,1-1h4.1c0.6,0,1,0.4,1,1v4.1C15.1,14.6,14.6,15.1,14.1,15.1z" />
    </svg>
  );
};
export default SVGComponent;
