import React, { useEffect } from 'react';
import Styles from './TicketDetailView.module.scss';
import appStore from '../../store/AppStore';
import { observer } from 'mobx-react';
import { ErpFrame } from '@qbot-chat/qbot-uikit';
import { ticketStore } from '../../store/AdminChatStore';

const TicketDetailView = observer(({ store, ticket, historyTickets }) => {
  const isFinished =
    ticketStore?.ticketMap.get(ticket?.id)?.isFinished ?? false;

  useEffect(() => {
    store.setChannel(ticket);
  }, [isFinished]);

  return (
    <div className={Styles.TicketDetail}>
      <ErpFrame
        ticket={ticket}
        isFinished={isFinished}
        setProperty={(data) => store.setProperty(data)}
        counselEachFetch={() => store.counselEachFetch(ticket)}
        companyId={appStore.companyId}
      />
    </div>
  );
});

TicketDetailView.propTypes = {};

export default TicketDetailView;
