import { useEffect, useState } from "react";

export function useOutsideClose(ref) {
  const [selectShow, setSelectShow] = useState(false);

  useEffect(() => {
    function handleInteraction(event) {
      if (ref?.current && !ref.current.contains(event.target)) {
        setSelectShow(false);
      }
    }

    document.addEventListener("click", handleInteraction);
    return () => {
      document.removeEventListener("click", handleInteraction);
    };
  }, [ref]);

  return [selectShow, setSelectShow];
}
