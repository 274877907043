import React from 'react';
import PropTypes from 'prop-types';
import FormStore from './FormStore';
import {Overlay, Tooltip} from 'react-bootstrap';
import {observer} from 'mobx-react';


@observer
class ValidationTooltip extends React.Component {

    render() {
        let {store, name, style} = this.props;
        if (store.hasTried.has(name) && store.getErrorMessages(name).length > 0)
            return (
                <Overlay target={store.refs.get(name)} show placement="top">
                    <Tooltip id="name" placement="top" className="in" style={style}>
                        {
                            store.getErrorMessages(name)[0]
                        }
                    </Tooltip>
                </Overlay>
            );
        else
            return '';
    }
}

ValidationTooltip.propTypes = {
    store: PropTypes.instanceOf(FormStore).isRequired,
    name: PropTypes.string.isRequired
};

export default ValidationTooltip;