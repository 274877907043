import React, { Component } from 'react';
import Styles from './TitlePanelLabel.module.scss';
import { ArrowRight2 } from 'iconsax-react';

class TitlePanelLabel extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { label } = this.props;
    return (
      <div className={Styles.Label}>
        <ArrowRight2 size={'16'} />
        {label}
      </div>
    );
  }
}

export default TitlePanelLabel;
