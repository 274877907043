const getMimeTypeFromExtension = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  const mimeTypes = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',
    webp: 'image/webp',
    pdf: 'application/pdf',
    txt: 'text/plain',
    html: 'text/html',
    csv: 'text/csv',
    json: 'application/json',
    xml: 'application/xml',
    zip: 'application/zip',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    hwp: 'application/x-hwp',
  };
  return mimeTypes[extension] || 'application/octet-stream';
};

export const fileUrl = (file, fileName) => {
  if (!file || !fileName) return;
  const mimeType = getMimeTypeFromExtension(fileName);
  const byteCharacter = atob(file);
  const byteNumbers = new Array(byteCharacter.length);
  for (let i = 0; i < byteCharacter.length; i++) {
    byteNumbers[i] = byteCharacter.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: mimeType,
  });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
};

export const fileDownload = (file, fileName) => {
  if (!file || !fileName) return;
  const blobUrl = fileUrl(file, fileName);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(blobUrl);
};
