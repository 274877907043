import React from 'react';
import S from './NavMenu.module.scss';
import classNames from 'classnames';
import { QtText } from '../../custom/CustomTypography';

const NavMenuItem = ({
  className,
  isBlock,
  collapsed,
  icon,
  label,
  isActive,
  ...props
}) => {
  return (
    <div
      className={classNames(
        S.MenuItem,
        {
          [S.Block]: isBlock,
          [S.Collapsed]: collapsed,
          [S.isActive]: isActive,
        },
        className,
      )}
      {...props}
    >
      <button>
        <span className={S.Icon}>{icon}</span>
        <QtText ellipsis className={S.Text}>
          {label}
        </QtText>
      </button>
    </div>
  );
};

export default NavMenuItem;
