import React from 'react';
import { observer } from 'mobx-react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { IoCloseCircleOutline } from 'react-icons/io5';

@observer
class TextEditor extends React.Component {
  render() {
    let { type, value = '', idx, onChangeItem } = this.props;
    return (
      <div>
        <InputGroup
          size={'sm'}
          className={`justify-content-end align-items-center`}
        >
          <FormControl
            placeholder={``}
            value={value || ''}
            as={'textarea'}
            style={{ resize: 'none' }}
            onChange={(e) => {
              let value = e.target.value;
              let newValue = { type, value, isInvalid: !value };
              onChangeItem && onChangeItem(newValue, idx);
            }}
            isInvalid={!value}
          />
          {value && (
            <div
              className={`d-flex position-absolute me-2`}
              style={{ cursor: 'pointer', zIndex: 3 }}
              onClick={() => {
                onChangeItem &&
                  onChangeItem({ type, value: '', isInvalid: !value }, idx);
              }}
            >
              <IoCloseCircleOutline />
            </div>
          )}
          <Form.Control.Feedback type="invalid" className={`text-left`}>
            텍스트를 입력해 주세요.
          </Form.Control.Feedback>
        </InputGroup>
      </div>
    );
  }
}

export default TextEditor;
