import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { FormStore, Input } from '../../components/validation';
import { axiosInstancePDS, callDataGridStore } from './CallListView';
import { NotificationManager } from 'react-notifications';
import { goBack, navigateReplace } from '../../helper/HistoryHelper';
import Panel from '../../components/layout/Panel';
import { ConfirmDialog, confirmDialogStore } from '@qbot-chat/qbot-uikit';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import TitlePanel from '../../components/layout/TitlePanel';
import { makeObservable, observable } from 'mobx';
import FrameWrap from '../../components/layout/FrameWrap';
import appStore from '../../store/AppStore';
import Select from 'react-select';
import { Warning2 } from 'iconsax-react';

const form = new FormStore();

@observer
class CallDetailView extends Component {
  @observable
  isFetching = false;

  @observable
  baseDt = '';

  // 기존 시나리오ID
  sid = '';

  // 기존 전화번호
  @observable
  num = '';

  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      scenarioList: [],
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    this.baseDt = params.get('basDt');
    this.num = params.get('num').replace(/"|=/g, '');
    this.sid = params.get('sid');

    this.fetchDetail();
    this.fetchScenarioList();
  }

  fetchDetail() {
    const queryData = {
      basDt: this.baseDt,
      sid: this.sid,
      num: this.num,
    };
    axiosInstancePDS
      .post(`/call/detail?` + new URLSearchParams(queryData))
      .then((res) => {
        form.values = res.data.data;
      })
      .catch((e) => {
        NotificationManager.error('데이터를 불러올 수 없습니다.');
        console.log(`CallDetailView/fetchDetail(): ${e}`);
      });
  }

  fetchScenarioList() {
    this.isFetching = true;
    const scenarioList = [];
    axiosInstancePDS
      .post('/call/scenarioList', {
        companyId: appStore.companyId,
      })
      .then((res) => {
        res?.data?.items?.map((data) => {
          scenarioList.push({
            value: data.scenarioId,
            label: data.scenarioId,
            name: data.scenarioName,
          });
        });
        this.setState({
          scenarioList: [...scenarioList],
        });
      })
      .catch((e) => console.log('CallDetailView/fetchScenarioList(): ', e))
      .finally(() => (this.isFetching = false));
  }

  selectScenario(e) {
    if (e) {
      form.setValue('scenarioId', e.value);
      form.setValue('scenarioName', e.name);
    }
  }

  delete() {
    const delData = [
      {
        basDt: this.baseDt,
        scenarioId: this.sid,
        phoneNumber: this.num,
      },
    ];
    confirmDialogStore.openRemove(<Warning2 />, `삭제 하시겠습니까?`, () => {
      axiosInstancePDS
        .put(`/call/delNumber`, delData)
        .then(() => {
          callDataGridStore.fetch();
          NotificationManager.success('전화번호가 삭제되었습니다.');
          navigateReplace(`/main/call/list`);
        })
        .catch((e) => {
          NotificationManager.error('삭제 실패하였습니다.');
          console.error(e);
        });
    });
  }

  save() {
    const currentData = {
      basDt: this.baseDt,
      sid: this.sid,
      num: this.num,
    };
    const newData = {
      scenarioId:
        currentData.sid !== form.values.scenarioId
          ? form.values.scenarioId
          : '',
      phoneNumber:
        currentData.num !== form.values.phoneNumber
          ? form.values.phoneNumber
          : '',
    };
    axiosInstancePDS
      .put(`/call/updNumber?` + new URLSearchParams(currentData), newData)
      .then(() => {
        callDataGridStore.fetch();
        NotificationManager.success('변경사항이 저장되었습니다.');
      })
      .catch((e) => {
        NotificationManager.error('변경사항 저장에 실패하였습니다');
        console.log(e);
      });
  }

  render() {
    let scenarioId = form.values.scenarioId;
    return (
      <FrameWrap className={`d-flex flex-column flex-default overflow-y-auto`}>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'상세 정보'} />
              <div>
                <Button
                  variant={'outline-danger'}
                  className={`ms-1`}
                  onClick={() => this.delete()}
                >
                  삭제
                </Button>
                <Button
                  variant={'outline-secondary'}
                  className={'ms-4'}
                  onClick={() => goBack()}
                >
                  취소
                </Button>
                <Button
                  variant={'outline-primary'}
                  className={`ms-1`}
                  onClick={() => this.save()}
                >
                  저장
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup as={Row}>
              <Col className="pt-5">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">전화번호 정보</FormLabel>
                    <div className="d-flex justify-content-between align-items-center w-75 gap-5">
                      <span>시나리오 ID</span>
                      <div className="w-75">
                        <Select
                          placeholder={scenarioId}
                          options={this.state.scenarioList}
                          onChange={(e) => this.selectScenario(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-3 mx-4">
                    <div className="d-flex justify-content-between align-items-center w-75 gap-5">
                      <span>시나리오 명</span>
                      <div className="w-75">
                        <Input
                          store={form}
                          label={`시나리오명`}
                          name={`scenarioName`}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-3 mx-4">
                    <div className="d-flex justify-content-between align-items-center w-75 gap-5">
                      <span>전화번호</span>
                      <div className="w-75">
                        <Input
                          store={form}
                          label={`전화번호`}
                          name={`phoneNumber`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className="pt-5">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">티켓ID</FormLabel>
                    <div className="w-75">
                      <Input
                        store={form}
                        label={`티켓ID`}
                        name={`ticketId`}
                        disabled
                        validOnBlur
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className="pt-5">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">상태</FormLabel>
                    <div className="w-75">
                      <Input
                        store={form}
                        label={`상태`}
                        name={`callStatusName`}
                        disabled
                        validOnBlur
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </FormGroup>

            <Form.Group as={Row}></Form.Group>
          </Form>
        </Panel>
        <ConfirmDialog />
      </FrameWrap>
    );
  }
}

export default CallDetailView;
