import React, { useState } from 'react';
import { Form, Dropdown, InputGroup, Spinner } from 'react-bootstrap';
import AgentProfile from './AgentProfile.js';
import { observer } from 'mobx-react';
import Styles from './AgentDropDown.module.scss';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SearchNormal1 } from 'iconsax-react';

const CustomToggle = React.forwardRef(({ onClick, user, isFetching }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.stopPropagation();
      onClick(e);
    }}
    style={{ borderRadius: 8, overflow: 'hidden' }}
  >
    {isFetching ? (
      <div className={Styles.AgentProfile}>
        <Spinner variant="primary" animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    ) : (
      <AgentProfile user={user} />
    )}
  </div>
));

const CustomMenu = React.forwardRef(
  ({ children, style, onClose, show }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={`${show ? 'd-block' : 'd-none'} ${Styles.Menu}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={Styles.Search}>
          <InputGroup>
            <InputGroup.Text className={Styles.Icon}>
              <SearchNormal1 size={24} />
            </InputGroup.Text>
            <Form.Control
              autoFocus
              placeholder="상담원 검색"
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </InputGroup>
        </div>
        <PerfectScrollbar style={{ maxHeight: 300 }}>
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              child.props.user.name.toLowerCase().includes(value.toLowerCase()),
          ).length > 0 ? (
            React.Children.toArray(children).filter(
              (child) =>
                !value ||
                child.props.user.name
                  .toLowerCase()
                  .includes(value.toLowerCase()),
            )
          ) : (
            <div className={Styles.AgentProfile} onClick={onClose}>
              <div className={Styles.Avatar}></div>
              <div className={Styles.Name}>검색결과 없음</div>
            </div>
          )}
        </PerfectScrollbar>
      </div>
    );
  },
);

const CustomItem = React.forwardRef(({ user, onUserSelect }, ref) => {
  return (
    <div
      ref={ref}
      className={'d-flex align-items-center'}
      onClick={() => {
        user.status === 'ACTIVE' && onUserSelect && onUserSelect(user);
      }}
    >
      <AgentProfile user={user} />
    </div>
  );
});

@observer
class AgentDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      selected: undefined,
      show: false,
      isFetching: false,
    };
    let { rsocket } = props;
    this.rsocket = rsocket;
  }

  componentDidMount() {}

  load() {
    const { ticket } = this.props;
    let store = this;
    store.setState({ isFetching: true });
    store.rsocket
      .requestChatApi(`/agent/transfer/list`, { ticketId: ticket.id })
      .then((r) => {
        store.onOpen();
        if (r.data) {
          r.data = r.data.sort(function (comp1, comp2) {
            // 대/소문자 구분 없이
            let comp1UC = comp1.status.toUpperCase();
            let comp2UC = comp2.status.toUpperCase();
            if (comp1UC < comp2UC) {
              return -1;
            } else if (comp1UC > comp2UC) {
              return 1;
            }
            return 0;
          });
        }
        this.setState({ list: r.data || [], isFetching: false });
      });
  }

  selectUser(user) {
    this.setState({ selected: user });
    this.onClose();
  }

  onOpen() {
    this.setState({ show: !this.state.show });
  }

  onClose() {
    this.setState({ show: false });
  }

  render() {
    let { user } = this.props;
    return (
      <div>
        <Dropdown show={this.state.show}>
          <Dropdown.Toggle
            as={CustomToggle}
            user={user || this.state.selected}
            isFetching={this.state.isFetching}
            onClick={(e) => {
              e.preventDefault();
              if (!this.state.show) {
                this.load();
              } else {
                this.onClose();
              }
            }}
          />
          <Dropdown.Menu
            as={CustomMenu}
            show={this.state.show}
            onClose={this.onClose}
          >
            {this.state.list?.map((user) => (
              <Dropdown.Item
                as={CustomItem}
                user={user}
                key={user.id}
                onUserSelect={(user) => {
                  this.selectUser(user);
                }}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export { AgentDropdown as default };
