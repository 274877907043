import React from 'react';
import Styles from './NoChannel.module.scss';
import { observer } from 'mobx-react';
import TicketEmptyIcon from '../../icon/TicketEmptyIcon.js';

@observer
class NoChannel extends React.Component {
  stream;

  componentDidMount() {}

  render() {
    // let {store} = this.props;
    return (
      <div className={Styles.NoChannel}>
        <div className={Styles.Icon}>
          <TicketEmptyIcon size={'2rem'} color={'var(--qt-cool-gray6)'} />
        </div>
        티켓이 없습니다.
      </div>
    );
  }
}

NoChannel.propType = {};

export { NoChannel };
