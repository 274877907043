import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Redirect, Route, Switch} from "react-router-dom";
import RealTimeView from "./RealTimeView";

@observer
class DashboardFrame extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <Switch>
                <Route path="/main/dashboard/real" component={RealTimeView}/>
                <Redirect exact from="/main/dashboard" to="/main/dashboard/real"/>
            </Switch>
        );
    }
}

export default DashboardFrame;
