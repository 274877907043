import React from 'react';
import Styles from './Content.module.scss';
import { observer } from 'mobx-react';
import { ReplyMessage } from './ReplyMessage.js';

@observer
class TextContent extends React.Component {
  highLight(content) {
    let { store } = this.props;
    const reg = new RegExp(store.keyword, 'g');
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: content.replaceAll(
            reg,
            (matched) => `<span class=${Styles.Highlight}>${matched}</span>`,
          ),
        }}
      ></div>
    );
  }
  render() {
    let { value, store, isBubble, isIncoming, replyMessage } = this.props;
    return (
      <div
        className={`${isBubble ? Styles.Bubble : ``} ${isIncoming ? Styles.In : Styles.Out}`}
      >
        {replyMessage && (
          <ReplyMessage
            isIncoming={isIncoming}
            store={store}
            message={replyMessage}
          />
        )}
        <div className={Styles.TextContent}>
          {store?.isSearchMode && store?.keyword
            ? this.highLight(value)
            : value}
        </div>
      </div>
    );
  }
}

export { TextContent };
