import React from 'react';
import { observer } from 'mobx-react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { IoCloseCircleOutline } from 'react-icons/io5';
import axios from 'axios';
import { FolderOpen } from 'iconsax-react';
import { APP_CONTEXT_PATH } from '../../../const/AppConst';
import { CONTENT_TYPE } from '@qbot-chat/qbot-uikit';

@observer
class MediaEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isError: false,
      isUrl: false,
    };
  }

  render() {
    let { type, value, idx, onChangeItem, accept } = this.props;
    let { name = '', url = '' } = value;
    return (
      <div>
        <input
          className={`d-none`}
          type={`file`}
          accept={accept}
          ref={(ref) => (this.ref = ref)}
          onChange={(e) => {
            let file = e.target.files[0];
            if (file) {
              const formData = new FormData();
              formData.append('file', file);
              axios
                .post(`/upload/draft`, formData)
                .then((res) => {
                  let { url, name } = res.data;
                  this.ref.value = '';
                  onChangeItem &&
                    onChangeItem(
                      {
                        type,
                        value: {
                          name,
                          size: '',
                          url,
                          fullUrl: `${APP_CONTEXT_PATH}/upload/${url}/preview`,
                        },
                      },
                      idx,
                    );
                })
                .catch(() => {
                  this.ref.value = '';
                });
            }
          }}
        />
        <div className={`d-flex justify-content-between`}>
          <div className={`d-flex flex-grow-1`}>
            <InputGroup
              size={'sm'}
              className={`justify-content-end align-items-center`}
            >
              <InputGroup.Text>URL</InputGroup.Text>
              <FormControl
                placeholder={``}
                value={name}
                onChange={(e) => {
                  let url = e.target.value;
                  let name = e.target.value;
                  onChangeItem &&
                    onChangeItem(
                      {
                        type,
                        value: { name, url, fullUrl: url, type: 'URL' },
                        isInvalid: !url,
                      },
                      idx,
                    );
                }}
                isInvalid={!url}
              />
              {url && (
                <div
                  className={`d-flex position-absolute me-2`}
                  style={{ cursor: 'pointer', zIndex: 3 }}
                  onClick={() => {
                    onChangeItem &&
                      onChangeItem(
                        {
                          type,
                          value: { name: '', size: '', url: '', fullUrl: '' },
                          isInvalid: true,
                        },
                        idx,
                      );
                  }}
                >
                  <IoCloseCircleOutline />
                </div>
              )}
              <Form.Control.Feedback type="invalid" className={`text-left`}>
                {type === CONTENT_TYPE.AUDIO
                  ? '오디오'
                  : type === CONTENT_TYPE.VIDEO
                    ? '비디오'
                    : '이미지'}
                를 등록해 주세요.
              </Form.Control.Feedback>
            </InputGroup>
          </div>
          <div className={`ms-3`}>
            <Button
              variant={'primary'}
              size={`sm`}
              onClick={() => {
                this.ref.click();
              }}
            >
              <div
                className={`d-flex justify-content-center align-items-center`}
              >
                <FolderOpen size={16} />
                <div className={`ps-1`}>파일열기</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default MediaEditor;
