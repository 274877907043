import React from 'react';
import Styles from './DropDownButton.module.scss';
import { CustomButton } from '../../../Component.js';

class DropDownButton extends React.Component {
  render() {
    let { className = '', children, onClick, disabled = false } = this.props;
    return (
      <CustomButton
        className={`${Styles.DropDownButton} ${className}`}
        onClick={() => {
          onClick && onClick();
        }}
        disabled={disabled}
      >
        {children}
      </CustomButton>
    );
  }
}

export { DropDownButton as default };
