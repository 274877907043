import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Styles from './SearchRadio.module.scss';

const SearchRadio = (prop, ref) => {
  const [item, setItem] = useState(prop.item);

  useEffect(() => {
    setItem({ ...item, value: item.data[0].code });
  }, []);

  useImperativeHandle(ref, () => ({
    getData() {
      return item;
    },
  }));

  return (
    <div className={Styles.search_radio}>
      {item.data.map((l, i) => {
        return (
          <div className={Styles.radio_area} key={i}>
            <input
              id={`${item.fieldName}_${l.code}`}
              name={item.fieldName}
              type="radio"
              value={l.code}
              checked={item.value === l.code}
              onChange={(e) => {
                setItem({ ...item, value: e.target.defaultValue });
              }}
            />
            <div className={Styles.input_radio}></div>
            <label
              htmlFor={`${item.fieldName}_${l.code}`}
              className={Styles.radio_label}
            >
              {l.name}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default forwardRef(SearchRadio);
