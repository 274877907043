import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { navigate } from '../../helper/HistoryHelper';
import { Badge, Button, Col, FormGroup } from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import TitlePanel from '../../components/layout/TitlePanel';
import FrameWrap from '../../components/layout/FrameWrap';
import Avatar from '../../components/avatar/Avatar';

const chatBotDataGridStore = new DataGridStore({
  fetchUrl: `/agent/bot/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC),
});

@observer
class ChatbotManageListView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    chatBotDataGridStore.refresh();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pathname &&
      prevProps.pathname.startsWith('/main/setting/manage/chat_bot/detail') &&
      this.props.pathname === '/main/setting/manage/chat_bot/list'
    ) {
      chatBotDataGridStore.fetch();
    }
  }

  componentWillUnmount() {
    chatBotDataGridStore.searchCondition.clear();
  }

  render() {
    return (
      <FrameWrap>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'챗봇 설정 - 챗봇'} />
              <div>
                <Button
                  variant={'outline-primary'}
                  onClick={() => {
                    navigate(`/main/setting/manage/chat_bot/new`);
                  }}
                >
                  + 챗봇 추가
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel className={'flex-default'}>
          <DataGrid
            store={chatBotDataGridStore}
            keyColumn={`id`}
            columns={[
              <Header
                id={`index`}
                key={`index`}
                name={`번호`}
                width={{ min: 40, current: 50, max: 60 }}
              />,
              <Header
                id={`id`}
                key={`id`}
                name={`ID`}
                width={{ min: 100, current: 125, max: 275 }}
                printFunction={(id) => (
                  <Badge pill bg="secondary" className="IdBadge">
                    {id}
                  </Badge>
                )}
              />,
              <Header
                id={`name`}
                key={`name`}
                name={`이름`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(chatbotName, chatbot) => {
                  return (
                    <div className={'w-100 d-flex flex-row gap-2'}>
                      <Avatar
                        type={'chatbot'}
                        seed={chatbot.id}
                        width={'1.5rem'}
                        src={chatbot?.avatar?.fullUrl}
                      />
                      <span>{chatbotName}</span>
                    </div>
                  );
                }}
              />,
              <Header
                id={`createdAt`}
                key={`createdAt`}
                name={`생성일시`}
                sortable={true}
                width={{ min: 100, current: 180, max: 250 }}
                type="datetime"
              />,
              <Header
                id={`creator`}
                key={`creator`}
                name={`생성자`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(creator) => creator?.name}
              />,
              <Header
                id={`updatedAt`}
                key={`updatedAt`}
                name={`수정일시`}
                sortable={true}
                width={{ min: 100, current: 180, max: 250 }}
                type="datetime"
              />,
              <Header
                id={`updater`}
                key={`updater`}
                name={`수정자`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(updater) => updater?.name}
              />,
            ]}
            onRowClick={(rowData) => {
              navigate(`/main/setting/manage/chat_bot/detail/${rowData.id}`);
            }}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export { ChatbotManageListView as default, chatBotDataGridStore };
