import React from 'react';
import Character from "./Character.js";
import FaqSvg from '../../../../../assets/icon/faq.svg'
import {Image} from "react-bootstrap";
import Styles from './GreetingMessage.module.scss';
import Message from "../Message.js";
import MessageContent from "../MessageContent.js";

class GreetingMessage extends React.Component {
    render() {
        let {message, store} = this.props;
        return (
            <div className={`d-flex justify-content-center flex-column align-items-center`} >
                <div className={`position-relative`}>
                    <div className={`d-flex justify-content-center align-items-center`} style={{height:166}}>
                        <Character/>
                    </div>
                    <br/>
                    <div className={Styles.Faq}>
                        <Image src={FaqSvg}/>자주하는 질문입니다.
                    </div>
                    <div style={{width:320}}>
                        <Message>
                            <MessageContent content={message.content}
                                            store={store}
                                            isIncoming={true}
                            />
                        </Message>
                    </div>
                </div>
            </div>
        )
    }
}

export {GreetingMessage};