import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import {
  ScrollTabNavItem,
  ChannelList,
  Chat,
  TicketList,
  ScrollTabContainer,
  ScrollTabContent,
  ScrollTabNav,
  ScrollTabPanel,
  TICKET_STATUS,
} from '@qbot-chat/qbot-uikit/chat';
import {
  channelStore,
  chatStore,
  historyChannelStore,
  ticketStore,
  historyChatStore,
} from '../store/AdminChatStore';
import { ArrowLeft2 } from 'iconsax-react';
import { CustomButton } from '@qbot-chat/qbot-uikit/component';
import TicketDetailView from './ticket/TicketDetailView';
import { observable, makeObservable, action, reaction } from 'mobx';
import Styles from './ChatView.module.scss';
import { Accordion, Badge as RBBadge } from 'react-bootstrap';
import Badge from '../components/badge/Badge';
import { BRAND_COLOR } from '../const/AppConst';
import OrgDialog from '../components/dialog/OrgDialog';
import TransferDialog from '../components/dialog/TransferDialog';
import CloseConfirmDialog from '../components/dialog/CloseConfirmDialog';
import { CustomHeader } from '../components/chat/CustomHeader';
import axios from 'axios';
import QPlayer from '../components/player/QPlayer';
import { Style } from '@mui/icons-material';
import appStore from '../store/AppStore';
import { Flex } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import DragLayerPopup from '../components/layout/DragLayerPopup';

const modalRoot = document.getElementById('modal');
const DragLayerPortal = ({ children }) =>
  ReactDOM.createPortal(children, modalRoot);

class ChatViewStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  templateMessage = '';

  @observable
  size = [];

  @observable
  currentHistoryTicket = null;

  @observable
  isFindNotClosed = false;

  // @observable
  // isNoticesLodding = true;

  // @observable
  // noticesItems = [];

  @action
  setCurrentHistoryTicket(currentHistoryTicket) {
    this.currentHistoryTicket = currentHistoryTicket;
  }

  subscribeChangeSize() {
    reaction(
      () => chatViewStore.currentHistoryTicket,
      (currentHistoryTicket) => {
        if (currentHistoryTicket) {
          chatViewStore.size = ['60%', '40%'];
        } else {
          chatViewStore.size = ['600px', '100%'];
        }
      },
    );
  }

  fetchFindNotClosed() {
    axios.post(`/ticket/find/not/closed`, {}).then(({ data }) => {
      this.isFindNotClosed = data;
    });
  }

  getNoticeItems() {
    axios
      .get('/notice/active/list/' + 10)
      .then((res) => {
        this.noticesItems = res.data;
      })
      .finally(() => {
        this.isNoticesLodding = false;
      });
  }
}

const chatViewStore = new ChatViewStore();

@observer
class ChatView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      isAccordionOpen: true,
      activeKey: 'ACTIVE',
    };
  }
  scenarioList = [];
  scenarioCompleted = [];
  currentStatus = '';

  componentDidMount() {
    chatViewStore.subscribeChangeSize();
    chatViewStore.fetchFindNotClosed();
    chatViewStore.getNoticeItems();
    this.clear();
  }

  clear() {
    chatStore.setChannel(null);
    chatViewStore.setCurrentHistoryTicket(null);
    historyChannelStore.clear();
  }

  handleCurrentTextareaChange = (text) => {
    chatViewStore.templateMessage = text;
  };

  render() {
    // let { setting } = settingStore;
    // const { isNoticesLodding, noticesItems } = chatViewStore;
    return (
      <Flex flex={1}>
        <div className={Styles.LeftArea}>
          <div className={Styles.TicketWrapper}>
            {appStore.chatReady && (
              <div className={Styles.TicketList}>
                <ScrollTabContainer defaultActiveKey={'ACTIVE'}>
                  <>
                    <ScrollTabNav
                      activeKey={this.state.activeKey}
                      className={Styles.TicketTabs}
                      onClick={(activeKey) => {
                        if (activeKey == TICKET_STATUS.CLOSED)
                          channelStore.refresh();
                        this.setState({ activeKey });
                      }}
                    >
                      <ScrollTabNavItem
                        eventKey={'ACTIVE'}
                        isBlink={ticketStore.tickets.find(
                          (ticket) => !ticket.isConfirm,
                        )}
                      >
                        <Flex gap={5}>진행중 {ticketStore.tickets.length}</Flex>
                      </ScrollTabNavItem>
                      <ScrollTabNavItem
                        eventKey={'CLOSED'}
                        isBlink={!chatViewStore.isFindNotClosed}
                        blinkClassName={Styles.CloseBlink}
                      >
                        종료됨
                      </ScrollTabNavItem>
                    </ScrollTabNav>

                    <ScrollTabContent>
                      <ScrollTabPanel
                        eventKey={'ACTIVE'}
                        activeKey={this.state.activeKey}
                      >
                        <TicketList
                          store={ticketStore}
                          activeChannelId={chatStore?.channelId}
                          isAgent={true}
                          onChannelClick={(channel) => {
                            console.log('------시나리오 정보 가져오기------');
                            this.scenarioList = [
                              { code: 'D0005', value: '통화가능' },
                              { code: 'D0006', value: '연체사실' },
                              { code: 'D0007', value: '안내문발송' },
                              { code: 'D0012', value: '주소확인' },
                            ];
                            this.scenarioCompleted = ['D0005', 'D0006'];
                            this.currentStatus = 'D0007';
                            chatStore.setChannel(channel);
                            chatViewStore.setCurrentHistoryTicket(undefined);
                            historyChatStore.setChannel(undefined);
                            historyChannelStore.searchCondition.set(
                              `customerId`,
                              channel?.customer?.id,
                            );
                            historyChannelStore.refresh();
                          }}
                          onCloseCallback={(channel) => {
                            if (chatStore?.channelId == channel.id) {
                              chatStore.setChannel(null);
                              chatViewStore.setCurrentHistoryTicket(null);
                              historyChatStore.setChannel(null);
                              historyChannelStore.clear();
                            }
                          }}
                          renderLabel={(ticket) => {
                            let properties = ticket.properties;
                            if (properties) {
                              return (
                                <Badge
                                  color={BRAND_COLOR[properties['brand-id']]}
                                  location={properties['branch-name']}
                                />
                              );
                            } else {
                              return;
                            }
                          }}
                        />
                      </ScrollTabPanel>
                      <ScrollTabPanel
                        eventKey={'CLOSED'}
                        activeKey={this.state.activeKey}
                      >
                        <ChannelList
                          isKeyword
                          store={channelStore}
                          activeChannelId={chatStore?.channelId}
                          onChannelClick={(channel) => {
                            chatStore.setChannel(channel);
                            chatViewStore.setCurrentHistoryTicket(undefined);
                            historyChatStore.setChannel(undefined);
                            historyChannelStore.searchCondition.set(
                              `customerId`,
                              channel?.customer?.id,
                            );
                            historyChannelStore.refresh();
                          }}
                          renderLabel={(ticket) => {
                            let properties = ticket.properties;
                            if (properties) {
                              return (
                                <Badge
                                  color={BRAND_COLOR[properties['brand-id']]}
                                  location={properties['branch-name']}
                                />
                              );
                            } else {
                              return;
                            }
                          }}
                        />
                      </ScrollTabPanel>
                    </ScrollTabContent>
                  </>
                </ScrollTabContainer>
              </div>
            )}
            {appStore.chatReady &&
              historyChannelStore &&
              historyChannelStore?.channels?.length > 0 && (
                <Accordion
                  defaultActiveKey="0"
                  className={Styles.Accordion}
                  onSelect={(key) =>
                    this.setState({ isAccordionOpen: key === '0' })
                  }
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className={`overflow-hidden`}>
                      <div className={Styles.HistoryHeader}>
                        <span>최근 상담 이력</span>
                        <DownOutlined
                          className={classNames(
                            Styles.Icon,
                            this.state.isAccordionOpen && Styles.Open,
                          )}
                        />
                      </div>
                    </Accordion.Header>
                    <Accordion.Body
                      className={`p-0 flex-default d-flex w-100`}
                      style={{ height: '400px' }}
                    >
                      <div className={Styles.HistoryContent}>
                        <ScrollTabContent>
                          <ScrollTabPanel>
                            <ChannelList
                              isSummary
                              isKeyword
                              store={historyChannelStore}
                              activeChannelId={historyChatStore?.channelId}
                              onChannelClick={(channel) => {
                                chatViewStore.currentHistoryTicket = channel;
                                historyChatStore.setChannel(channel);
                              }}
                              renderLabel={(ticket) => {
                                let properties = ticket.properties;
                                if (properties) {
                                  return (
                                    <Badge
                                      color={
                                        BRAND_COLOR[properties['brand-id']]
                                      }
                                      location={properties['branch-name']}
                                    />
                                  );
                                } else {
                                  return;
                                }
                              }}
                            />
                          </ScrollTabPanel>
                        </ScrollTabContent>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
          </div>
          {/* {(isNoticesLodding || !!noticesItems?.length) && (
            <div className={Styles.NoticeWrapper}>
              <NoticeCarousel
                title="공지사항"
                className={Styles.Notice}
                items={noticesItems}
                loading={isNoticesLodding}
                onClickItemTitle={(item) =>
                  navigate(PATH.notice.read.name + `/${item.id}`)
                }
              />
            </div>
          )} */}
        </div>
        <div className={Styles.ChatView}>
          <Chat
            store={chatStore}
            ref={chatStore.chatRef}
            isImageSendAble={true}
            isImageSendMsg={true}
            renderCustomHeader={(store) => <CustomHeader store={store} />}
            // renderCustomHeader={(store) => (
            //   <>
            //     {!!chatStore.channel?.customer?.name &&
            //       chatStore.channel?.customer.name.startsWith('010') && (
            //         <div className={Style.ChatViewPlayerWrap}>
            //           <QPlayer store={chatStore} />
            //         </div>
            //       )}
            //     <CustomHeader store={store} />
            //   </>
            // )}
            renderSubHeader={() => (
              <>
                {!!chatStore.channel?.customer?.name &&
                  chatStore.channel?.customer.name.startsWith('010') &&
                  chatStore.channel.status === TICKET_STATUS.CLOSED && (
                    <div className={Style.ChatViewPlayerWrap}>
                      <QPlayer store={chatStore} />
                    </div>
                  )}
              </>
            )}
            // renderSubHeader={(ticket) => {
            //   return (
            //     <CloseCollapse
            //       name={'current'}
            //       ticket={ticket}
            //       onSave={(ticket) => {
            //         chatViewStore.fetchFindNotClosed();
            //         channelStore.load();
            //         historyChannelStore.load();
            //         chatStore.channel = ticket;
            //         if (ticket.id == historyChatStore.channel?.id) {
            //           historyChatStore.channel = ticket;
            //         }
            //       }}
            //     />
            //   );
            // }}
            backButton={
              <CustomButton
                onClick={() => {
                  this.clear();
                  // chatStore.setChannel(null)
                }}
              >
                <ArrowLeft2 size={24} />
              </CustomButton>
            }
            onCloseCallback={(channel) => {
              ticketStore.deleteTicket(channel.id);
              chatStore.setChannel(null);
              chatViewStore.setCurrentHistoryTicket(null);
              historyChatStore.setChannel(null);
              historyChannelStore.clear();
            }}
            callbackLeave={(channelId, callback) => {
              channelStore.leave(channelId).then(() => callback && callback());
            }}
          />
        </div>
        {/* {this.scenarioList.length > 0 && (
          <div className={Styles.ScenarioArea}>
            <div className={Styles.ScenarioTop}>시나리오 진행</div>
            <div className={Styles.ScenarioWrap}>
              {this.scenarioList.map((e) => {
                return (
                  <div
                    className={`${Styles.ScenarioItem} ${e.code === this.currentStatus ? Styles.Current : this.scenarioCompleted.filter((j) => e.code === j).length === 0 ? Styles.Yet : ''}`}
                  >
                    <span className={Styles.Label}>{e.code}</span>
                    <span className={Styles.Text}>{e.value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )} */}

        <div className={Styles.DetailContainer}>
          {chatStore.channel && (
            <TicketDetailView
              ticket={chatStore.channel}
              historyTickets={[]}
              currentTextareaChange={this.handleCurrentTextareaChange}
              store={chatStore}
            />
          )}
        </div>
        <TransferDialog ticket={chatStore.channel} />
        <CloseConfirmDialog />
        <OrgDialog />
        <DragLayerPortal>
          {chatViewStore.currentHistoryTicket && (
            <DragLayerPopup
              title={`최근 상담`}
              maxHeight={700}
              maxWidth={400}
              onClose={() => {
                chatViewStore.setCurrentHistoryTicket(null);
                historyChatStore.setChannel(null);
              }}
            >
              <Chat
                store={historyChatStore}
                ref={historyChatStore.chatRef}
                contentClassName={Styles.CustomContent}
                // renderCustomHeader={(store) => {
                //   let { channel } = store;
                //   let { properties, customer } = channel;
                //   return (
                //     <>
                //       <div className={Styles.ChatHeader}>
                //         <div className={Styles.NameContainer}>
                //           {properties && (
                //             <div className={Styles.Badge}>
                //               <Badge
                //                 color={BRAND_COLOR[properties['brand-id']]}
                //                 location={properties['branch-name']}
                //               />
                //             </div>
                //           )}
                //           <div className={Styles.Name}>{customer?.name}</div>
                //         </div>
                //       </div>
                //       {channel?.category && (
                //         <>
                //           <div>
                //             <RBBadge bg={`secondary`}>종료타입</RBBadge>
                //             &nbsp;
                //             {channel.category.closedType === 'CLOSE_DRAFT'
                //               ? '1차 종결'
                //               : '점포 확인 후 종결'}
                //           </div>
                //           <div>
                //             <RBBadge bg={`secondary`}>카테고리</RBBadge>
                //             {appStore.getCategoriesBreadCrumb(
                //               channel.category.categoryId,
                //             )}
                //           </div>
                //           <div>
                //             <RBBadge bg={`secondary`}>관리부서</RBBadge>
                //             &nbsp;
                //             {
                //               appStore.orgs.find(
                //                 (org) =>
                //                   org.org_id ===
                //                   parseInt(channel.category.orgId),
                //               )?.full_org_nm
                //             }
                //           </div>
                //         </>
                //       )}
                //     </>
                //   );
                // }}
                // renderSubHeader={(ticket) => {
                //   return (
                //     <CloseCollapse
                //       name={'history'}
                //       ticket={ticket}
                //       onSave={(ticket) => {
                //         chatViewStore.fetchFindNotClosed();
                //         channelStore.load();
                //         historyChannelStore.load();
                //         historyChatStore.channel = ticket;
                //         if (ticket.id == chatStore.channelId) {
                //           chatStore.channel = ticket;
                //         }
                //       }}
                //     />
                //   );
                // }}
                // backButton={
                //   <CustomButton
                //     onClick={() => {
                //       chatViewStore.setCurrentHistoryTicket(null);
                //       historyChatStore.setChannel(null);
                //     }}
                //   >
                //     <ArrowLeft2 size={28} />
                //   </CustomButton>
                // }
                callbackLeave={(channelId, callback) => {
                  historyChannelStore
                    .leave(channelId)
                    .then(() => callback && callback());
                }}
              />
            </DragLayerPopup>
          )}
        </DragLayerPortal>
      </Flex>
    );
  }
}

export { ChatView as default, chatViewStore };
