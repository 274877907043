import React from 'react';
import Styles from './IncomingMessage.module.scss';
import moment from 'moment';
import { observer } from 'mobx-react';
import Message from './Message.js';
import MessageContent from './MessageContent.js';
import Avatar from '../avatar/Avatar.js';
import { CONTENT_TYPE } from '../../../const/ChatConst.js';
import axios from 'axios';
import { audioStore } from '../../../store/AudioStore.js';
import { PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons';
import { Button, Flex } from 'antd';

const playAxios = axios.create({
  baseURL: '/play',
  headers: {
    'Content-Type': 'application/octet-stream',
  },
});

@observer
class IncomingMessage extends React.Component {
  audioMessage;

  replyMessage(message) {
    let { store } = this.props;
    store.replyMessage = message;
  }

  toLittleEndianBytes = (value, bytes) => {
    let result = '';
    for (let i = 0; i < bytes; i++) {
      result += String.fromCharCode((value >> (i * 8)) & 0xff);
    }
    return result;
  };

  async playChunk(message) {
    if (!message.audioChunk) return;
    const [start, end] = message.audioChunk.split(':');

    try {
      const res = await playAxios.get(
        `${message.channelId}?startT=${start}&endT=${end}`,
        { responseType: 'blob' },
      );
      audioStore.play(res.data);
    } catch {
      (e) => console.error(e);
    }
  }

  render() {
    let { message, isSameUser, store } = this.props;
    this.audioMessage = message;
    let {
      sender,
      createdAt,
      focus = false,
      notConfirmCount = 0,
      replyMessage,
      content,
    } = message;

    if (content.type === CONTENT_TYPE.TEXT && !content.value.trim()) return;
    return (
      <div
        className={`${Styles.Container} ${focus ? Styles.Focus : ''}`}
        onAnimationEnd={() => {
          store.messageMap.set(message.id, { ...message, focus: false });
        }}
      >
        <div className={Styles.Avatar}>
          {!isSameUser && (
            <Avatar
              className={Styles.AvatarImg}
              type={sender?.type}
              src={sender?.avatar?.fullUrl}
              seed={sender?.id}
            />
          )}
        </div>
        <div className={Styles.Content}>
          {!isSameUser && <div className={Styles.Sender}>{sender?.name}</div>}
          <div className={Styles.ContentInner}>
            <Message>
              <MessageContent
                content={content}
                store={store}
                messageKey={message.id}
                isIncoming={true}
                replyMessage={replyMessage}
              />
            </Message>
            {store.channel.status !== 'CLOSED' && (
              <div className={Styles.MessageOption}>
                {notConfirmCount > 0 && (
                  <div className={Styles.NotConfirmCount}>
                    {notConfirmCount}
                  </div>
                )}
                {message?.audioChunk && (
                  <div className={Styles.Button}>
                    <Button
                      className={Styles.Play}
                      onClick={() => this.playChunk(message)}
                      disabled={audioStore.isPlaying}
                      icon={<PlayCircleFilled />}
                      size="small"
                      type="text"
                    />
                    <Button
                      className={Styles.Stop}
                      onClick={() => audioStore.stop()}
                      disabled={!audioStore.isPlaying}
                      icon={<PauseCircleFilled />}
                      size="small"
                      type="text"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={Styles.Date}>{moment(createdAt).format('LT')}</div>
        </div>
      </div>
    );
  }
}
// 답장버튼
//               <Back
//                 className={Styles.Icon}
//                 onClick={() => {
//                   this.replyMessage(message);
//                 }}
//               />
export default IncomingMessage;
