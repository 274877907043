import React from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import appStore from '../store/AppStore';
import { HISTORY } from '../helper/HistoryHelper';
import ChatView from './ChatView';
import { ChannelDropDown, Chat, USER_TYPE } from '@qbot-chat/qbot-uikit/chat';
import { channelStore, chatStore } from '../store/AdminChatStore';
//import { onMessageListener, requestForToken } from "../firebase";
import Styles from './MainViewFrame.module.scss';
import DragLayerPopup from '../components/layout/DragLayerPopup';
import QuickMsgModal from './quickmsg/modal/QuickMsgModal';
import UploadDialog, { uploadStore } from '../components/dialog/UploadDialog';
import TicketManageFrame from './ticket/TicketManageFrame';
import AgentInfoChangeView from './AgentInfoChangeView';
import AgentPasswordChangeView from './AgentPasswordChangeView';
import QuickMsgFrame from './quickmsg/QuickMsgFrame';
import RoadShowView from './roadshow/RoadShowView';
import BranchMemoView from './memo/BranchMemoView';
import DashboardFrame from './dashboard/DashboardFrame';
import ReportView from './report/ReportView';
import Monitor from './monitor/Monitor';
import AgentMangeFrame from './agent/AgentManageFrame';
import TeamManageFrame from './team/TeamManageFrame';
import SettingManageFrame from './setting/SettingManageFrame';
import BotMessageTemplateManageFrame from './message_template/BotMessageTemplateManageFrame';
import MemberManageFrame from './member/MemberManageFrame';
import ScenarioFrame from './scenario/ScenarioFrame';
import CallFrame from './call/CallFrame';
import ObSettingFrame from './ob_setting/ObSettingFrame';
import VoiceRecordFrame from './voice_record/VoiceRecordFrame';
import StandardScenarioFrame from './standard_scenario/StandardScenarioFrame';
import StandardChatFrame from './standard_chat/StandardChatFrame';
import AnnotationFrame from './annotation/AnnotationFrame';
import NoticeFrame from './notice/NoticeFrame';
import { Flex, Layout } from 'antd';
import SideNav from '../components/layout/SideNav';
import { COMMON_ROUTER } from '../const/AppConst';
//피그마 디자인 적용
// import ChatMainView from './chat/ChatMainView';

const modalRoot = document.getElementById('modal');
const DragLayerPortal = ({ children }) =>
  ReactDOM.createPortal(children, modalRoot);

const redirectObj = {
  [USER_TYPE.AGENT]: <Redirect exact from="/main" to="/main/chat" />,
  [USER_TYPE.AGENT_LEADER]: <Redirect exact from="/main" to="/main/chat" />,
  [USER_TYPE.ADMIN]: <Redirect exact from="/main" to="/main/dashboard" />,
  [USER_TYPE.SUPER]: <Redirect exact from="/main" to="/main/dashboard" />,
};

@observer
class MainViewFrame extends React.Component {
  componentDidMount() {
    // let store = this;
    // if (
    //   appStore.isLogin &&
    //   [USER_TYPE.AGENT, USER_TYPE.AGENT_LEADER].includes(appStore.type)
    // ) {
    //   requestForToken &&
    //     requestForToken()
    //       .then((currentToken) => {
    //         if (currentToken) {
    //           console.log("current token for client: ", currentToken);
    //           axios.post("/user/registration-push-token", {
    //             token: currentToken,
    //             type: "WEB",
    //           });
    //         } else {
    //           console.log(
    //             "No registration token available. Request permission to generate one."
    //           );
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("An error occurred while retrieving token. ", err);
    //       });
    //   onMessageListener &&
    //     onMessageListener()
    //       .then((payload) => {})
    //       .catch((err) => console.log("failed: ", err));
    // }

    // appStore.loadBranches();
    // appStore.loadOrgTree();
    // appStore.loadCategories();
    appStore.loadBranches();
    appStore.loadBrands();
  }

  componentDidUpdate() {
    if (appStore.currentPath != HISTORY.location.pathname) {
      appStore.currentPath = HISTORY.location.pathname;
    }
  }

  componentWillUnmount() {}

  MENU_ROUTE = {
    // ADMIN: (
    //   <Route path="/main/admin" key="admin" component={AdminManageFrame} />
    // ),
    // COMPANY: (
    //   <Route path="/main/company" key="company" component={CompanyFrame} />
    // ),
    //피그마 디자인 적용
    // CHAT: <Route path="/main/chat" key="chat" component={ChatMainView} />,
    CHAT: <Route path="/main/chat" key="chat" component={ChatView} />,
    TICKET: (
      <Route path="/main/ticket" key="ticket" component={TicketManageFrame} />
    ),
    PROFILE: (
      <Route
        path="/main/profile"
        key="profile"
        component={AgentInfoChangeView}
      />
    ),
    PASSWORD: (
      <Route
        path="/main/password"
        key="password"
        component={AgentPasswordChangeView}
      />
    ),
    QUICK: (
      <Route path="/main/quickmsg" key="quick" component={QuickMsgFrame} />
    ),
    ROAD: <Route path="/main/roadshow" key="road" component={RoadShowView} />,
    BRANCH: (
      <Route path="/main/branch-memo" key="branch" component={BranchMemoView} />
    ),
    DASH: (
      <Route path="/main/dashboard" key="dash" component={DashboardFrame} />
    ),
    REPORT: <Route path="/main/report" key="report" component={ReportView} />,
    MONITOR: <Route path="/main/monitor" key="monitor" component={Monitor} />,
    TEAM_AGENT: (
      <Route
        path="/main/member"
        key="teamAgent"
        component={MemberManageFrame}
      />
    ),
    AGENT: <Route path="/main/agent" key="agent" component={AgentMangeFrame} />,
    TEAM: <Route path="/main/team" key="team" component={TeamManageFrame} />,
    SETTING: (
      <Route
        path="/main/setting"
        key="setting"
        component={SettingManageFrame}
      />
    ),
    BOT_MESSAGE: (
      <Route
        path="/main/bot"
        key="botMessage"
        component={BotMessageTemplateManageFrame}
      />
    ),

    SCENARIO: (
      <Route path="/main/scenario" key="scenario" component={ScenarioFrame} />
    ),
    OUTBOUND_CALL: (
      <Route path="/main/call" key="outboundCall" component={CallFrame} />
    ),
    OUTBOUND_SETTING: (
      <Route
        path="/main/ob-setting"
        key="outboundSetting"
        component={ObSettingFrame}
      />
    ),
    VOICE_RECORD: (
      <Route
        path="/main/voice-record"
        key="voiceRecord"
        component={VoiceRecordFrame}
      />
    ),
    STANDARD_SCENARIO: (
      <Route
        path="/main/standard-scenario"
        key="standareScenario"
        component={StandardScenarioFrame}
      />
    ),
    STANDARD_CHAT: (
      <Route
        path="/main/standard-chat"
        key="standardChat"
        component={StandardChatFrame}
      />
    ),
    ANNOTATION: (
      <Route
        path="/main/annotation"
        key="annotation"
        component={AnnotationFrame}
      />
    ),
    NOTICE: <Route path="/main/notice" key="notice" component={NoticeFrame} />,
  };

  render() {
    if (appStore.isSessionChecked && !appStore.isLogin)
      return <Redirect to={`/`} />;

    return (
      <Layout style={{ width: '100%' }}>
        <SideNav />
        {/* {!isAgent && <StatusBar />} */}
        <Flex flex={1} className={Styles.Content}>
          {appStore?.type && appStore.menu && (
            <Switch>
              {[...COMMON_ROUTER, ...appStore.menu].map(
                (menu) => this.MENU_ROUTE[menu],
              )}
              {redirectObj[appStore.type]}
            </Switch>
          )}
        </Flex>

        <ChannelDropDown channelStore={channelStore} chatStore={chatStore} />
        <DragLayerPortal>
          {chatStore.channelId &&
            !appStore.currentPath.startsWith(`/main/chat`) && (
              <DragLayerPopup
                title={'진행중인 상담'}
                maxHeight={700}
                maxWidth={400}
                onClose={() => {
                  chatStore.setChannel(null);
                }}
              >
                <Chat
                  store={chatStore}
                  ref={chatStore.chatRef}
                  isImageSendAble={true}
                  isImageSendMsg={true}
                  // renderCustomHeader={(store) => <CustomHeader store={store} />}
                  onCloseCallback={() => {
                    chatStore.setChannel(null);
                  }}
                />
              </DragLayerPopup>
            )}
        </DragLayerPortal>
        {appStore.chatReady && <QuickMsgModal store={chatStore} />}
        <UploadDialog store={uploadStore} />
      </Layout>
    );
  }
}

export default MainViewFrame;
