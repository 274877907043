import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  Background,
  Controls,
  useReactFlow,
  ReactFlowProvider,
  Panel,
} from 'reactflow';
import {
  findDuplicateNodes,
  flowIdExtract,
  flowUid,
  getDepthNodes,
  getLayoutedElements,
  getStartNodes,
  nodesLoader,
  noedsSave,
} from './FlowOptions';
import 'reactflow/dist/style.css';
import './flowStyle.css';
import Styles from './NodeFlow.module.scss';
import CustomEdge from './CustomEdge';
import CustomNode from './CustomNode';
import FlowSideList from './FlowSideList';
import { BiReset } from 'react-icons/bi';
import { TbSortDescending2 } from 'react-icons/tb';

const nodeTypes = {
  custom: CustomNode,
};
const edgeTypes = {
  custom: CustomEdge,
};

const reactFlowType = {
  nodeTypes,
  edgeTypes,
};

const LayoutFlow = forwardRef(({ data, listApi, ...props }, ref) => {
  const [alignType, setAlignType] = useState('');
  const [validText, setValidText] = useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState(data.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(data.edges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const isHorizontal = alignType === 'LR';
  const { fitView } = useReactFlow();

  useImperativeHandle(ref, () => ({
    onSaveNode,
  }));

  useEffect(() => {
    onReset();
  }, [data]);

  const onValidText = useCallback((text) => {
    setValidText(text);
    setTimeout(() => {
      setValidText('');
    }, 2000);
  }, []);

  const onLayout = useCallback(
    async (nodeData, edesData, type) => {
      const layoutedNodes = getLayoutedElements(nodeData, edesData, type);
      await setNodes([...layoutedNodes]);
      await setEdges([...edesData]);
      fitView();
    },
    [data, nodes, edges, fitView],
  );

  const onReset = useCallback(async () => {
    onLayout(data.nodes, data.edges, '');
    setAlignType('');
    setTimeout(() => fitView(), 100);
  }, [data, fitView]);

  const onToggleLayout = useCallback(() => {
    const type = isHorizontal ? 'TB' : 'LR';
    onLayout(nodes, edges, type);
    setAlignType(type);
  }, [nodes, edges, isHorizontal]);

  const isValidConnection = useCallback(
    (connection) => {
      const { source: currentId, target: targetId } = connection;
      const currentNode = nodes.find((node) => node.id === currentId);
      const targetNode = nodes.find((node) => node.id === targetId);
      const {
        scriptType: currentType,
        depth: currentDepth,
        nextIds: currentNextIds,
      } = currentNode.data;
      const { scriptType: targetType, depth: targetDepth } = targetNode.data;
      if (currentType === targetType) {
        setValidText('같은 타입은 연결할 수 없습니다.');
        return false;
      }

      if (targetType === 'E' && ['S', 'Q'].includes(currentType)) {
        setValidText('"시작" 또는 "질문"은 "종료"할 수 없습니다.');
        return false;
      }

      if (currentDepth > targetDepth && targetDepth !== 1) {
        setValidText('이전 노드에 연결할 수 없습니다.');
        return false;
      }

      if (Math.abs(currentDepth - targetDepth) >= 2 && targetDepth !== 1) {
        setValidText('2뎁스 이상 차이날 수 없습니다.');
        return false;
      }

      if (
        !!currentNextIds.find(
          (id) => flowIdExtract(id) === flowIdExtract(targetId),
        )
      ) {
        setValidText('같은 메세지(ID)는 연결할 수 없습니다.');
        return false;
      }

      const currentEdgeTypes = nodes
        .filter((node) => currentNextIds.includes(node.id))
        .map((v) => v.data.scriptType);
      if (
        (targetType !== 'A' && currentNextIds.length > 0) ||
        currentEdgeTypes.some((type) => type !== 'A')
      ) {
        setValidText('"답변" 외 두개 이상 연결할 수 없습니다.');
        return false;
      }

      setValidText('');
      return true;
    },
    [nodes],
  );

  const onConnecting = useCallback(() => {
    setValidText('');
  }, []);

  const onConnect = useCallback(
    (params) => {
      const newNodes = nodes.map((item) => {
        if (item.id === params.source) {
          return {
            ...item,
            data: {
              ...item.data,
              nextIds: [...item.data.nextIds, params.target],
            },
          };
        }
        if (item.id === params.target) {
          return {
            ...item,
            data: {
              ...item.data,
              prevIds: [...item.data.prevIds, params.source],
            },
          };
        }
        return { ...item, data: { ...item.data } };
      });
      const newEdges = addEdge(
        { ...params, type: 'custom', animated: true },
        edges,
      );

      const dethNodes = getDepthNodes(newNodes, newEdges);
      //1뎁스 무한루프 예외처리
      if (!dethNodes) return;
      setNodes(dethNodes);
      setEdges(newEdges);
      setValidText('');
    },
    [nodes, edges],
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const data = JSON.parse(
        event.dataTransfer.getData('application/reactflow'),
      );
      if (typeof data === 'undefined' || !data) return;
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const { content, scriptType, intentId: currentIntentId } = data;
      const newNode = {
        id: flowUid([currentIntentId, 'new' + +new Date()]),
        position,
        data: {
          content,
          scriptType,
          currentId: currentIntentId,
          prevIds: [],
          nextIds: [],
          depth: 1,
          isNew: true,
        },
        type: 'custom',
        targetPosition: isHorizontal ? 'left' : 'top',
        sourcePosition: isHorizontal ? 'right' : 'bottom',
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, isHorizontal],
  );

  const onSaveNode = useCallback(() => {
    if (!reactFlowInstance) return;
    const { nodes: flowNodes, edges: flowEdges } = reactFlowInstance.toObject();
    const startNodes = getStartNodes(flowNodes, flowEdges);
    const { data: startData } = startNodes[0];
    const errorNodesText = startNodes
      .map((node) => node.data.currentId)
      .join(', ');

    if (startNodes.length > 1) {
      onValidText(`"시작" 데이터가 두개 이상입니다. [${errorNodesText}]`);
      return false;
    }

    if (startData.scriptType !== 'S' && startData.scriptType !== 'Q') {
      onValidText(`"시작" 또는 "질문"으로 시작해야합니다. [${errorNodesText}]`);
      return false;
    }

    const duplicateNodes = findDuplicateNodes(flowNodes);
    if (duplicateNodes.length > 0) {
      onValidText(
        `같은 뎁스에 같은 "질문"은 두개 이상 존재할 수 없습니다. [${duplicateNodes.map((node) => node.data.currentId).join(', ')}]`,
      );
      return false;
    }

    return noedsSave(flowNodes);
  }, [reactFlowInstance, isHorizontal]);

  return (
    <div className={Styles.FlowWrapper}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onConnectStart={onConnecting}
        onConnectEnd={onConnecting}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={setReactFlowInstance}
        onDrop={onDrop}
        onDragOver={onDragOver}
        isValidConnection={isValidConnection}
        deleteKeyCode={null}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView
        minZoom={0.1}
        className="validationflow"
        {...reactFlowType}
        {...props}
      >
        {!!validText && (
          <Panel position="top-left">
            <p className={Styles.ValidText}>{validText}</p>
          </Panel>
        )}
        <Panel position="bottom-left" className={Styles.NodeBtnWrap}>
          <button className={Styles.NodeBtn} onClick={onReset}>
            <BiReset />
          </button>
          <button className={Styles.NodeBtn} onClick={onToggleLayout}>
            <TbSortDescending2
              style={{ transform: `rotate(${isHorizontal ? '0' : '-90deg'})` }}
            />
          </button>
        </Panel>
        <Background variant="dots" gap={12} size={1} />
        <Controls />
      </ReactFlow>

      {listApi && <FlowSideList listApi={listApi} />}
    </div>
  );
});

const NodeFlow = ({ nodesData, listApi }, ref) => {
  const { nodes, edges } = nodesLoader(nodesData);

  return (
    <ReactFlowProvider>
      <LayoutFlow
        data={{ nodes, edges }}
        listApi={listApi}
        ref={ref}
      ></LayoutFlow>
    </ReactFlowProvider>
  );
};

export default forwardRef(NodeFlow);
