import React, { useEffect, useRef, useState } from 'react';
import FrameWrap from '../../components/layout/FrameWrap';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import { Button, Col, Form, InputGroup, FormGroup, Row } from 'react-bootstrap';
import { goBack, navigateReplace } from '../../helper/HistoryHelper';
import Panel from '../../components/layout/Panel';
import { FormStore, Input, Validation } from '../../components/validation';
import Styles from './ScenarioView.module.scss';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit';
import { Warning2 } from 'iconsax-react';
import NodeFlow from '../../components/flow/NodeFlow';

const form = new FormStore();
const checkSid = /^[a-zA-Z0-9]{4,12}$/;

const ScenarioDetailView = (props) => {
  const sid = props.match?.params.id;
  const [isUsed, setIsUsed] = useState(false);
  const [isSidChange, setIsSidChange] = useState(false);
  const [isSidChecked, setIsSidChecked] = useState(true);
  const [nodesData, setNodesData] = useState([]);
  const nodeRef = useRef();

  useEffect(() => {
    if (!sid) return onReset();
    fetchDetail(sid);
    fetchScenario(sid);
  }, [sid]);

  function fetchDetail(id) {
    axios.post(`/scenario/detail/${id}`).then((res) => {
      setIsUsed(res.data.useYn === 'Y' ? true : false);
      form.setValue('sid', id);
      form.setValue('scenarioName', res.data.scenarioName);
      form.setValue('description', res.data.description);
    });
  }
  function fetchScenario(id) {
    axios.post(`/scenario/node/${id}`).then((res) => {
      setNodesData(res.data);
    });
  }

  function onReset() {
    form.clear();
    setIsUsed(false);
  }

  function onSave() {
    if (!isSidChecked && isSidChange)
      return NotificationManager.warning('중복확인을 해주세요!');
    if (!checkSid.test(form.valueMap.get('sid').trim()))
      return NotificationManager.warning(
        '시나리오 ID는 4~12글자 영어와 숫자만 입력하세요.',
      );
    const useYn = isUsed ? 'Y' : 'N';
    if (!!sid) {
      const nodeData = nodeRef.current.onSaveNode();
      if (nodeData === false) return;
      axios
        .post(`/scenario/update`, { currentSid: sid, useYn, ...form.values })
        .then((res) => {
          let id = res.data;
          axios.post(`/scenario/node/insert/${sid}`, nodeData).then(() => {
            NotificationManager.success('시나리오가 저장되었습니다.');
            fetchDetail(id);
            fetchScenario(id);
          });
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      axios
        .post(`/scenario/insert`, { useYn, ...form.values })
        .then((res) => {
          let id = res.data;
          NotificationManager.success('시나리오가 등록되었습니다.');
          navigateReplace(`/main/scenario/detail/${id}`);
        })
        .catch((err) => console.error(err));
    }
  }

  function onDelete() {
    if (!sid) return;
    confirmDialogStore.openRemove(<Warning2 />, `삭제 하시겠습니까?`, () => {
      axios
        .post(`/scenario/delete`, { sid })
        .then(() => {
          NotificationManager.success('상담사가 삭제되었습니다.');
          navigateReplace(`/main/scenario/list`);
        })
        .catch((e) => {
          console.error(e);
        });
    });
  }
  function onCheckedId() {
    const inputId = form.valueMap.get('sid');
    if (!checkSid.test(inputId))
      return NotificationManager.warning('4~12글자 영어와 숫자만 입력하세요.');

    if (inputId) {
      axios.post(`/scenario/exist`, { sid: inputId }).then((res) => {
        if (res.data) {
          NotificationManager.warning('이미 사용중인 시나리오 아이디 입니다.');
        } else {
          NotificationManager.success('사용 가능한 시나리오 아이디 입니다.');
          setIsSidChecked(true);
        }
      });
    }
  }
  function onChangeSid(e) {
    setIsSidChange(e.target.value !== (sid ?? ''));
    setIsSidChecked(false);
  }

  return (
    <FrameWrap className="w-100 overflow-y-auto">
      <TitlePanel>
        <Col className="justify-content-between align-items-center d-flex">
          <TitlePanelLabel label={!!sid ? '시나리오 수정' : '시나리오 등록'} />
          <div>
            {!!sid && (
              <Button variant="outline-danger" onClick={onDelete}>
                삭제
              </Button>
            )}
            <Button
              variant="outline-secondary"
              className="ms-4"
              onClick={goBack}
            >
              취소
            </Button>
            <Button variant="outline-primary" className="ms-1" onClick={onSave}>
              저장
            </Button>
          </div>
        </Col>
      </TitlePanel>
      <Panel className={Styles.Panel}>
        {!sid && (
          <Form>
            <FormGroup as={Row} className="mb-3">
              <Col md={2}>
                <div className={Styles.Label}>사용 중</div>
              </Col>
              <Col md={10} className="d-flex flex-row">
                <Form.Check
                  type="switch"
                  checked={isUsed}
                  onChange={(e) => setIsUsed(e.target.checked)}
                />
              </Col>
            </FormGroup>
            <FormGroup as={Row} className="mb-3">
              <Col md={2}>
                <div className={Styles.Label}>시나리오 ID</div>
              </Col>
              <Col md={4} className="d-flex flex-row">
                <InputGroup>
                  <Input
                    store={form}
                    name="sid"
                    placeholder="시나리오 ID"
                    validations={[
                      new Validation(/^.+$/, `시나리오 ID를 입력하세요.`),
                      new Validation(
                        checkSid,
                        '4~12글자 영어와 숫자만 입력하세요.',
                      ),
                    ]}
                    validOnBlur
                    style={
                      isSidChange
                        ? { borderRadius: '0.375rem 0 0 0.375rem' }
                        : {}
                    }
                    onChange={onChangeSid}
                    button={
                      isSidChange ? (
                        <Button variant="outline-primary" onClick={onCheckedId}>
                          중복체크
                        </Button>
                      ) : (
                        <></>
                      )
                    }
                  />
                </InputGroup>
              </Col>
              <Col md={2}>
                <div className={Styles.Label}>시나리오 명</div>
              </Col>
              <Col md={4} className="d-flex flex-row">
                <Input
                  store={form}
                  name="scenarioName"
                  placeholder="시나리오 명을 입력하세요."
                  validations={[
                    new Validation(/^.+$/, `시나리오 명을 입력하세요.`),
                  ]}
                  validOnBlur
                />
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col md={2}>
                <div className={Styles.Label}>시나리오 내용</div>
              </Col>
              <Col md={10}>
                <Input
                  store={form}
                  name="description"
                  placeholder="시나리오 내용을 입력하세요."
                  as="textarea"
                  validations={[
                    new Validation(/^.+$/m, `시나리오 내용을 입력하세요.`),
                  ]}
                  style={{ height: '2rem' }}
                  validOnBlur
                />
              </Col>
            </FormGroup>
          </Form>
        )}
        {!!sid && (
          <div className={Styles.DetailWrapper}>
            <Form>
              <div className={Styles.Header}>
                <dl className="center">
                  <dt>실행 상태</dt>
                  <dd>
                    <Form.Check
                      type="switch"
                      checked={isUsed}
                      onChange={(e) => setIsUsed(e.target.checked)}
                    />
                  </dd>
                </dl>
              </div>
              <div className={Styles.Header}>
                <dl>
                  <dt>시나리오 ID</dt>
                  <dd>
                    <InputGroup>
                      <Input
                        store={form}
                        name="sid"
                        validations={[
                          new Validation(/^.+$/, `시나리오 ID를 입력하세요.`),
                          new Validation(
                            checkSid,
                            '4~12글자 영어와 숫자만 입력하세요.',
                          ),
                        ]}
                        validOnBlur
                        style={
                          isSidChange
                            ? { borderRadius: '0.375rem 0 0 0.375rem' }
                            : {}
                        }
                        onChange={onChangeSid}
                        button={
                          isSidChange ? (
                            <Button
                              variant="outline-primary"
                              onClick={onCheckedId}
                            >
                              중복체크
                            </Button>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </InputGroup>
                  </dd>
                </dl>
                <dl>
                  <dt>시나리오 명</dt>
                  <dd>
                    <Input
                      store={form}
                      name="scenarioName"
                      validations={[
                        new Validation(/^.+$/, `시나리오 명을 입력하세요.`),
                      ]}
                      validOnBlur
                    />
                  </dd>
                </dl>
                <dl className="block">
                  <dt>시나리오 내용</dt>
                  <Input
                    store={form}
                    name="description"
                    as="textarea"
                    validations={[
                      new Validation(/^.+$/m, `시나리오 내용을 입력하세요.`),
                    ]}
                    style={{ height: '2rem' }}
                    validOnBlur
                  />
                </dl>
              </div>
            </Form>
            <NodeFlow
              nodesData={nodesData}
              onSave={onSave}
              listApi="/scenario/intent"
              ref={nodeRef}
            />
          </div>
        )}
      </Panel>
    </FrameWrap>
  );
};

export default ScenarioDetailView;
