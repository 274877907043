import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Styles from '../SettingManageView.module.scss';
import TicketEventEditor from './TicketEventEditor';
import { TICKET_EVENT_TYPE } from '@qbot-chat/qbot-uikit/chat';
import settingStore from '../../../store/SettingStore';
import Panel from '../../../components/layout/Panel';
import { TEMPLATE_OPTIONS } from '../../../const/AppConst';

@observer
class DirectPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { ticketEventMap, fetchTicketEvent } = settingStore;
    let active = ticketEventMap.get(TICKET_EVENT_TYPE.TICKET_ACTIVE);
    let idle = ticketEventMap.get(TICKET_EVENT_TYPE.TICKET_IDLE);
    let closed = ticketEventMap.get(TICKET_EVENT_TYPE.TICKET_CLOSED);
    let assignmentRequest = ticketEventMap.get(
      TICKET_EVENT_TYPE.TICKET_ASSIGNMENT_REQUEST,
    );
    let assignment = ticketEventMap.get(TICKET_EVENT_TYPE.TICKET_ASSIGNMENT);
    let transfer = ticketEventMap.get(TICKET_EVENT_TYPE.TICKET_TRANSFER);
    let transferWait = ticketEventMap.get(
      TICKET_EVENT_TYPE.TICKET_TRANSFER_WAIT,
    );
    let enter = ticketEventMap.get(TICKET_EVENT_TYPE.TICKET_ENTER);
    return (
      <Panel className={`h-100 overflow-y-scroll`}>
        {enter && (
          <Panel className={Styles.PanelBorder}>
            <TicketEventEditor
              ticketEvent={enter}
              fetchTicketEvent={fetchTicketEvent}
              isTime={false}
              title={`고객 입장 메시지`}
              options={TEMPLATE_OPTIONS.filter((option) =>
                option.id.startsWith(`CUSTOMER`),
              )}
            />
          </Panel>
        )}
        {assignmentRequest && (
          <Panel className={Styles.PanelBorder}>
            <TicketEventEditor
              ticketEvent={assignmentRequest}
              fetchTicketEvent={fetchTicketEvent}
              isTime={false}
              title={`상담원 요청 시 메시지 `}
              options={TEMPLATE_OPTIONS.filter((option) =>
                option.id.startsWith(`CUSTOMER`),
              )}
            />
          </Panel>
        )}
        {assignment && (
          <Panel className={Styles.PanelBorder}>
            <TicketEventEditor
              ticketEvent={assignment}
              fetchTicketEvent={fetchTicketEvent}
              isTime={false}
              title={`상담원 배정 메시지 `}
              options={TEMPLATE_OPTIONS.filter(
                (option) => !option.id.startsWith(`CUSTOMER`),
              )}
            />
          </Panel>
        )}
        {transfer && (
          <Panel className={Styles.PanelBorder}>
            <TicketEventEditor
              ticketEvent={transfer}
              fetchTicketEvent={fetchTicketEvent}
              isTime={false}
              title={`상담원 이관 메시지`}
              options={TEMPLATE_OPTIONS.filter(
                (option) => !option.id.startsWith(`CUSTOMER`),
              )}
            />
          </Panel>
        )}
        {transferWait && (
          <Panel className={Styles.PanelBorder}>
            <TicketEventEditor
              ticketEvent={transferWait}
              fetchTicketEvent={fetchTicketEvent}
              isTime={false}
              title={`팀 이관 대기 메시지`}
              options={TEMPLATE_OPTIONS.filter(
                (option) => !option.id.startsWith(`CUSTOMER`),
              )}
            />
          </Panel>
        )}
        {active && (
          <Panel className={Styles.PanelBorder}>
            <TicketEventEditor
              ticketEvent={active}
              fetchTicketEvent={fetchTicketEvent}
              isTime={false}
              title={`상담 활성상태 전환 메시지`}
              options={TEMPLATE_OPTIONS.filter(
                (option) => !option.id.startsWith(`CUSTOMER`),
              )}
            />
          </Panel>
        )}
        {idle && (
          <Panel className={Styles.PanelBorder}>
            <TicketEventEditor
              ticketEvent={idle}
              fetchTicketEvent={fetchTicketEvent}
              isTime={false}
              title={`상담 유휴상태 전환 메시지`}
              options={TEMPLATE_OPTIONS.filter(
                (option) => !option.id.startsWith(`CUSTOMER`),
              )}
            />
          </Panel>
        )}
        {closed && (
          <Panel className={Styles.PanelBorder}>
            <TicketEventEditor
              ticketEvent={closed}
              fetchTicketEvent={fetchTicketEvent}
              isTime={false}
              title={`상담 종료상태 전환 메시지`}
              options={TEMPLATE_OPTIONS.filter(
                (option) => !option.id.startsWith(`CUSTOMER`),
              )}
            />
          </Panel>
        )}
      </Panel>
    );
  }
}

export default DirectPanel;
