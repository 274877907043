import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Frame from '../../components/layout/Frame';
import StandardChatList from './StandardChatList';

const StandardChatFrame = () => {
  return (
    <Frame>
      <Switch>
        <Route path="/main/standard-chat/list" component={StandardChatList} />
        <Redirect
          exact
          from="/main/standard-chat"
          to="/main/standard-chat/list"
        />
      </Switch>
    </Frame>
  );
};

export default StandardChatFrame;
