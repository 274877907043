import React from 'react';
import { observer } from 'mobx-react';
import Styles from './Editor.module.scss';
import { FormControl, InputGroup } from 'react-bootstrap';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { CONTENT_TYPE, BUTTON_TYPE } from '@qbot-chat/qbot-uikit/chat';
import { AddCircle, MinusCirlce } from 'iconsax-react';
import OptionSelect from './OptionSelect';

const buttonOption = [
  { label: `외부링크`, value: BUTTON_TYPE.OUTER_LINK },
  { label: `내부링크`, value: BUTTON_TYPE.INNER_LINK },
  { label: `전화`, value: BUTTON_TYPE.TEL },
  { label: `메시지`, value: BUTTON_TYPE.MESSAGE },
];

@observer
class ButtonEditor extends React.Component {
  render() {
    let { value, idx, onChangeItem } = this.props;
    let { buttons } = value;
    return (
      <div className={Styles.Button}>
        {buttons.map((button, index) => {
          let { label, value, type } = button;
          return (
            <div key={`button_${index}`}>
              {index > 0 && (
                <div className={Styles.Line}>
                  <hr width={'100%'} />
                  <a
                    className={Styles.Delete}
                    onClick={() => {
                      let newButtons = buttons;
                      newButtons.splice(index, 1);
                      onChangeItem &&
                        onChangeItem(
                          {
                            type: CONTENT_TYPE.BUTTON,
                            value: {
                              buttons: newButtons,
                            },
                          },
                          idx,
                        );
                    }}
                  >
                    <MinusCirlce />
                  </a>
                </div>
              )}
              <div className={`d-flex flex-column`} style={{ flex: 1 }}>
                <div className={`d-flex pb-1`}>
                  <div className={`d-flex`} style={{ flex: 1 }}>
                    <OptionSelect
                      value={buttonOption.find(
                        (option) => option.value == button?.type,
                      )}
                      placeholder={'타입 선택'}
                      options={buttonOption}
                      onChange={(changeType) => {
                        let newButtons = buttons;
                        newButtons.splice(index, 1, {
                          type: changeType,
                          value: '',
                          label,
                        });
                        onChangeItem &&
                          onChangeItem(
                            {
                              type: CONTENT_TYPE.BUTTON,
                              value: {
                                buttons: newButtons,
                              },
                            },
                            idx,
                          );
                      }}
                    />
                  </div>
                  <div className={`d-flex`} style={{ flex: 1 }}>
                    <InputGroup
                      size={'sm'}
                      className={`justify-content-end align-items-center`}
                    >
                      <FormControl
                        placeholder={``}
                        value={button?.label || ''}
                        defaultValue={button?.label || ''}
                        onChange={(e) => {
                          let newButtons = buttons;
                          newButtons.splice(index, 1, {
                            type,
                            value,
                            label: e.target.value,
                          });
                          onChangeItem &&
                            onChangeItem(
                              {
                                type: CONTENT_TYPE.BUTTON,
                                value: {
                                  buttons: newButtons,
                                },
                              },
                              idx,
                            );
                        }}
                      />
                      {button?.value && (
                        <div
                          className={`d-flex position-absolute me-2`}
                          style={{ cursor: 'pointer', zIndex: 3 }}
                          onClick={() => {
                            let newButtons = buttons;
                            newButtons.splice(index, 1, {
                              type,
                              value,
                              label: '',
                            });
                            onChangeItem &&
                              onChangeItem(
                                {
                                  type: CONTENT_TYPE.BUTTON,
                                  value: {
                                    buttons: newButtons,
                                  },
                                },
                                idx,
                              );
                          }}
                        >
                          <IoCloseCircleOutline />
                        </div>
                      )}
                    </InputGroup>
                  </div>
                </div>
                <div>
                  <InputGroup
                    size={'sm'}
                    className={`justify-content-end align-items-center`}
                  >
                    <FormControl
                      placeholder={``}
                      value={button?.value || ''}
                      defaultValue={button?.value || ''}
                      onChange={(e) => {
                        let newButtons = buttons;
                        newButtons.splice(index, 1, {
                          type,
                          value: e.target.value,
                          label,
                        });
                        onChangeItem &&
                          onChangeItem(
                            {
                              type: CONTENT_TYPE.BUTTON,
                              value: {
                                buttons: newButtons,
                              },
                            },
                            idx,
                          );
                      }}
                    />
                    {button?.value && (
                      <div
                        className={`d-flex position-absolute me-2`}
                        style={{ cursor: 'pointer', zIndex: 3 }}
                        onClick={() => {
                          let newButtons = buttons;
                          newButtons.splice(index, 1, {
                            type,
                            value: '',
                            label,
                          });
                          onChangeItem &&
                            onChangeItem(
                              {
                                type: CONTENT_TYPE.BUTTON,
                                value: {
                                  buttons: newButtons,
                                },
                              },
                              idx,
                            );
                        }}
                      >
                        <IoCloseCircleOutline />
                      </div>
                    )}
                  </InputGroup>
                </div>
              </div>
            </div>
          );
        })}
        <div className={Styles.Line}>
          <hr width={'100%'} />
          <a
            className={Styles.Add}
            onClick={() => {
              let newButtons = buttons;
              newButtons.splice(buttons.length, 0, {
                type: null,
                value: '',
                label: '라벨',
              });
              onChangeItem &&
                onChangeItem(
                  {
                    type: CONTENT_TYPE.BUTTON,
                    value: {
                      buttons: newButtons,
                    },
                  },
                  idx,
                );
            }}
          >
            <AddCircle />
          </a>
        </div>
      </div>
    );
  }
}

export default ButtonEditor;
