import React from 'react';
import {observer} from "mobx-react";
import {User} from "iconsax-react";
import {ProgressBar} from "react-bootstrap";
import Styles from './Stats.module.scss'

@observer
class AgentStatus extends React.Component{
    render() {
        let {total = 0, active = 0, away = 0, offline = 0} = this.props;
        return(
            <div className={Styles.AgentStatus}>
                <div className={Styles.TotalInfo}>
                    <div className={Styles.Avatar}>
                        <User/>
                    </div>
                    <div className={Styles.Count}>
                        {total}
                    </div>
                    <div className={Styles.Name}>
                        총 상담사
                    </div>
                </div>
                <div className={Styles.Bar}>
                    <ProgressBar style={{height:10}}>
                        <ProgressBar variant="success" now={(active/total*100).toFixed(1)} key={1} />
                        <ProgressBar variant="warning" now={(offline/total*100).toFixed(1)} key={2} />
                        <ProgressBar variant="danger" now={(away/total*100).toFixed(1)} key={3} />
                    </ProgressBar>
                </div>
                <div className={Styles.Info}>
                    <div className={Styles.Status}>
                        <div className={Styles.Count}>
                            {active}
                        </div>
                        <div className={Styles.Name}>
                            온라인
                        </div>
                    </div>
                    <div className={Styles.Status}>
                        <div className={Styles.Count}>
                            {offline}
                        </div>
                        <div className={Styles.Name}>
                            오프라인
                        </div>
                    </div>
                    <div className={Styles.Status}>
                        <div className={Styles.Count}>
                            {away}
                        </div>
                        <div className={Styles.Name}>
                            자리비움
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AgentStatus;
