import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

const RunningTimeText = () => {
  const currentTime = dayjs().format('YYYY-MM-DD dddd HH:mm');
  const [runningTime, setRunningTime] = useState(currentTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setRunningTime(currentTime);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [currentTime]);

  return <span>{runningTime}</span>;
};

export default RunningTimeText;
