import React from 'react';
import Select from 'react-select';
import { observer } from 'mobx-react';

@observer
class OptionSelect extends React.Component {
  render() {
    let {
      options,
      onChange,
      value,
      isClearable = true,
      isSearchable = true,
      menuShouldBlockScroll = true,
      placeholder,
    } = this.props;
    return (
      <Select
        menuPortalTarget={document.body}
        menuShouldBlockScroll={menuShouldBlockScroll}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isClearable={isClearable}
        styles={{
          clearIndicator: (base) => ({
            ...base,
            padding: `0 .25rem`,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: `0 .25rem`,
          }),
          indicatorSeparator: (base) => ({
            ...base,
            marginTop: '.25rem',
            marginBottom: '.25rem',
          }),
          container: (base) => ({
            ...base,
            marginRight: '.25rem',
            flex: 1,
            maxHeight: 31,
          }),
          indicatorsContainer: (base) => ({
            ...base,
            padding: 0,
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '0 .5rem',
          }),
          control: (base) => ({
            ...base,
            minHeight: 31,
            maxHeight: 31,
          }),
          input: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),

          group: (base) => ({
            ...base,
          }),
          groupHeading: (base) => ({
            ...base,
          }),

          loadingIndicator: (base) => ({
            ...base,
          }),
          loadingMessage: (base) => ({
            ...base,
          }),
          menu: (base) => ({
            ...base,
          }),
          menuList: (base) => ({
            ...base,
          }),
          menuPortal: (base) => ({
            ...base,
          }),
          multiValue: (base) => ({
            ...base,
          }),
          multiValueLabel: (base) => ({
            ...base,
          }),
          multiValueRemove: (base) => ({
            ...base,
          }),
          noOptionsMessage: (base) => ({
            ...base,
          }),
          option: (base) => ({
            ...base,
          }),
          placeholder: (base) => ({
            ...base,
          }),
          singleValue: (base) => ({
            ...base,
          }),
        }}
        options={options}
        onChange={({ value }) => {
          onChange && onChange(value);
        }}
        value={value}
      ></Select>
    );
  }
}

export default OptionSelect;
