import React from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <span className={`d-flex justify-content-start align-items-center`}>
          <AiOutlineExclamationCircle className={`me-1`} />
          {`정상 적인 메시지가 아닙니다.`}
        </span>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
