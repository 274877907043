import * as React from 'react';

const SVGComponent = ({
  size = 24,

  color = '',
  ...props
}) => {
  return (
    <svg
      id="MentionIcon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
        width: size,
        height: 'auto',
        fill: color,
        //
      }}
      xmlSpace="preserve"
      {...props}
    >
      {/*--여기까지는 기본값--*/}

      <g>
        {/*여기아래 붙여넣기*/}
        <path d="M-47.2,26h-11.1c-4,0-5.2-1.2-5.2-5.2v-6.4c0-4,1.2-5.2,5.2-5.2h11.1c4,0,5.2,1.2,5.2,5.2v6.3 C-42,24.7-43.2,26-47.2,26z M-58.3,10.7c-3.1,0-3.7,0.5-3.7,3.7v6.3c0,3.1,0.5,3.7,3.7,3.7h11.1c3.1,0,3.7-0.5,3.7-3.7v-6.3 c0-3.1-0.5-3.7-3.7-3.7H-58.3z" />
        <path d="M-52.7,30.4c-0.4,0-0.8-0.3-0.8-0.8v-4.1c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v4.1 C-52,30-52.3,30.4-52.7,30.4z M-43.1,21.7h-19.3c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h19.3c0.4,0,0.8,0.3,0.8,0.8 S-42.7,21.7-43.1,21.7z" />
        <path d="M-48.2,30.7h-9c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h9c0.4,0,0.8,0.3,0.8,0.8S-47.8,30.7-48.2,30.7z" />
        <g>
          <path d="M-54.4,19.4h-3c-0.3,0-0.6-0.2-0.6-0.4v-3.1c0-0.6,0.7-1.1,1.5-1.1h2.1c0.3,0,0.6,0.2,0.6,0.4v3.7 C-53.8,19.2-54.1,19.4-54.4,19.4z M-56.8,18.2h1.8v-2.3h-1.5c-0.2,0-0.3,0.1-0.3,0.3l0,0V18.2z" />
          <path d="M-51.4,19.4h-3c-0.3,0-0.6-0.2-0.6-0.4v-5.8c0-0.6,0.7-1.1,1.5-1.1h1.2c0.8,0,1.5,0.5,1.5,1v5.7 C-50.8,19.2-51.1,19.4-51.4,19.4z M-53.8,18.2h1.8v-4.6c0-0.1-0.1-0.2-0.3-0.2l0,0h-1.2c-0.2,0-0.3,0.1-0.3,0.2l0,0v4.9V18.2z" />
          <path d="M-48.4,19.4h-3c-0.3,0-0.6-0.2-0.6-0.4v-2.7c0-0.2,0.3-0.4,0.6-0.4h2.1c0.8,0,1.5,0.5,1.5,1.1v2 C-47.8,19.2-48,19.4-48.4,19.4z M-50.8,18.1h1.8v-1c0-0.1-0.1-0.2-0.3-0.2h-1.5V18.1z" />
        </g>
        <g>
          <g>
            <path d="M12,22.8c-0.7,0-1.3-0.3-1.8-1l-1.5-2c0,0-0.1-0.1-0.2-0.1H8c-4.2,0-6.8-1.1-6.8-6.7V8c0-4.4,2.3-6.8,6.8-6.8 h8c4.4,0,6.8,2.3,6.8,6.8v5c0,4.4-2.3,6.7-6.8,6.7h-0.5c-0.1,0-0.2,0-0.2,0.1l-1.5,2C13.3,22.4,12.7,22.8,12,22.8z M8,2.7 C4.4,2.7,2.8,4.4,2.8,8v5c0,4.5,1.5,5.2,5.2,5.2h0.5c0.5,0,1.1,0.3,1.4,0.7l1.5,2c0.3,0.5,0.9,0.5,1.2,0l1.5-2 c0.3-0.4,0.9-0.7,1.4-0.7H16c3.6,0,5.2-1.7,5.2-5.2V8c0-3.6-1.7-5.2-5.2-5.2H8z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M7.6,15.2H6.9c-0.2,0-0.4-0.1-0.6-0.3C6,14.6,6,14.1,6.1,13c0-0.4,0.1-1.1,0-1.4c-0.2-0.1-0.4-0.1-0.4-0.1 c-0.4-0.1-0.7-0.4-0.7-0.8s0.3-0.7,0.7-0.7c0,0,0.1,0,0.4-0.1c0-0.3,0-1,0-1.4C6,7.5,6,7,6.3,6.7c0.1-0.1,0.4-0.3,0.6-0.3h0.7 c0.4,0,0.8,0.3,0.8,0.8S8,7.9,7.6,7.9h0c0,0.2,0,0.4,0,0.6c0.1,1,0.1,1.8-0.2,2.3c0.3,0.5,0.2,1.3,0.2,2.3c0,0.2,0,0.4,0,0.6h0 c0.4,0,0.8,0.3,0.8,0.8S8,15.2,7.6,15.2z" />
          </g>
          <g>
            <path d="M17.1,15.2h-0.7c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0c0-0.2,0-0.4,0-0.6c-0.1-1-0.1-1.8,0.2-2.3 c-0.3-0.5-0.2-1.3-0.2-2.3c0-0.2,0-0.4,0-0.6h0c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0.7c0.2,0,0.4,0.1,0.6,0.3 C18,7,18,7.5,17.9,8.6c0,0.4-0.1,1.1,0,1.4c0.2,0.1,0.4,0.1,0.4,0.1c0.4,0.1,0.7,0.4,0.7,0.8c0,0.4-0.3,0.7-0.7,0.7l0,0 c0,0-0.1,0-0.4,0.1c0,0.3,0,1,0,1.4c0.1,1,0.1,1.5-0.3,1.9C17.5,15.1,17.3,15.2,17.1,15.2z" />
          </g>
        </g>
        <g>
          <path d="M11,13.8c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l2-4.7c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1 l-2,4.7C11.6,13.6,11.3,13.8,11,13.8z" />
        </g>
      </g>
    </svg>
  );
};
export default SVGComponent;
