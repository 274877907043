import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Calendar } from "react-calendar";
import moment from "moment";
import Styles from "./SearchYear.module.scss"

const SearchYear = (prop, ref) => {
    const [item, setItem] = useState(prop.item);
    const [showCal, setShow] = useState(false);
    const clickRef = useRef(null);
    const boxRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(boxRef.current && boxRef.current.contains(event.target)){
                return;
            }

            if (clickRef.current && !clickRef.current.contains(event.target)) {
                setShow(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [])

    const onClickCalBtn = () => {
        setShow(!showCal);
    }

    useImperativeHandle(ref, () => ({
        getData() {
            return item.value
        }
    }));

    return (
        <div className={`d-flex ${Styles.search_calendar}`}>
            <div ref={boxRef} onClick={() => onClickCalBtn()} className={`${Styles.range_picker_input_box}`}>
                <button ></button>
                <label>{item.name}</label>
                <span>{item.value}</span>
            </div>
            {
                showCal === true ?
                    <div ref={clickRef} className={`${Styles.mm_calendar}`}>
                        <Calendar
                            // className={`${Styles.mm_calendar}`}
                            onChange={(e) => {
                                let nItem = item;
                                nItem.value = moment(e).format("YYYY");
                                setItem({ ...item, value: nItem.value });
                            }}
                            value={moment(item.value)}
                            locale="ko-KO"
                            maxDetail='decade'
                        // minDetail='year'
                        ></Calendar>
                    </div>
                    : <></>
            }
        </div>
    )
}

export default forwardRef(SearchYear);