import React, { Component } from 'react';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { observer } from 'mobx-react';
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap';
import Styles from './TransferDialog.module.scss';
import { toast } from 'react-toastify';
import axios from 'axios';
import Select from 'react-select';
import { styles } from '../input/SelectBox';
import { chatStore } from '../../store/AdminChatStore';

class TransferDialogStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  show = false;

  @action.bound
  open() {
    this.show = true;
  }

  @action.bound
  close() {
    this.show = false;
  }
}

const transferDialogStore = new TransferDialogStore();

@observer
class TransferDialog extends Component {
  @observable
  selectedBranch = null;

  @observable
  branches = [
    {
      value: 'DIRECT',
      label: '직접입력',
      branch: { id: 'DIRECT', name: '직접입력' },
    },
  ];

  @observable
  branchTel;

  @observable
  isBranchesLoading;

  @observable
  selectedAgentId;

  @observable
  activeKey;

  @computed
  get isTransferable() {
    if (!this.activeKey) return false;

    if (this.activeKey === 'ARS') return !!this.selectedBranch?.id;

    if (this.activeKey === 'EMAIL') return true;

    return !!this.selectedAgentId;
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.isBranchesLoading = true;
    axios
      .post('/code/branches')
      .then(({ data: branches }) => {
        this.branches.replace([
          ...branches
            .filter((branch) => !!branch.arsTelno)
            .map((branch) => ({
              label: branch.name,
              value: branch.id,
              branch,
            })),
          {
            value: 'DIRECT',
            label: '직접입력',
            branch: { id: 'DIRECT', name: '직접입력' },
          },
        ]);
      })
      .finally(() => (this.isBranchesLoading = false));
  }

  handleSave = async () => {
    const { ticket, onSave } = this.props;

    if (this.activeKey === 'EMAIL') {
      try {
        this.isSubmitting = true;
        chatStore?.ticketStore?.close(ticket.id);
        toast.info('티켓을 종료했습니다.');
        this.handleClose();
      } catch (e) {
        toast.error('티켓을 종료할 수 없었습니다. 잠시 후 다시 시도해 주세요.');
      } finally {
        this.isSubmitting = false;
      }
      window.open('https://emartvoc.emart.com/', '_blank');
      return;
    }

    const transferRequest = {
      ticket,
      reason: this.branchTel,
    };

    try {
      this.isSubmitting = true;
      await axios.post('/custom-message/send/voice', transferRequest);
      this.handleClose();
      onSave && onSave();
    } catch (e) {
      console.log(e);
    } finally {
      this.isSubmitting = false;
    }
  };

  handleClose() {
    transferDialogStore.close();
    runInAction(() => {
      this.selectedBranch = null;
      this.selectedAgentId = null;
      this.branchTel = '';
    });
  }

  render() {
    return (
      <Modal
        show={transferDialogStore.show}
        onHide={this.handleClose.bind(this)}
        dialogClassName={Styles.TransferDialog}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className={Styles.Header}>이관</div>
        </Modal.Header>
        <Modal.Body className={Styles.Body}>
          <Container>
            <Form.Group as={Row}>
              <Accordion
                activeKey={this.activeKey}
                onSelect={(key) => (this.activeKey = key)}
              >
                <Accordion.Item eventKey="ARS">
                  <Accordion.Header>음성 이관</Accordion.Header>
                  <Accordion.Body>
                    <Form.Group as={Row}>
                      <Col md={6}>
                        <Select
                          ref={(ref) => (this.selectRef = ref)}
                          placeholder="지점 선택"
                          isClearable
                          isSearchable
                          styles={styles}
                          theme={(theme) => ({
                            ...theme,
                          })}
                          onChange={(option) => {
                            this.selectedBranch = option?.branch;
                            this.branchTel = this.selectedBranch?.arsTelno;
                          }}
                          value={
                            this.selectedBranch
                              ? this.branches.find(
                                  (branch) =>
                                    branch.id === this.selectedBranch.id,
                                )
                              : null
                          }
                          options={this.branches}
                          isLoading={this.isBranchesLoading}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Control
                          value={this.branchTel}
                          disabled={this.selectedBranch?.id !== 'DIRECT'}
                          onChange={(e) => (this.branchTel = e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="EMAIL">
                  <Accordion.Header>이메일</Accordion.Header>
                  <Accordion.Body>
                    이소리 VOC 관리창을 통해 이메일 상담으로 처리 됩니다. 해당
                    채팅은 이메일 분류로 종료됩니다.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Form.Group>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.handleClose.bind(this)}>
            취소
          </Button>
          <Button
            onClick={this.handleSave.bind(this)}
            variant="primary"
            disabled={this.isSubmitting || !this.isTransferable}
          >
            {this.isSubmitting ? (
              <Spinner animation="border" variant="secondary" />
            ) : (
              <span>이관</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export { TransferDialog as default, transferDialogStore };
