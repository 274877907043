import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Styles from './DataGrid.module.scss';
import DataGridStore from '../../store/DataGridStore.js';

/**
 * 페이지 링크
 */
@observer
class PageLink extends React.Component {
  /**
   * 저장소
   * @return {DataGridStore} 저장소 프로퍼티
   */
  get store() {
    return this.props.store;
  }

  /**
   * 이 페이지 링크의 현재 페이지 가리킴 여부
   * @returns {boolean}
   */
  get isCurrentPage() {
    return this.props.page === this.store.page;
  }

  get isNumberLabelPage() {
    const { label } = this.props;
    return typeof label === 'number';
  }

  /**
   * 이 페이지링크의 클릭 처리
   */
  onClick() {
    this.props.store.onPageButtonClick(this.props.page);
  }

  render() {
    return (
      <div
        className={`${Styles.PageLink} ${this.isCurrentPage ? Styles.active : ''} ${this.isNumberLabelPage ? Styles.number : ''} pt-pagelink`}
        onClick={() => this.onClick()}
      >
        {this.props.label}
      </div>
    );
  }
}

PageLink.propTypes = {
  /**
   * 데이터 스토어
   */
  // store: PropTypes.instanceOf(DataGridStore),

  /**
   * 각 페이지 링크가 이동할 페이지 번호
   */
  page: PropTypes.number,

  /**
   * 각 페이지 링크가 나타낼 라벨
   */
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.number]),
};

export default PageLink;
