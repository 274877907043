export const antTheme = {
  token: {
    margin: 0,
    borderRadius: 4,
  },
  components: {
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
    Menu: {
      itemColor: 'var(--qt-cool-gray6);',
      itemHoverColor: 'var(--qt-cool-gray7);',
      itemHoverBg: 'var(--qt-gray3);',
      itemSelectedColor: 'var(--qt-primary6);',
      itemSelectedBg: 'var(--qt-primary1);',
      itemBorderRadius: 4,
      activeBarBorderWidth: 0,
      subMenuItemBg: 'var(--qt-gray1)',
    },
  },
};
