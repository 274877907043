import React from 'react';

class MessageLine extends React.Component {
    render() {
        let {value} = this.props;
        let {thickness, colorCode} = value;
        return (
            <div className={`w-100`}>
                <div style={{borderBottom:`${thickness}px solid ${colorCode}`}}/>
            </div>
        )
    }
}

export {MessageLine};
